import React, { Component } from 'react';
import styled from 'styled-components';
import {EditField, Layout, NoResults, Table} from '../components';
import {
  DashboardForm,
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  Flex,
  FieldSet,
  FormRow,
  Legend,
  ROBOTO_MEDIUM,
  GREEN, SecondaryButton, CustomTable, TableRow, TableHeader, TableItem, PrimaryButton, PaginationButtons,FLEX_BETWEEN
} from '../components/style';
import SORT_COLUMN from '../utils/sortCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import data from './increase-allocation/data';
import * as strings from "../utils/appstring";
import {employeeService, organisationService, reportService, assessmentCycleService} from "../services";
import InlineEdit from '../components/EditableInput';
import Pencil from "../images/Pencil.svg";
import renderSelect from '../components/RenderSelect';
import moment from "moment";
import {Field, initialize, reduxForm} from 'redux-form';

const FlexRow = styled(FormRow)`
  flex-direction: column;
`;

// TODO: replace with the new convention see, Trend & Comparison Report
const Banner = styled(FieldSet)`
  border: solid 1px #cdcdcd;
  margin-bottom: 40px;
  border-radius: 5px;

  ${Legend} {
    border-top-width: 0;
  }
`;

const TableLegend = styled(Legend)`
  width: 100%;
  border-width: 1px 1px 0;
`;

const Heading = styled(Heading2)`
  padding: 0;
  margin: 0;
`;

const PrimaryHeading = styled(Heading2)`
  ${ROBOTO_MEDIUM};
  color: ${GREEN};
`;

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;
  
  select {
    margin-left: 10px;
  }
  
  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
  }

 
`;

class IncreaseAllocation extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      successModal: false,
      items: [],
      promptIndex: '',
      sortedColumn: '',
      pageNumber:1,
      percentIncrease:5,
      currentUser: JSON.parse(localStorage.getItem('user')),
      orgSetting:{
        branches: JSON.parse(localStorage.getItem('user')).organisation_obj.setup_branches !== 0,
        departments: JSON.parse(localStorage.getItem('user')).organisation_obj.setup_departments !== 0,
        teams: JSON.parse(localStorage.getItem('user')).organisation_obj.setup_teams !== 0,
      },
      searchTerm: '',
      selectedCycle:{},
      cycle: {},
      cycles:[],
  };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.dataChanged = this.dataChanged.bind(this);

  }

  componentDidMount() {

    this.getUsers();
    this.getAssessmentCycles();

  }

  getAssessmentCycles() {

    this.setState({showLoader: true});

    assessmentCycleService.getAssessmentCycles("locked").then(result => {
        this.setState({cycles: result.data, showLoader: false});
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getAssessmentCycleResultSummary(assessment_cycle_guid) {

    this.setState({showloader: true});
    assessmentCycleService.getAssessmentCycleResultSummary(assessment_cycle_guid).then(result => {
      this.setState({items: result.data, selectedCycle:{guid:assessment_cycle_guid}, showloader: false, send_notification: false});
      this.props.dispatch(initialize(
          'review-results',
          this.state.selectedCycle
      ));
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getUsers(){
    employeeService.getOrganisationEmployees(this.state.currentUser.organisation_obj.guid).then(res=>{

      var increasePercentage = this.state.percentIncrease;
      var arr = res.data;
      arr.map(function(ele){
       
        ele.increase = Number((ele.salary * (increasePercentage/100) * 1)).toFixed(2);
        ele.newSalary = Number(Number(ele.salary) + Number(ele.increase)).toFixed(2);
        ele.increasePercentage = 5;

      });

      this.setState({items:arr});

    });

  }

  showModal(modal) {
    this.setState({ [modal]: true });
  }

  hideModal(modal) {
    this.setState({ [modal]: false });
  }

  handleDelete() {
    const items = this.state.items.filter((_, i) => i !== this.state.promptIndex);
    this.setState({ items, promptIndex: '' });
  }

  handleSort(column) {
    const items = SORT_COLUMN(data, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  promptDelete(index) {
    this.showModal('promptModal');
    this.setState({ promptIndex: index });
  }

  getReport(){
    reportService.getIncreaseReport().then(result =>{
      let url = URL.createObjectURL(result);
      window.open(url, "_blank");
    })
  }

  paginate (array, page_size, page_number) {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  dataChanged(data) {

    this.setState({percentIncrease: data.percentIncrease});

    var increasePercentage = data.percentIncrease;
    var arr = this.state.items;
    arr.map(function(ele){
     
      ele.increase = Number((ele.salary * (increasePercentage/100) * 1)).toFixed(2);
      ele.newSalary = Number(Number(ele.salary) + Number(ele.increase)).toFixed(2);
      ele.increasePercentage = increasePercentage;

    });

    this.setState({items:arr});

  }

  customValidateText(text) {
    return !isNaN(text);
  }

  focusOnInput() {
    console.log("edit clicked")
  }

  sum(arr, prop) {
    var total = Number(0);
    for ( var i = 0, _len = arr.length; i < _len; i++ ) {
        total += Number(arr[i][prop]);
    }
    return total;
  }

  render() {
    const headers = ['Department', 'Team', 'Employee', 'Salary', '%', 'Increase', "New Salary"];
    const { match } = this.props;
    return (
      <Layout title="Increase Allocation">
        <div>
          <Flex justify="center" style={{paddingBottom: "20px"}}>
            <Heading2 as={'h4'}>
              Define Increase Allocation Amount
            </Heading2>
          </Flex>
          <DashboardForm>
              <Wrapper>
                <label>Schedule</label>
                <Field
                    component={renderSelect}
                    id="due-date"
                    name="guid"
                    options={this.state.cycles.map(({name, guid, date_due, organisation_name}) => {
                      let label = `${name} (${moment(date_due).format("DD/MM/YYYY")})`;
                      if (this.state.currentUser.user_type === "SYSADMIN") {
                          label= organisation_name + " > " + label;
                      }
                      return {label: label, value: guid}
                    })}
                    onChange={({target: {value}}) => {
                      this.setState({selectedCycle:{guid:value}});
                      this.getAssessmentCycleResultSummary(value);
                    }}

                />
                </Wrapper>

                <FlexRow justify="center" align="center" style={{paddingBottom: "20px"}}>
                
                <div>
                  <InlineEdit
                      id="increaseAllocationAmountInput"
                      validate={this.customValidateText}
                      activeClassName="editing"
                      text={this.state.percentIncrease+'%'}
                      paramName="percentIncrease"
                      change={this.dataChanged}
                      style={{
                        fontFamily:"robotomedium",
                        color:"#009b77",
                        fontSize:"2em",
                        outline: 1,
                        border: "solid 1px #a8a8a8",
                        borderWidth: "0 0 1px",
                        borderRadius: "0",
                        boxSizing: "border-box",
                        width: "200px",
                        display: "inline-block",
                        textAlign: "center",
                        background: "white"
                      }}
                  />
                  <div style={{display: "inline-block", height: "100%", paddingLeft: "10px"}}>
                    <img onClick={this.focusOnInput} src={Pencil} alt="Edit"/>
                  </div>
                </div>

                </FlexRow>


          </DashboardForm>

          <TableLegend style={{marginTop:"20px"}}>{this.state.currentUser.organisation_obj.name} Branch &amp; Departments </TableLegend>

          <CustomTable>
            <TableRow>
              {this.state.orgSetting.branches?<TableHeader>
                Branch
              </TableHeader>:null}
              {this.state.orgSetting.departments?<TableHeader>
                Department
              </TableHeader>:null}
              {this.state.orgSetting.teams?<TableHeader>
                Team
              </TableHeader>:null}
              <TableHeader>
                Employee
              </TableHeader>
              <TableHeader>
                Current Salary
              </TableHeader>
              <TableHeader>
                Rating
              </TableHeader>
              <TableHeader>
                Increase Percentage
              </TableHeader>
              <TableHeader>
                Increase Value
              </TableHeader>
              <TableHeader >
                New Salary
              </TableHeader>
            </TableRow>
            {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((employee, i) =>{
               return( <TableRow key={i}>
                 {this.state.orgSetting.branches? <TableItem>
                   Branch Name
                 </TableItem>:null}
                 {this.state.orgSetting.departments? <TableItem>
                   Department Name
                 </TableItem>:null}
                 {this.state.orgSetting.teams? <TableItem>
                   Team Name
                 </TableItem>:null}
                  <TableItem>
                    {employee.first_name} {employee.last_name}
                  </TableItem>
                  <TableItem>
                    R{employee.salary}
                  </TableItem>
                  <TableItem>
                    100%
                  </TableItem>
                  <TableItem>
                    {this.state.percentIncrease}%
                  </TableItem>
                  <TableItem>
                   R {employee.increase}
                  </TableItem>
                 <TableItem>
                   R {employee.newSalary}
                 </TableItem>
                </TableRow>)}
            ) : <NoResults /> }
          </CustomTable>

          {this.state.pageNumber>1 && <PaginationButtons>
            {this.state.pageNumber>1?<PrimaryButton onClick={this.handleBack}>Back</PrimaryButton>:<div></div>}
            {10*this.state.pageNumber<this.state.items.length?<PrimaryButton  onClick={this.handleNext}>Next</PrimaryButton>:<div></div>}
          </PaginationButtons>}

          <Flex justify="center">
            <Heading2 as={'h4'}>Total Increases: <u>R {this.sum(this.state.items, "increase")}</u></Heading2>
          </Flex>

          <Flex justify="center" style={{marginTop: "-20px"}}>
            <Heading2 as={'h4'} >Total Salaries: <u>R {this.sum(this.state.items, "salary")}</u></Heading2>
          </Flex>

          <Buttons style={{padding: "10px"}}>
              <PillButton 
                onClick={()=>this.getReport()}>
                Generate Report
              </PillButton>
          </Buttons>

          <Buttons style={{padding: "10px"}}>
            <SecondaryLink to="#">
              Cancel
            </SecondaryLink>
            <PillButton 
              onClick={() => this.showModal('successModal')}>
              {strings.ok_Title}
            </PillButton>
          </Buttons>
        </div>
        {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')} />}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} />}
      </Layout>
    );
  }
};

export default reduxForm({
  form: 'increase-allocation'
})(IncreaseAllocation);
