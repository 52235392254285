const Sort = (arr, predicate) => {

  return arr.sort((a, b) => {

    const A = a[predicate] && a[predicate].toString().toUpperCase();
    const B = b[predicate] && b[predicate].toString().toUpperCase();

    if (isNaN(A) && isNaN(B)) return A < B ? -1 : A > B ? 1 : 0;

    return Number(A) - Number(B);
  });
};

export default Sort;
