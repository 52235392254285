import React from 'react';
import {Button, CheckIcon, DARK_GRAY, Heading1, Heading2, InfoIcon, ModalContainer, WarningIcon} from "../style";
import Modal from "../Modal";
import styled from "styled-components";
import * as strings from "../../utils/appstring";

const StyledLink = styled.a`
    color:${DARK_GRAY};
`

const ConfirmBtn = styled(Button)`
  width: 50%;
  border-radius: 20px;
`;

const DismissBtn = styled(ConfirmBtn)`
  background: ${DARK_GRAY};
  color: white;
`;

const MetaLabel = styled.label`
    width: 50%;
    color: #a8a8a8;
    max-width: 400px,
    text-align: center;
    font-size: 12px
`;

class TypedModal extends React.Component {

    render() {
        switch (this.props.type) {
            case 'success':
                return (
                    <Modal hideModal={this.props.hideModal}>
                        <ModalContainer>
                            <CheckIcon/>
                            <Heading1>Success</Heading1>
                            <Heading2 style={
                                {
                                    maxWidth: "400px",
                                    textAlign: "center"
                                }
                            }>{this.props.message}                            
                                {this.props.meta && <div><br/><MetaLabel>{this.props.meta}</MetaLabel></div>}
                            </Heading2>
                            <ConfirmBtn onClick={this.props.affirmAction || this.props.hideModal}>{strings.ok_Title}</ConfirmBtn>
                        </ModalContainer>
                    </Modal>
                );
            case 'error':
                return (
                    <Modal hideModal={this.props.hideModal}>
                        <ModalContainer>
                            <WarningIcon/>
                            <Heading1>Error</Heading1>
                            <Heading2 style={
                                {
                                    maxWidth: "400px",
                                    textAlign: "center"
                                }
                            }>{this.props.message}
                                {this.props.meta && <div><br/><MetaLabel>{this.props.meta}</MetaLabel></div>}
                            </Heading2>
                            <DismissBtn onClick={this.props.affirmAction || this.props.hideModal}>{strings.ok_Title}</DismissBtn>
                            <div/>
                            <div/>
                            <div/>
                            <StyledLink href="mailto:support@assessme.online">Contact Support</StyledLink>
                        </ModalContainer>
                    </Modal>
                );
            case 'info':
                return (
                    <Modal hideModal={this.props.hideModal}>
                        <ModalContainer>
                            <InfoIcon/>
                            <Heading1>Information</Heading1>
                            <Heading2 style={
                                {
                                    maxWidth: "400px",
                                    textAlign: "center"
                                }
                            }>{this.props.message}
                                {this.props.meta && <div><br/><MetaLabel>{this.props.meta}</MetaLabel></div>}
                            </Heading2>
                            <ConfirmBtn onClick={this.props.affirmAction || this.props.hideModal}>{strings.ok_Title}</ConfirmBtn>
                        </ModalContainer>
                    </Modal>
                );
            case 'warning':
                return (
                    <Modal hideModal={this.props.hideModal}>
                        <ModalContainer>
                            <WarningIcon/>
                            <Heading1>Warning</Heading1>
                            <Heading2 style={
                                {
                                    maxWidth: "400px",
                                    textAlign: "center"
                                }
                            }>{this.props.message}
                                {this.props.meta && <div><br/><MetaLabel>{this.props.meta}</MetaLabel></div>}
                            </Heading2>
                            <ConfirmBtn onClick={this.props.affirmAction || this.props.hideModal}>{strings.ok_Title}</ConfirmBtn>
                        </ModalContainer>
                    </Modal>
                );
            default:
                return null;
        }

    }
}
export default TypedModal;