import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import {
  FieldSet,
  Legend,
  DashboardForm,
  MultiColumn
} from '../../components/style';
import renderSelect from '../../components/RenderSelect';
import renderDatePicker from '../../components/RenderDatePicker';
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

const Label = styled.div`
  display: flex;
  align-items: center;
  width: 90px;
  font-size: 12px;
  white-space: nowrap;
`;

class SchedulerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scehduledDate: new Date(), 
      showloader: false
    }
  }

  render() {
    const { handleSubmit } = this.props;
  
    return (
      <DashboardForm onSubmit={handleSubmit}>
        <FieldSet>
          <Legend>Report Criteria</Legend>
          <MultiColumn>
            <MultiColumn>
              <Label>Date From</Label>
              <Field
                component={renderDatePicker}
                id="date-from"
                name="date-from"
              />
            </MultiColumn>
            <MultiColumn>
              <Label>Date to</Label>
              <Field
                component={renderDatePicker}
                id="date-to"
                name="date-to"
              />
            </MultiColumn>
          </MultiColumn>
          <MultiColumn>
            <MultiColumn>
              <Label>Level</Label>
              <Field
                component={renderSelect}
                id="level"
                name="Level"
              />
            </MultiColumn>
            <MultiColumn>
              <Label>Selection</Label>
              <Field
                component={renderSelect}
                id="selection"
                name="Selection"
              />
            </MultiColumn>
          </MultiColumn>
        </FieldSet>

        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

      </DashboardForm>

    );
  }
};

export default reduxForm({
  form: 'trend-report'
})(SchedulerForm);