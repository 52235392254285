import React, { Component } from "react";
import styled, { css } from "styled-components";
import {
  CHEVRON,
  GREEN,
  LIGHT_GRAY
} from "../../components/style";
import List from "../../components/List";

const AccordionWrapper = styled.div`
  padding-left: 40px;
  position: relative;
  border-bottom: solid 1px ${LIGHT_GRAY};

  > div::before {
    ${CHEVRON};
    border-color: ${GREEN};
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 15px;
    left: 15px;

    ${props =>
      props.isOpen &&
      css`
        transform: rotate(-135deg);
        top: 15px;
      `};
  }

  :hover {
    cursor: pointer;
  }
`;

class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: this.props.isOpen || false };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({
      isOpen: this.props.isOpen
    });
  }

  toggleOpen(event) {
    event.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <AccordionWrapper isOpen={this.state.isOpen} onClick={(e)=>{this.toggleOpen(e)}}>
        <div>{this.props.label}</div>
        <List isOpen={this.state.isOpen}>{this.props.children}</List>
      </AccordionWrapper>
    );
  }
}

export default Accordion;
