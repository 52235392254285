import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import {
  Flex,
  COLORS_3,
  GRAY,
  LIGHT_GRAY,
  LIGHT_GREEN
} from "../../../components/style";
import {
  QuestionHeading as SectionHeading,
  FieldSet as ImportedFieldSet,
  Legend
} from "./renderQuestions/styles";

const parse = txt => txt.replace("-", " ");

const Warning = styled.span`
  color: #ff0000;
`;

const FieldSet = styled(ImportedFieldSet)`
  text-transform: capitalize;
  font-size: 13px;
  margin-bottom: 5px;

  ${props =>
    props.bg &&
    `
    background: ${props.bg}
  `}
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const QuestionHeading = styled(SectionHeading)`
  font-size: 16px;
  margin-bottom: 5px;
  ${COLORS_3};
  border: 0;
`;

const Separator = styled.div`
  border-bottom: solid 1px ${GRAY};
  padding-bottom: 5px;
  margin-bottom: 5px;

  :last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const IndexValue = styled.span`
  color: ${GRAY};
`;

const FormValues = ({ values }) => {
  if (!!values && values.questions.length) {
    const { questions } = values;
    if (Object.keys(questions[0]).length) {
      return questions.map((question, index) => (
        <Section key={`Question ${index}`}>
          <QuestionHeading>Question {index + 1}</QuestionHeading>
          <div>
            <Flex justify="center" align="center">
              <FieldSet>
                <Legend>Type:</Legend>
                {question["type"] ? (
                  parse(question["type"])
                ) : (
                  <Warning>Not defined</Warning>
                )}
              </FieldSet>
              <FieldSet>
                <Legend>Category:</Legend>
                {question["category"] ? (
                  parse(question["category"])
                ) : (
                  <Warning>Not defined</Warning>
                )}
              </FieldSet>
            </Flex>
            <FieldSet bg={LIGHT_GRAY}>
              <Legend>Question:</Legend>
              {question["question"] ? (
                parse(question["question"])
              ) : (
                <Warning>Not defined</Warning>
              )}
            </FieldSet>
            {question["answers"] && (
              <FieldSet>
                <Legend>{parse(question["type"])}:</Legend>
                {question["answers"].map((ans, k) => (
                  <Separator key={ans}>
                    <IndexValue>{k}:</IndexValue> {ans[k]}
                  </Separator>
                ))}
              </FieldSet>
            )}
            {question["correct-answer"] && (
              <FieldSet bg={LIGHT_GREEN}>
                <Legend>Correct Answer:</Legend>
                {question["correct-answer"]}
              </FieldSet>
            )}
          </div>
        </Section>
      ));
    } else {
      return "Incomplete question";
    }
  } else {
    return "Complete the questionaire";
  }
};

export default connect(state => ({
  values: getFormValues("performance-add-edit")(state)
}))(FormValues);
