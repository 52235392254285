import React, {Component} from 'react';
import styled from 'styled-components';
import { AccessLayout } from '../components';
import { Heading1, Heading2, AccessForm, PillButton } from '../components/style';
import {Field, reduxForm} from 'redux-form';
import {email, required} from "../utils/validation";
import renderField from "../components/RenderField";
import {authService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import Loading from '../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../components/style";
const Heading = styled(Heading1)`
  margin-top: 80px;
  text-align: center;
`;

const SubHeading = styled(Heading2)`
  margin: 20px 0 40px;
  text-align: center;
`;

const ResetForm = styled(AccessForm)`
  height: 195px;
`;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state={
            showloader: false
        }
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    forgotPassword(data){
        this.setState({showloader: true});
        authService.forgotPassword(data).then(response=>{
            this.setState({showloader: false});
            this.showModal('modal', response.message, 'success')
        }).catch(error => {
            this.setState({showloader: false});
            this.showModal('modal', error, 'error')
        })
    }
    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {
        this.setState({[modal]: false, message:null, type:null});
        this.props.history.push('/')
    }
    render() {
        return (
            <AccessLayout>
                <Heading>Password Reset</Heading>
                <SubHeading>Enter your email address to reset your password.</SubHeading>
                <ResetForm onSubmit={this.props.handleSubmit((e) => this.forgotPassword(e))}>
                    <Field component={renderField} placeholder="Email" name="email" validate={[required, email]}/>
                    <PillButton disabled={!this.props.valid} type="submit">Reset Password</PillButton>
                </ResetForm>
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </AccessLayout>
        );
    }
}

export default reduxForm({
    form: 'forgot-password',
    destroyOnUnmount: true
})(ForgotPassword);
