import React, { Component } from 'react';
import { Layout, Search, Table, NoResults } from '../components';
import styled from 'styled-components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
  DashboardForm,
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  PrimaryLink,
  Flex,
  Banner,
  FlexRow,
  Legend,
  CustomTable,
  TableRow,
  TableHeader,
  TableItem,
  CHECKMARK,
  DARK_GRAY_OPACITY,
  GREEN,
  FLEX_CENTER, FieldSet, FLEX_BETWEEN, SecondaryButton, ButtonsLessPadding
} from '../components/style';
import Loading from "../components/loader/Loading";
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import renderSelect from '../components/RenderSelect';
import moment from "moment";
import {assessmentCycleService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import checkNullOrEmpty from "../utils/checkNull"

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;
  
  select {
    margin-left: 10px;
  }
  
  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
  }

 
`;

class ReviewResults extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));

    if (user.user_type==="SYSADMIN") {
        this.state = {
          showloader: false,
          successModal: false,
          items: [],
          promptIndex: '',
          pageNumber:1,
          sortedColumn: '',
          searchTerm: '',
          selectedCycle:{},
          cycle: {},
          cycles:[],
          sysadmin: true,
          currentUser: user
        };
    } else {
      this.state = {
        showloader: false,
        successModal: false,
        items: [],
        promptIndex: '',
        pageNumber:1,
        sortedColumn: '',
        searchTerm: '',
        selectedCycle:{},
        cycle: {},
        cycles:[],
        sysadmin: false,
        currentUser: user
      };
    }
  
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.paginate = this.paginate.bind(this);

  }

  componentDidMount() {
    if(this.props.match.params.id){
      this.getAssessmentCycles();
      this.getAssessmentCycleResultSummary(this.props.match.params.id);
    }else{
      this.getAssessmentCycles();
    }

  }

  getAssessmentCycles() {

    this.setState({showLoader: true});

    assessmentCycleService.getAssessmentCycles("locked").then(result => {
        this.setState({cycles: result.data, showLoader: false});
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getAssessmentCycleResultSummary(assessment_cycle_guid) {

    this.setState({showloader: true});
    assessmentCycleService.getAssessmentCycleResultSummary(assessment_cycle_guid).then(result => {
      this.setState({items: result.data, selectedCycle:{guid:assessment_cycle_guid}, showloader: false, send_notification: false});
      this.props.dispatch(initialize(
          'review-results',
          this.state.selectedCycle
      ));
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  paginate (array, page_size, page_number) {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  handleSort(column) {
    const items = SORT_COLUMN(this.state.items, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  render() {
    const headers = ['Full Name', 'Branch', 'Department', 'Team', 'Employee No', 'Average'];
    const { match } = this.props;
    return (
      <Layout title="Review Assessment Cycle Results">
        <div>
          <Flex justify="center">
            <Heading2 as={'h4'}>
              View Assessment Cycle Results
            </Heading2>
          </Flex>
          <DashboardForm>
            <FieldSet>
              <Legend>Schedule</Legend>
              <Wrapper>
                <label>Schedule</label>
                <Field
                    component={renderSelect}
                    id="due-date"
                    name="guid"
                    options={this.state.cycles.map(({name, guid, date_due, organisation_name}) => {
                      let label = `${name} (${moment(date_due).format("DD/MM/YYYY")})`;
                      if (this.state.currentUser.user_type === "SYSADMIN") {
                          label= organisation_name + " > " + label;
                      }
                      return {label: label, value: guid}
                    })}
                    onChange={({target: {value}}) => {
                      this.setState({selectedCycle:{guid:value}});
                      this.getAssessmentCycleResultSummary(value);
                    }}

                />
              </Wrapper>
            </FieldSet>
          </DashboardForm>

          <CustomTable>
            <TableRow>
              <TableHeader onClick={() => this.handleSort('assignee_full_name')}>
                Full Name
              </TableHeader>
              {this.state.sysadmin
                    ?
                    <TableHeader onClick={() => this.handleSort('organisation_name')}>
                    Organisation
                    </TableHeader>
                  : null
              }
              <TableHeader onClick={() => this.handleSort('branch_name')}>
                Branch
              </TableHeader>
              <TableHeader onClick={() => this.handleSort('department_name')}>
                Department
              </TableHeader>
              <TableHeader onClick={() => this.handleSort('team_name')}>
                Team
              </TableHeader>
              {/* <TableHeader onClick={() => this.handleSort('employee_number')}>
                Employee No.
              </TableHeader> */}
              <TableHeader onClick={() => this.handleSort('average')}>
                Average
              </TableHeader>
            </TableRow>
            {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((obj, i) =>
              <TableRow key={i}>
                <TableItem>
                  {obj.assignee_full_name}
                </TableItem>
                {this.state.sysadmin
                    ?
                    <TableItem>
                      {checkNullOrEmpty(obj.organisation_name)}
                    </TableItem>
                    : null
                }
                <TableItem>
                  {checkNullOrEmpty(obj.branch_name)}
                </TableItem>
                <TableItem>
                  {checkNullOrEmpty(obj.department_name)}
                </TableItem>
                <TableItem>
                  {checkNullOrEmpty(obj.team_name)}
                </TableItem>
                {/* <TableItem>
                  {obj.employee_number}
                </TableItem> */}
                <TableItem>
                  {checkNullOrEmpty(obj.average)}
                </TableItem>
              </TableRow>
            ) : <NoResults searchTerm={this.state.searchTerm} /> }
          </CustomTable>

          <Buttons>
            <SecondaryLink to="#" onClick={()=>this.props.history.goBack()}>
              Cancel
            </SecondaryLink>
            <PrimaryLink to={`/performance-management/draft-review-feedback/${this.state.selectedCycle.guid}`}>
              Compile Draft Feedback
            </PrimaryLink>
          </Buttons>

        </div>
        
        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type}/>}
         
      </Layout>
    );
  }
};

export default reduxForm({
  form: 'review-results'
})(ReviewResults);