import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';
import styled, {css} from "styled-components";


const Error = styled.span`
  position: absolute;
  bottom: 6px;
  left: 10px;
  color: red;
  opacity: 0;
  font-size: 12px;
  transition: all 0.1s linear;
  white-space: nowrap;

  ${props => props.error && css`
    opacity: 1;
  `};
`

const Captcha = (props) => (
    <div>
        <ReCAPTCHA
            sitekey={props.sitekey}
            ref={props.ref}
            onChange={props.input.onChange}
        />
        <Error error={props.meta.error && props.meta.dirty}>{props.meta.error}</Error>
    </div>
);
export default Captcha;