import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const userAdministrationService = {
    getUsers,
    getUser,
    updateUser,
    addUser,
    deleteUser
}


function getUsers(search) {
    return ajax.handlePost(`${API_ROOT}/user/search`, {search: search ? search : ""})
}

function getUser(guid) {
    return ajax.handleGet(`${API_ROOT}/user/retrieve/${guid}`)
}

function updateUser(data) {
    return ajax.handlePost(`${API_ROOT}/user/update`, data)
}

function addUser(data) {
    return ajax.handlePost(`${API_ROOT}/user/add`, data)
}

function deleteUser(data) {
    return ajax.handlePost(`${API_ROOT}/user/delete`, {guid: data})
}