import React from "react";
import { Link } from "react-router-dom";
import { EditField } from "../components/style";
import Pencil from "../images/Pencil.svg";
import Trash from "../images/Trash.svg";

const EditFieldComponent = ({ match, promptDelete, index }) => (
  <EditField>
    <Link to={`${match.path}/add-edit/${index}`}>
      <img src={Pencil} alt="Edit" />
    </Link>
    <img src={Trash} alt="Delete" onClick={() => promptDelete(index)} />
  </EditField>
);

export default EditFieldComponent;
