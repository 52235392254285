import authHeader from './auth-header'
import {API_ROOT} from '../api-config';
import fetchProgress from 'fetch-progress'

export default {

    auth(data) {
        const self = this;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Origin': '*'
            },
            body: JSON.stringify(data)
        };
        return fetch(`${API_ROOT}/auth/login`, requestOptions)
            .then(self.handleResponse)
            .then(result => {
                // login successful if there's a user in the response
                if (result) {
                    // store user details and basic auth credentials in local storage
                    // to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(result.data));
                    localStorage.setItem('authToken', result.bearerToken);

                    if (result.data.user_type === "SYSADMIN") {
                        window.location = `/integrators`
                    } else if (result.data.user_type === "ORGADMIN") {
                        window.location = `/organisation/add-edit/${result.data.fk_organisation}`
                    } else if (result.data.user_type === "MANAGER") {
                        window.location = `/employees`
                    } else if (result.data.user_type === "USER") {
                        window.location = `/profile`
                    } else {
                        window.location = `/?error=No user type?`
                    }

                }

                return result;
            });
    },

    logout() {
        const self = this;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Origin': '*'
            },
        };
        return fetch(`${API_ROOT}/auth/logout`, requestOptions)
            .then(self.handleResponse)
            .then(data => {

                if (data) {
                    localStorage.removeItem("user");
                    localStorage.removeItem('authToken');
                    window.location = "/";

                }

                return data;

            });

    },

    handlePost(url, data) {

        const self = this;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authHeader(),
                'Content-Type': 'application/json',
                'Origin': '*'
            },
            body: JSON.stringify(data)
        };
        return fetch(url, requestOptions)
            .then(self.handleResponse)
            .then(data => {
                // login successful if there's a user in the response
                return data;
            });
    },

    handlePostMultiPart(url, data) {

        let formData = new FormData();

        for (const name in data) {
            formData.append(name, data[name]);
        }

        const self = this;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authHeader(),
                'Origin': '*'
            },
            body: formData
        };
        return fetch(url, requestOptions)
            .then(self.handleResponse)
            .then(data => {
                // login successful if there's a user in the response
                return data;
            });
    },

    handleGet(url) {
        const self = this;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authHeader(),
                'Origin': '*'
            },
        };
        return fetch(url, requestOptions)
            .then(
                fetchProgress({ //https://www.npmjs.com/package/fetch-progress
                    // implement onProgress method
                    onProgress(progress) {
                      console.log({ progress });
                      // A possible progress report you will get
                      // {
                      //    total: 3333,
                      //    transferred: 3333,
                      //    speed: 3333,
                      //    eta: 33,
                      //    percentage: 33
                      //    remaining: 3333,
                      // }
                    },
                  })
            )
            .then(self.handleResponse)
            .then(data => {
                return data;
            });
    },

    handleResponse(response) {

        if (response.headers.get('Content-Type').includes('application/json')) {

            return response.text().then(text => {

                const data = text && JSON.parse(text);

                if (data && data.redirect && (data.redirect.length > 0)) {
                    let win = window.open(data.redirect, "_blank");
                    var timer = setInterval(function() {
                        if (win.closed) {
                            clearInterval(timer);
                            const event = new Event('refresh');
                            event.responseData = data;
                            window.dispatchEvent(event);
                        }
                    }, 1000);
                }
                
                localStorage.setItem("lastRequestTime", String(new Date().getTime()));

                if (!response.ok) {
                    
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('user');
                        window.location = '/?error=' + (data && data.message)
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
                
            });

        } else if (response.headers.get('Content-Type').includes('text/html')) {

            return response.text().then(text => {
                const data = text;
                if (!response.ok) {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('user');
                        window.location = '/?error=' + (data && data.message)
                    }

                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }

                return data;
            });

        } else {
            return response.blob().then(blob => {
                const data = blob;
                if (!response.ok) {
                    if (response.status === 401) {
                        // auto logout if 401 response returned from api
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('user');
                        // window.location = ''
                    }

                    const error = response.statusText;
                    return Promise.reject(error);
                }
                return data;
            })
        }

    }
}