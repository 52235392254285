import React, {Component} from 'react';
import {Field, initialize, reduxForm} from 'redux-form';
import {Layout} from '../components';
import {userAdministrationService, mediaService} from "../services";
import {
  DashboardForm,
  FieldSet,
  Buttons,
  MultiColumn,
  SecondaryLink,
  Legend,
  FormMeta,
  EditDate,
  LogoRow,
  LogoImg,
  PictureLogo,
  IconContainer, PrimaryButton
} from '../components/style';
import renderField from '../components/RenderField';
import moment from "moment";
import Camera from "../images/Camera.svg";
import formValueSelector from "redux-form/es/formValueSelector";
import { connect } from 'react-redux'
import {email, required, minLength} from '../utils/validation';
import TypedModal from "../components/modals/TypedModal";
import Loading from '../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../components/style";

const matchPassword = (input, allInputs) => {
  return input === allInputs.password ? undefined : 'Passwords don\'t match';
}
const minLength8 = minLength(8);

class Profile extends Component {

  constructor(props, context) {
    
    super(props, context);
    
    this.state = { user: JSON.parse(localStorage.user), handle:"", showloader:false};
    
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);

    this.props.dispatch(initialize(
      'profile-edit',
      this.state.user
    ))

    if (this.state.user.guid) {
      this.loadUser(this.state.user.guid);
      this.getUserPicturePicture(this.state.user.guid);
    }
    
  }

  // also load user from api
  loadUser(guid) {

    this.setState({showloader: true});
    userAdministrationService.getUser(guid).then(result => {
      
      this.setState({showloader: false});
      this.setState({user: result.data})

      this.props.dispatch(initialize(
          'profile-edit',
          this.state.user
      ));

    });

  }

  saveUser(data, id) {

    this.setState({showloader: true});
    
    if(!this.props.password){
      delete data.password;
      delete data.repeatPassword;
    }else{
      delete data.repeatPassword;
    }
    //console.log('preparing to update user profile');
    //console.log(data);
    //do update
    userAdministrationService.updateUser(data).then(result => {

      delete data.password;
      delete data.repeatPassword;
      this.setState({showloader: false});
      this.showModal('modal', result.message, 'success');

    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error');
    });

  }

  showModal(modal, message , type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    this.setState({[modal]: false, message:'', type:''});
  }

  getUserPicturePicture(guid) {
    mediaService.getUserPicture(guid).then(response => {
        if (response.data && response.data.error) {

        } else {
            this.setState({userPicture: URL.createObjectURL(response)});
        }

    })
  }

  uploadPhoto(event) {
    this.setState({showloader: true});
    let data = {
        file: event.target.files[0],
    };

    mediaService.uploadUserPicture(this.props.match.params.id?this.props.match.params.id:this.state.user.guid, data).then(response => {
      this.setState({showloader: false});
      if (!response.error) {
            this.getUserPicturePicture(this.props.match.params.id?this.props.match.params.id:this.state.user.guid);
        } else {
          this.setState({showloader: false});
        }
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error');
    });
  }

  render() {
    return (
      <Layout 
        title="Profile"
        dateCreated={this.state.user.date_created}
        dateModified={this.state.user.date_modified}
        dirty={this.props.dirty}
      >
        
        <DashboardForm onSubmit={this.props.handleSubmit((e) => this.saveUser(e, this.props.match.params.id))} onKeyPress={e=> {
          if(e.key==="Enter"){
            e.preventDefault();
          }
        }}>
          
          <LogoRow>
            <PictureLogo>
              {this.state.userPicture ? "" : "User Profile Picture"}
              <IconContainer>
                <img src={Camera} alt="Camera"/>
              </IconContainer>
              {this.state.userPicture ? <LogoImg src={this.state.userPicture}/> : ""}
              <input type="file" name="Logo" placeholder="logo" onChange={this.uploadPhoto}/>
            </PictureLogo>
          </LogoRow>
          <FieldSet>
            <Legend>User information</Legend>
            <MultiColumn>
              <MultiColumn>
                <Field component={renderField} placeholder="Name" name="first_name" validate={[required]} required/>
              </MultiColumn>
              <MultiColumn>
                <Field component={renderField} placeholder="Surname" name="last_name" validate={[required]} required/>
              </MultiColumn>
            </MultiColumn>
            <MultiColumn>
              <Field component={renderField} placeholder="Telephone" name="contact_number"/>
              <Field component={renderField} placeholder="Email" name="user_name" validate={[required, email]} disabled={this.state.user.user_type !== 'SYSADMIN'} required/>
            </MultiColumn>
          </FieldSet>
  
          <FieldSet>
            <Legend>Change Password</Legend>
            <MultiColumn>
              <Field component={renderField} type="password" placeholder="Password" name="password" validate={this.props.password?[minLength8]:[]}/>
              <Field component={renderField} type="password" placeholder="Repeat Password" name="repeatPassword" validate={this.props.password?[required, matchPassword]:[]}/>
            </MultiColumn>
          </FieldSet>
  
          <Buttons>
            <SecondaryLink to="#" onClick={this.props.dirty? (e) => 
                {
                    e.preventDefault();
                    this.showModal('modal', 'You have made changes that you will be losing if you do not save!', 'info');
                }
            :undefined}>Cancel</SecondaryLink>
            <PrimaryButton type="submit" disabled={!this.props.valid}>Save</PrimaryButton>
          </Buttons>
        </DashboardForm>
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
                
        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

      </Layout>
    );
  };

}
const selector = formValueSelector('profile-edit')

Profile = reduxForm({
  form: 'profile-edit'
})(Profile);

export default Profile = connect(state=>{
  const password = selector(state, 'password');
  return{
    password: password
  }
})(Profile);