import React, { Fragment } from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import renderField from "./RenderField";
import renderSelectNoLabel from "./RenderSelectNoLabel";
import {
  FormRow,
  AddAnswerBtn,
  FlexCenter,
  Label as ImportedLabel,
  FieldSet,
  Legend
} from "./styles";
import renderSelect from "./RenderSelect";
import {required} from "../../../../utils/validation";
import PromptModal from '../../../../components/modals/PromptModal';
import TypedModal from "../../../../components/modals/TypedModal";

const Label = styled(ImportedLabel)`
  margin-right: 10px;

  > input {
    margin-right: 10px;
  }
`;

const ratingScaleOptions = [
  {title: 1, value: 1},
  {title: 2, value: 2},
  {title: 3, value: 3},
  {title: 4, value: 4},
  {title: 5, value: 5}
]

const renderAnswers = (
  { fields, question, questionType, answerOptions, answerDelete, meta: { error }, validateAnswers }
) => {

  let answerOpt = answerOptions?answerOptions.map(answer=> {
    return {label: answer.answer_multi, value: answer.answer_multi}
  }):[];
  return (
  <FieldSet>
    <Legend>{questionType}</Legend>
    {questionType === "True / False" && (
      <FlexCenter>
        <Label>
          <Field
            name={`${question}.answers[0].answer_truefalse`}
            component="input"
            type="radio"
            value='true'
            validate={validateAnswers}
            required
          />
          True
        </Label>
        <Label>
          <Field
            name={`${question}.answers[0].answer_truefalse`}
            component="input"
            type="radio"
            value='false'
            validate={validateAnswers}
            required
          />
          False
        </Label>
      </FlexCenter>
    )}
    {questionType === "Multiple Choice" && (
      <Fragment>
        {fields.map((answer, i) => (
          <Field
            key={`${question}-${answer}-${i}`}
            name={`${answer}.answer_multi`}
            type="text"
            component={renderField}
            label={`Answer #${i + 1}`}
            fields={fields}
            index={i}
            deleteBtn
            deleteBtnOnClick={answerDelete}
            validate={required}
            required
          />
        ))}
        <FormRow>
          <AddAnswerBtn type={"button"} disabled={fields.length>3} onClick={() => 
              {
                fields.push({})
              }
            } style={fields.length>3?{display: 'none'}:{marginTop: '-6px'}}>
            + Add Answer
          </AddAnswerBtn>
        </FormRow>
        {fields.length > 0 && (
          <FieldSet style={fields.length>3?{marginTop: '-12px'}:{}}>
            <Legend>Correct Answer</Legend>
            <Field component={renderSelectNoLabel} name={`${question}.correct_answer`} validate={[required]} props={{placeHolder: 'Please Select'}}>
              {answerOpt}
            </Field>
          </FieldSet>
        )}
      </Fragment>
    )}
    {questionType === "Rating Scale" && (
      <FlexCenter>
        <table>
          <thead>
          <tr>
            <td style={{textAlign: 'center', verticalAlign: 'middle'}}><b><Label style={{width:'100%', paddingLeft: '2px'}}>1</Label></b></td>
            <td style={{textAlign: 'center', verticalAlign: 'middle'}}><b><Label style={{width:'100%', paddingLeft: '2px'}}>2</Label></b></td>
            <td style={{textAlign: 'center', verticalAlign: 'middle'}}><b><Label style={{width:'100%', paddingLeft: '2px'}}>3</Label></b></td>
            <td style={{textAlign: 'center', verticalAlign: 'middle'}}><b><Label style={{width:'100%', paddingLeft: '2px'}}>4</Label></b></td>
            <td style={{textAlign: 'center', verticalAlign: 'middle'}}><b><Label style={{width:'100%', paddingLeft: '2px'}}>5</Label></b></td>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>
              <Field
                name={`${question}.answers[0].answer_scale`}
                component="input"
                type="radio"
                value="1"
                validate={validateAnswers}
                required
              />
            </td>
            <td>
              <Field
                name={`${question}.answers[0].answer_scale`}
                component="input"
                type="radio"
                value="2"
                validate={validateAnswers}
                required
              />    
            </td>
            <td>
              <Field
                name={`${question}.answers[0].answer_scale`}
                component="input"
                type="radio"
                value="3"
                validate={validateAnswers}
                required
              />    
            </td>
            <td>
              <Field
                name={`${question}.answers[0].answer_scale`}
                component="input"
                type="radio"
                value="4"
                validate={validateAnswers}
                required
              />    
            </td>
            <td>
              <Field
                name={`${question}.answers[0].answer_scale`}
                component="input"
                type="radio"
                value="5"
                validate={validateAnswers}
                required
              />
            </td>
          </tr>
          </tbody>
        </table>
      </FlexCenter>
    )}
  </FieldSet>
)};

export default renderAnswers;
