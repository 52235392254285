import React, { Fragment, Component } from  'react';
import styled, { css } from 'styled-components';
import { GREEN } from './style';

const HamburgerContainer = styled.div`
  width: 60px;
  height: 45px;
  position: relative;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: ${GREEN};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2) {
    top: 18px;
  }

  span:nth-child(3) {
    top: 36px;
  }

  ${props => props.isOpen && css `
  
    span:nth-child(1) {
      top: 18px;
      transform: rotate(135deg);
    }

    span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    span:nth-child(3) {
      top: 18px;
      transform: rotate(-135deg);
    }
  `}
`;

const Hamburger = (props) => (
  <HamburgerContainer onClick={props.handleClick} isOpen={props.isOpen}>
    <span></span>
    <span></span>
    <span></span>
  </HamburgerContainer>
);

export default Hamburger;