import React, {Component} from 'react';
import {EditField, Layout, NoResults, Search} from '../components';
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import PromptModal from '../components/modals/PromptModal';
import {userAdministrationService} from "../services";
import {
  CustomTable,
  DARK_GRAY_OPACITY, GREEN,
  PaginationButtons,
  PrimaryButton,
  TableHeader,
  TableItem,
  TableRow
} from "../components/style";
import data from './admin-users/data';
import TypedModal from "../components/modals/TypedModal";
import Loading from "../components/loader/Loading";

class AdminUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      items: [],
      promptIndex: '',
      sortedColumn: '',
      searchTerm: '',
      pageNumber:1,
      showLoader:false
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleApiSearch = this.handleApiSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.paginate = this.paginate.bind(this);


  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers(search) {
    this.setState({showLoader:true});
    userAdministrationService.getUsers(search).then(result => {
        this.setState({items:result.data, showLoader:false})
    }).catch(error => {
      this.setState({showLoader:false});
      this.showModal('modal', error, 'error');
    })
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    this.setState({[modal]: false, message:null, type:null});
  }

  handleSearch({target: {value}}) {
    //const items = SEARCH_COLLECTION(this.state.items, value);
    this.setState({searchTerm: value});
    if(value===""){
      this.getUsers();
    }
  }
  handleApiSearch(){
    this.getUsers(this.state.searchTerm);
  }

  handleDelete() {
    userAdministrationService.deleteUser(this.state.promptIndex).then(result=>{
      this.getUsers();
      this.hideModal('promptModal')
    })
    // const items = this.state.items.filter((_, i) => i !== this.state.promptIndex);
    // this.setState({ items, promptIndex: '' });
  }

  handleSort(column) {
    const items = SORT_COLUMN(this.state.items, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  promptDelete(index) {
    this.showModal('promptModal');
    this.setState({ promptIndex: index });
  }

  handleNext() {
    this.setState({ pageNumber: this.state.pageNumber+1 });
  }
  handleBack() {
    let currentPage = this.state.pageNumber;
    let setVal=0;
    if(currentPage-1===0){
      setVal=1
    }else{
      setVal=currentPage-1
    }
    this.setState({ pageNumber: setVal });
  }
  paginate (array, page_size, page_number) {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  // handleRowClick(guid) {
  //   //please review this
  //   this.props.history.push(this.props.match.path + "/add-edit/" + guid);
  // }

  render() {
    return (
      <Layout title="User Search">
        <div>
          <Search match={this.props.match} handleSearch={this.handleSearch} apiSearch={this.handleApiSearch}/>
          <CustomTable>
            <TableRow>
              <TableHeader onClick={() => this.handleSort('user_name')}>
                Email
              </TableHeader>
              <TableHeader onClick={() => this.handleSort('first_name')}>
                First Name
              </TableHeader>
              <TableHeader onClick={() => this.handleSort('last_name')}>
                Last Name
              </TableHeader>
              <TableHeader >
                User Type
              </TableHeader>
              <TableHeader >
                Status
              </TableHeader>
              <TableHeader >
                Action
              </TableHeader>
            </TableRow>
            {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((user, i) =>
              <TableRow key={i}>
                <TableItem>
                  {user.user_name}
                </TableItem>
                <TableItem>
                  {user.first_name}
                </TableItem>
                <TableItem>
                  {user.last_name}
                </TableItem>
                <TableItem>
                  {user.user_type}
                </TableItem>
                <TableItem>
                  {user.deleted == 1 ? 'Deleted' : user.active == 1 ? 'Active' : 'Inactive'}
                </TableItem>
                <TableItem>
                  <EditField
                      match={this.props.match}
                      promptDelete={this.promptDelete}
                      index={user.guid}
                  />
                </TableItem>
              </TableRow>
            ) : <NoResults searchTerm={this.state.searchTerm} />}
          </CustomTable>
          <PaginationButtons>
            {this.state.pageNumber>1?<PrimaryButton onClick={this.handleBack}>Back</PrimaryButton>:<div></div>}
            {10*this.state.pageNumber<this.state.items.length?<PrimaryButton  onClick={this.handleNext}>Next</PrimaryButton>:<div></div>}
          </PaginationButtons>
        </div>
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} />}
        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>
      </Layout>
    );
  }
};

export default AdminUsers;