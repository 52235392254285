import React, { Component } from 'react';
import { Layout, Search, CheckBox, NoResults } from '../components';
import {
  Buttons,
  PillButton,
  SecondaryLink,
  CustomTable,
  TableRow,
  TableHeader,
  TableItem,
  Heading2,
  FormRow as PaddedFlex
} from '../components/style';
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import data from './review-feedback/data';

class ReviewFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      successModal: false,
      items: data,
      promptIndex: '',
      sortedColumn: '',
      searchTerm: ''
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
  }

  showModal(modal) {
    this.setState({ [modal]: true });
  }

  hideModal(modal) {
    this.setState({ [modal]: false });
  }

  handleSearch({ target: { value } }) {
    const items = SEARCH_COLLECTION(data, value);
    this.setState({ items, searchTerm: value });
  }

  handleDelete() {
    const items = this.state.items.filter((_, i) => i !== this.state.promptIndex);
    this.setState({ items, promptIndex: '' });
  }

  handleSort(column) {
    const items = SORT_COLUMN(data, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }
  promptDelete(index) {
    this.showModal('promptModal');
    this.setState({ promptIndex: index });
  }

  render() {
    const headers = ['Full Name', 'Employee No', 'Average', 'Score', 'Feedback', 'Status']
    return (
      <Layout title="Review Assessment Feedback">
        <div>
          <PaddedFlex justify="center">
            <Heading2 as={'h4'}>View Assessment Feedback and discard or allow it</Heading2>
          </PaddedFlex>
          <Search match={this.props.match} handleSearch={this.handleSearch} />
          <CustomTable>
            <TableRow>
              {headers &&
                headers.map((header, i) => (
                  <TableHeader
                    key={header}
                    onClick={() => this.handleSort(`column${i + 1}`)}
                  >
                    {header}
                  </TableHeader>
                ))}
            </TableRow>
            {this.state.items.length ? this.state.items.map((item, i) =>
              <TableRow key={i}>
                <TableItem>
                  {item.column1}
                </TableItem>
                <TableItem>
                  {item.column2}
                </TableItem>
                <TableItem>
                  {item.column3}
                </TableItem>
                <TableItem>
                  {item.column4}
                </TableItem>
                <TableItem>
                  {item.column5}
                </TableItem>
                <TableItem>
                  <CheckBox />
                </TableItem>
              </TableRow>
            ) : <NoResults searchTerm={this.state.searchTerm} /> }
          </CustomTable>
          <Buttons>
            <SecondaryLink to="#">
              Cancel
            </SecondaryLink>
            <PillButton 
              type="submit"
              onClick={() => this.showModal('successModal')}>
              Save
            </PillButton>
          </Buttons>
        </div>
        {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')} />}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} />}
      </Layout>
    );
  }
};

export default ReviewFeedback;