import styled from "styled-components";
import {
  StyledNavLink,
  LIGHT_GRAY,
  LIGHT_GREEN,
  GREEN,
  WHITE,
  ROBOTO_REGULAR
} from "../style";

const AccordionItem = styled(StyledNavLink)`
  ${ROBOTO_REGULAR};
  color: ${GREEN};
  background: ${WHITE};
  border-left: solid 3px ${WHITE};
  border-bottom: solid 1px ${LIGHT_GREEN};
  position: relative;
  padding: 0 30px;
  line-height: 42px;
  display: block;
  white-space: nowrap;

  &:last-of-type {
    border-bottom: solid 1px transparent;
  }

  &.active,
  &:hover {
    border-left-color: ${GREEN};
    background-color: ${LIGHT_GRAY};
    cursor: pointer;
  }
`;

export default AccordionItem;
