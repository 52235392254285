import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {RenderField, RenderPassword, CheckBox, AccessLayout} from '../../components';
import {PrimaryButton, SecondaryButton} from '../../components/style';
import {
    SignUpForm,
    Buttons,
    MultiColumn,
    StyledLink,
    Terms, 
    Heading, 
    SubHeading,
    CheckBoxAndLabel
} from './style';
import { ReCaptcha } from 'react-recaptcha-v3'
import {email, required, minLength, alpha, alphaNumeric} from '../../utils/validation';
import SuccessModal from "../../components/modals/SuccessModal";
import MapperUtil from '../../utils/objectMapper'
import {organisationService} from "../../services";
import { loadReCaptcha } from 'react-recaptcha-v3'
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

const minLength1 = minLength(1);
const minLength2 = minLength(2);
const minLength8 = minLength(8);
const lower = value => value && value.toLowerCase();

const matchPassword = (input, allInputs) => input === allInputs.password ? undefined : 'Passwords don\'t match';

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            load: false, 
            showloader: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.reCaptchaRef = React.createRef();
        this.handleCaptcha = this.handleCaptcha.bind(this);
    }

    componentDidMount() {
        this.setState({load: true})
        loadReCaptcha('6LeGIZsUAAAAAL7LJx-bPtNaFqWMdo7B_e2HPdFr');
    }

    handleCaptcha(value) {
        //console.log('Captcha Value', value)
    }

    signUp(data) {
        this.setState({showloader: true});
        delete data.repeatPassword;
        MapperUtil.remapObject(data, false);
        organisationService.registerOrganisation(data).then(response=>{
            this.setState({showloader: false});
            this.showModal('successModal', response.message)
        })
    }

    showModal(modal, message) {
        this.setState({[modal]: true, message: message});
    }

    hideModal(modal) {
        this.setState({[modal]: false, message: ''});
        this.props.history.push('/')
    }

    handleTermsClick(guid) {
        //please review this
        window.open('https://assessme.online/terms', '_blank');
    }

    render() {
        return (
            <AccessLayout>
                <Heading>Welcome</Heading>
                <SubHeading>Sign up your below</SubHeading>
                <SignUpForm onSubmit={this.props.handleSubmit((e) => this.signUp(e))}>
                    <MultiColumn>
                        <Field
                            component={RenderField}
                            placeholder="Name"
                            name="first_name"
                            validate={[required, minLength1, alpha]} 
                            required
                        />
                        <Field
                            component={RenderField}
                            placeholder="Surname"
                            name="last_name"
                            validate={[required, minLength2, alpha]}
                            required
                        />
                    </MultiColumn>
                    
                    <MultiColumn>
                        <Field
                            component={RenderField}
                            placeholder="Email"
                            name="email"
                            type="email"
                            validate={[required, email]} 
                            required 
                            normalize={lower}
                        />
                        <Field
                            component={RenderField}
                            placeholder="Name of your organisation"
                            name="organisation"
                            validate={[required, alphaNumeric]} 
                            required
                        />
                    </MultiColumn>

                    <MultiColumn>
                        <Field
                            component={RenderPassword}
                            type="password"
                            placeholder="Password"
                            name="password"
                            validate={[required, minLength8]} 
                            required
                        />
                        <Field
                            component={RenderField}
                            type="password"
                            placeholder="Repeat Password"
                            name="repeatPassword"
                            validate={[required, matchPassword]} 
                            required
                        />
                    </MultiColumn>

                    <CheckBoxAndLabel>
                        <Field component={CheckBox} name="is_employee"/> By ticking this box you confirm that you are an employee of this Organisation.                   
                    </CheckBoxAndLabel>

                    <Terms>
                        <Field component={CheckBox} name="terms_accepted" validate={[required]} required/> By ticking this box you agree to
                        our <StyledLink  onClick={() => this.handleTermsClick()}>Terms and Privacy Policy.</StyledLink>
                    </Terms>

                    <ReCaptcha
                        sitekey="6LeGIZsUAAAAAL7LJx-bPtNaFqWMdo7B_e2HPdFr"
                        action='signup'
                        verifyCallback={this.handleCaptcha}
                    />
                    <Buttons>
                        <SecondaryButton
                            as={'a'}
                            target="_blank"
                            href="https://assessme.online/"
                            to="learn-more"
                        >
                            Learn More
                        </SecondaryButton>
                        <PrimaryButton disabled={!this.props.valid}>
                            Sign Up
                        </PrimaryButton>
                    </Buttons>
                    {this.state.successModal &&
                    <SuccessModal hideModal={() => this.hideModal('successModal')} message={this.state.message}/>}
                </SignUpForm>
                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </AccessLayout>

        );
    }
};

export default reduxForm({
    form: 'sign-up'
})(Form);