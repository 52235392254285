import React, {Component} from 'react';
import styled from 'styled-components';
import {Field, initialize, reduxForm} from 'redux-form';
import {CheckBox, Layout} from '../../components';
import {
    Buttons,
    DashboardForm,
    EditDate,
    FieldSet,
    FormMeta,
    GRAY,
    IconContainer,
    Legend,
    LogoImg,
    LogoRow,
    MultiColumn,
    PictureLogo,
    PillButton, PrimaryLink,
    SecondaryLink,
    SingleColumn
} from '../../components/style';
import {required, minLength, alpha, alphaNumeric, email} from '../../utils/validation';
import Camera from '../../images/Camera.svg';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {employeeService, lookupService, mediaService, organisationService, userService} from "../../services";
import moment from "moment";
import MapperUtil from '../../utils/objectMapper'
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from "../../components/modals/PromptModal";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";
import "react-scroll-to-top/lib/index.css"

const minLength1 = minLength(1);

const UnstyledLegend = styled(Legend)`
  border: 0;
  background: transparent;
  color: ${GRAY};
`;

const southAfricaGuid = ['049a3444-9d89-11e9-835a-068fc7503a26','854116d9-9e31-11e9-835a-068fc7503a26'];

const emailValidate = val => {
    return (!email(val)) ? undefined : "Not a valid email.";
}

class AddEdit extends Component {
    
    constructor(props, context) {
        super(props, context);
        this.state = {
            organisation: {}, 
            handle:"", 
            countries:[], 
            provinces:[], 
            selectedCountry:{}, 
            message:'', 
            orgPicture:null, 
            employees:[], 
            selectedManager:{}, 
            showloader: false,
            manager_fields_disabled: true
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.getLookups();

    }

    handleConfirmation() {
        window.location = "/organisation";
    }

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.loadOrganisation(this.props.match.params.id);
            this.getOrganisationPicture(this.props.match.params.id);
        }
    }

    loadOrganisation(guid) {

        this.setState({showloader: true});

        organisationService.getOrganisation(guid).then(result => {

            this.setState({showloader: false});
            MapperUtil.remapObject(result.data, true);
            this.getEmployees(result.data.guid, result.data.fk_employee_guid);
            this.setState({organisation: result.data, handle:result.data.handle, selectedCountry:result.data.address_obj? result.data.address_obj.country:""});
            this.props.dispatch(initialize(
                'organisation-add-edit',
                this.state.organisation
            ));

        }).catch(error => {
            this.setState({showloader: false});
            this.showModal('modal', error, 'error')
        });
        
    }

    getEmployees(orgGuid, managerGuid) {

        employeeService.getOrganisationEmployees(orgGuid).then(result => {

            let employee = {guid: "new", "first_name": "New", "last_name": ""};
            let arr = [];
            arr.push(employee);
            arr = arr.concat(result.data);
            this.setState({employees: arr});
            if (managerGuid) {
                this.setState({selectedManager: result.data.find(emp => emp.guid === managerGuid)})
            }

        }).catch(error => {
            console.log(error);
        });

    }

    getOrganisationPicture(guid){

        mediaService.getOrganisationPicture(guid).then(response=>{
            if(response.data && response.data.error){

            }else{
                this.setState({orgPicture:URL.createObjectURL(response)});
            }

        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

    }

    uploadPhoto(event){

        let data = {
            file:event.target.files[0],
        };

        mediaService.uploadOrganisationPhoto(this.props.match.params.id, data).then(response=>{
            if(!response.error){
                this.getOrganisationPicture(this.props.match.params.id);
            }
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

    }

    getLookups(){

        lookupService.getCountries().then(countries =>{
            let countryLookup = []
            countries.data.map(({name, guid})=>{
                return countryLookup.push({label:name, value:guid});
            })
            this.setState({countries:countryLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

        lookupService.getProvinces().then(provinces =>{
            let provinceLookup = []
            provinces.data.map(({name, guid})=>{
                return provinceLookup.push({label:name, value:guid});
            })
            this.setState({provinces:provinceLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {

        if(this.state.type==='success' && JSON.parse(localStorage.getItem('user')).user_type==='SYSADMIN'){
            this.setState({[modal]: false, message:null, type:null});
            this.props.history.push('/organisation')
        }else{
            this.setState({[modal]: false, message:null, type:null});
        }

    }

    saveOrganisation(data, id){

        this.setState({showloader: true});
        MapperUtil.remapObject(data, false);

        if (id === 'new') {

            // do add
            organisationService.addOrganisation(data).then(result => {

                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success');

                window.scrollTo(0, 0);

            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            });

        } else {

            //do update
            if (!data.contact_person_obj) { data.contact_person_obj = {} };
            data.contact_person_obj.first_name = this.state.selectedManager.first_name;
            data.contact_person_obj.last_name = this.state.selectedManager.last_name;
            data.contact_person_obj.phone_number = this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : '';
            data.contact_person_obj.fax = this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.fax : '';;
            data.contact_person_obj.email = this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : '';
            data.manager_first_name = !this.state.manager_fields_disabled ? data.manager_first_name_editable : this.state.selectedManager.first_name;
            data.manager_last_name = !this.state.manager_fields_disabled ? data.manager_last_name_editable : this.state.selectedManager.last_name;
            data.manager_email = !this.state.manager_fields_disabled ? data.manager_email_editable : this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : '';
            data.manager_mobile = !this.state.manager_fields_disabled ? data.manager_mobile_editable: this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : '';
            data.fk_employee_guid = !this.state.manager_fields_disabled ? "new" : this.state.selectedManager.guid;
            
            delete data.manager_first_name_editable;
            delete data.manager_last_name_editable;
            delete data.manager_email_editable;
            delete data.manager_mobile_editable;

            organisationService.updateOrganisation(data).then(result => {

                this.setState({showloader: false});
                this.loadOrganisation(data.guid);//this was a hack so that checkboxes pull state through

                if(JSON.parse(localStorage.getItem("user")).user_type==="ORGADMIN"){
                    userService.getUser(JSON.parse(localStorage.getItem("user")).guid).then(user=>{
                        localStorage.setItem("user", JSON.stringify(user.data));
                        this.showModal('modal', result.message, 'success');
                    });
                }else{
                    this.showModal('modal', result.message, 'success')
                }

                window.scrollTo(0, 0);

            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            });

        }

    }

    render() {

        return (
            <Layout 
                title={`Organisation ${this.props.match.params.id==='new'?"Add":"Edit"}`}
                dateCreated={this.state.organisation.date_created}
                dateModified={this.state.organisation.date_modified}
                dirty={this.props.dirty}
            >
                
                <DashboardForm onSubmit={this.props.handleSubmit((e) => this.saveOrganisation(e, this.props.match.params.id))} onKeyPress={e=> {
                    if(e.key==="Enter"){
                        e.preventDefault();
                    }
                }}>

                    <LogoRow>
                        <PictureLogo>
                            {this.state.orgPicture?"":"Company Logo"}
                            <IconContainer>
                                <img src={Camera} alt="Camera"/>
                            </IconContainer>
                            {this.state.orgPicture?<LogoImg src={this.state.orgPicture} />:""}
                            <input type="file" name="Logo" placeholder="logo" onChange={this.uploadPhoto}/>
                        </PictureLogo>
                    </LogoRow>

                    <FieldSet>
                        <Legend>Organisation Information</Legend>
                        <MultiColumn>
                            <Field component={renderField} validate={[required, alphaNumeric]}
                                   placeholder="Organisation Name"
                                   name="name"
                                   required
                            />
                            <Field component={renderField} placeholder="Website"
                                   name="website"/>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                {/*<Field component={renderField} validate={required} placeholder="ID" name="id"/>*/}
                                <Field component={renderField} placeholder="Registration Number"
                                       name="registration_number"/>
                                <Field component={renderField} validate={required} placeholder="Trading Name"
                                       name="trading_name" required/>
                            </MultiColumn>
                            
                        </MultiColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Address Information</Legend>
                        <MultiColumn>
                            <Field component={renderField} placeholder="Complex" name="address_obj.complex"/>
                            <Field component={renderField} placeholder="Building" name="address_obj.building"/>
                            <Field component={renderField} placeholder="Floor" name="address_obj.floor"/>
                            <Field component={renderField} validate={required} placeholder="Number" name="address_obj.unit" required/>
                        </MultiColumn>
                        <MultiColumn>
                            <Field component={renderField} validate={required} placeholder="Street" name="address_obj.street" required/>
                            <Field component={renderField} placeholder="Suburb" name="address_obj.suburb"/>
                            <Field component={renderField} placeholder="Town" name="address_obj.city_town" required/>
                            <Field component={renderField} placeholder="Postal Code" name="address_obj.postal_code"/>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderSelect} validate={required} id="country" options={this.state.countries} placeholder="Select Country *" name="address_obj.country" onChange={(e, newVal)=>this.setState({selectedCountry:newVal})}/>
                                {southAfricaGuid.includes(this.state.selectedCountry) && <Field component={renderSelect} id="province" options={this.state.provinces} disabled={!southAfricaGuid.includes(this.state.selectedCountry)} placeholder="Select Province" name="address_obj.province" required/>}
                            </MultiColumn>
                            <MultiColumn/>
                        </MultiColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Contact Information</Legend>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} validate={required} placeholder="Telephone" name="company_contact_obj.phone_number" required/>
                                <Field component={renderField} placeholder="Fax" name="company_contact_obj.fax"/>
                            </MultiColumn>
                            <SingleColumn>
                                <Field component={renderField} placeholder="Web" name="company_contact_obj.web"/>
                            </SingleColumn>
                        </MultiColumn>
                        <SingleColumn>
                            <Field component={renderField} validate={[required, email]} placeholder="Email" name="company_contact_obj.email" required/>
                        </SingleColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Organisation Setup</Legend>
                        <MultiColumn>
                            <Field component={CheckBox} name="setup_branches" placeholder="Branches" disabled={false}/>
                            <Field component={CheckBox} name="setup_departments" placeholder="Departments" disabled={!this.state.organisation.setup_branches}/>
                            <Field component={CheckBox} name="setup_teams" placeholder="Teams" disabled={!(this.state.organisation.setup_branches && this.state.organisation.setup_departments)}/>
                        </MultiColumn>
                    </FieldSet>

                    {this.props.match.params.id !== 'new' && <FieldSet>
                        <Legend>Organisation Head</Legend>
                        <MultiColumn>
                            <Field
                                component={renderSelect}
                                options={this.state.employees.map(emp => {
                                    return {label: `${emp.first_name} ${emp.last_name}`, value: emp.guid}
                                })}
                                placeholder="Organisation Head"
                                name="fk_employee_guid"
                                onChange={({target: {value}}) => {
                                    if (value.toUpperCase() === "NEW") {
                                        this.setState({
                                            selectedManager: {},
                                            manager_fields_disabled: false
                                        });
                                    } else {
                                        const managerIndex = this.state.employees.findIndex(emp => emp.guid === value);
                                        this.setState({
                                            selectedManager: this.state.employees[managerIndex],
                                            manager_fields_disabled: true
                                        });
                                    }
                                }}
                                validate={required}
                                required
                            />
                        </MultiColumn>

                        {this.state.manager_fields_disabled
                        ?
                            <FieldSet>
                                <MultiColumn>
                                    <Field component={renderField} placeholder="Surname" name="manager_last_name" disabled={this.state.manager_fields_disabled}
                                        input={{value: this.state.selectedManager ? this.state.selectedManager.last_name : ""}}
                                        required/>
                                    <Field component={renderField}  placeholder="Name" name="manager_first_name" disabled={this.state.manager_fields_disabled}
                                        input={{value: this.state.selectedManager ? this.state.selectedManager.first_name : ""}}
                                        required/>
                                </MultiColumn>
                                <MultiColumn>
                                    <Field component={renderField} placeholder="Email" name="manager_email" disabled={this.state.manager_fields_disabled}
                                        input={{value: this.state.selectedManager ? this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : "" : ""}}
                                        required/>
                                    <Field component={renderField} placeholder="Telephone" name="manager_mobile" disabled={this.state.manager_fields_disabled}
                                        input={{value: this.state.selectedManager ? this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : "" : ""}}/>
                                </MultiColumn>
                            </FieldSet>
                        :
                            <FieldSet>
                                <MultiColumn>
                                    <Field component={renderField} placeholder="Manager Name" name="manager_first_name_editable" 
                                        validate={[required]} required/>
                                   <Field component={renderField} placeholder="Manager Surname" name="manager_last_name_editable"
                                        validate={[required]} required/>
                                </MultiColumn>
                                <MultiColumn>
                                    <Field component={renderField} placeholder="Email" name="manager_email_editable" 
                                        validate={[required, emailValidate]} required/>
                                    <Field component={renderField} placeholder="Telephone" name="manager_mobile_editable"/>
                                </MultiColumn>
                            </FieldSet>
                        }

                    </FieldSet>}

                    {this.props.match.params.id !== 'new' &&<FieldSet>
                        <UnstyledLegend>Marketing Preferences</UnstyledLegend>
                        <MultiColumn>
                            <Field component={CheckBox} name="contact_person_obj.prefer_sms" placeholder="SMS"/>
                            <Field component={CheckBox} name="contact_person_obj.prefer_email" placeholder="Email"/>
                        </MultiColumn>
                    </FieldSet>}

                    <FieldSet>
                        <Legend>AssessMe Handle</Legend>
                        <MultiColumn>
                            <Field component={renderField} validate={required} placeholder="Handle"
                                   name="handle" required onChange={(e, newVal)=>this.setState({handle:newVal})}/>

                            <Field component={renderField}  placeholder="Website" disabled={true} name="WEBSITE_UNUSED" input={{value:`https://assessme.online/handle/${this.state.handle}`}}/>
                        </MultiColumn>
                    </FieldSet>

                    <Buttons>
                        <SecondaryLink to="/organisation" onClick={this.props.dirty? (e) => 
                            {
                                e.preventDefault();
                                this.showModal('promptModal', 'You have unsaved changes that will be lost. Are you sure you want to cancel?');
                            }
                        :undefined}>Cancel</SecondaryLink>
                        <PillButton disabled={!this.props.valid} type="submit">Save</PillButton>
                    </Buttons>

                    <Buttons>
                        {this.props.match.params.id !== 'new' && <PrimaryLink to={`/organisation/organogram/${this.props.match.params.id}`}>View Organogram</PrimaryLink>}
                    </Buttons>

                </DashboardForm>

                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleConfirmation}  message={this.state.message}/>}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}

                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>
                
            </Layout>
        );
    }
}

export default reduxForm({
    form: 'organisation-add-edit',
    destroyOnUnmount: true
})(AddEdit);