import React, { Component } from 'react';
import { Layout, EditField, NoResults, Search } from '../components';
import {
  Heading2,
  PrimaryButton,
  PaginationButtons,
  Flex,
  CustomTable,
  DARK_GRAY_OPACITY, GREEN,
  TableHeader,
  TableItem,
  TableRow
} from '../components/style';
import SORT_COLUMN from '../utils/sortCollection';
import data from './messages/data';
import * as strings from "../utils/appstring";
import PromptModal from '../components/modals/PromptModal';
import {messagesService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import Loading from '../components/loader/Loading';
import moment from "moment";
import * as queryString from 'query-string'

class Messages extends Component {

  constructor(props) {

    super(props);

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      modal: false, 
      items: [], 
      promptIndex: '', 
      searchTerm: '',
      pageNumber:1,
      showLoader:false
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.paginate = this.paginate.bind(this);
    this.handleApiSearch = this.handleApiSearch.bind(this);

  }

    componentDidMount() {
         this.getMessages();
    }

getMessages(search) {
    this.setState({showLoader:true});
    messagesService.getMessages(search).then(result => {
        this.setState({items: result.data, showLoader:false})
    }).catch(error => {
        this.setState({showLoader:false});
        this.showModal('modal', error, 'error')
    });
  }

  viewMessage(message) {

    this.setState({showLoader:true});
    messagesService.viewMessage(message.message_body_ref, moment(message.date_created).format("YYYY-MM-DD")).then(result => {

        this.setState({showLoader:false});

        if (result.data) {

            this.showModal('modal', result.message, 'info');

        } else {

            let emailTab = undefined;

            try {
                emailTab = window.open();
            } catch (err) {
                console.error(err);
            }

            if (emailTab) {
                emailTab.document.write(result);
                emailTab.document.title = message.message_body_ref;
                emailTab.document.close();
            } else {
                this.showModal('modal', 'Please allow popups in your browser settings!', 'info');
            }

        }

    }).catch(error => {
        this.setState({showLoader:false});
        this.showModal('modal', error, 'error');
    });

  }

  showModal(modal, message, type) {
      this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
      if(this.state.type==='success'){
          this.setState({[modal]: false, message:null, type:null});
      }else{
          this.setState({[modal]: false, message:null, type:null});
      }
  }

  handleSearch({target: {value}}) {
      //const items = SEARCH_COLLECTION(this.state.items, value);
      this.setState({searchTerm: value});
      if(value===""){
          this.getMessages();
      }
  }

  handleApiSearch(){
      this.setState({showLoader:true});
      this.getMessages('');
  }

  handleSort(column) {
      const items = SORT_COLUMN(this.state.items, column);
      this.setState({items});
  }

  handleNext() {
      this.setState({ pageNumber: this.state.pageNumber+1 });
  }

  handleBack() {

      let currentPage = this.state.pageNumber;
      let setVal=0;
      if(currentPage-1===0){
          setVal=1
      }else{
          setVal=currentPage-1
      }
      this.setState({ pageNumber: setVal });

  }

  paginate (array, page_size, page_number) {
      --page_number; // because pages logically start with 1, but technically with 0
      return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  render() {
    return (
      <Layout title="Messages">

        <div>

          <Flex justify="center">
            <Heading2 as={'h4'}>Here are all the system messages that pertain to your user role.</Heading2>
          </Flex>

          <Search match={this.props.match} handleSearch={this.handleSearch} apiSearch={this.handleApiSearch}/>
    
          <CustomTable>
              <TableRow>
                  <TableHeader onClick={() => this.handleSort('message_type')}>
                      Type
                  </TableHeader>
                  <TableHeader onClick={() => this.handleSort('template')}>
                      Reason
                  </TableHeader>
                  <TableHeader onClick={() => this.handleSort('status')}>
                      Status
                  </TableHeader>
                  <TableHeader onClick={() => this.handleSort('date_created')}>
                      Date
                  </TableHeader>
                  <TableHeader>
                      Action
                  </TableHeader>
              </TableRow>
              {(this.state.items.length > 0) ? this.paginate(this.state.items, 10, this.state.pageNumber).map((message, i) =>
                  <TableRow key={i}>
                      <TableItem >
                          {message.message_type == "EMAIL_TEMPLATE" ? "EMAIL" : "UNKOWN"}
                      </TableItem>
                      <TableItem >
                          {message.template.toUpperCase()}
                      </TableItem>
                      <TableItem >
                          {message.status == 1 ? "SENT SUCCESSFULLY" : "SENT"}
                      </TableItem>
                      <TableItem >
                          {moment(message.date_created).format("DD MMMM YYYY")}
                      </TableItem>
                      <TableItem>
                        <PrimaryButton onClick={() => this.viewMessage(message)}>View</PrimaryButton>
                      </TableItem>
                  </TableRow>
                ) : <NoResults searchTerm={this.state.searchTerm}/>}
            </CustomTable>

            <PaginationButtons>
                {this.state.pageNumber > 1 ? <PrimaryButton onClick={this.handleBack}>Back</PrimaryButton> :
                    <div></div>}
                {10 * this.state.pageNumber < this.state.items.length ?
                    <PrimaryButton onClick={this.handleNext}>Next</PrimaryButton> : <div></div>}
            </PaginationButtons>
        </div>

        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete}/>}
        
        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
            <p>Loading, please be patient...</p>
        </Loading>

      </Layout>
    );
  }
};

export default Messages;