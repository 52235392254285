import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "./Button";
import {
  Button as StyledButton,
  COLORS_1,
  COLORS_1_INVERT,
  ROBOTO_REGULAR, 
  ProfileThumbnailImg,
  SmallProfileThumbnail
} from "./style";
import List from "./List";
import ProfilePic from "../images/profile.png";
import {authService, mediaService} from "../services";
import Loading from '../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../components/style";

const ProfileDropDown = styled.div`
  ${ROBOTO_REGULAR};
  ${COLORS_1_INVERT};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 72px;
  margin-right: 0px;
  width: 200px;

  > img {
    margin-right: 10px;
    max-width: 72px;
    max-height: 72px;
  }

  :hover {
    cursor: pointer;
  }
`;

const ListItem = styled(StyledButton)`
  border-radius: 0;
  display: block;
  ${COLORS_1_INVERT};
  text-align: left;
  padding: 20px 40px;

  :hover {
    ${COLORS_1};
  }
`;

const LoginButton = styled(Button)`
  min-width: 200px;
`;

class Profile extends Component {
  
  constructor(props) {

    super(props);

    let user = JSON.parse(localStorage.getItem("user"));
    this.state = { isOpen: false, loggedIn:false, showLoader: false, user: user, loggedIn:(user!=null) };

    this.listRef = React.createRef();

    this.toggleOpen = this.toggleOpen.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }

  componentDidMount() {

    document.addEventListener('mousedown', this.handleClickOutside);
    if(this.state.user){

      if (this.state.user.guid) {
        this.getUserPicturePicture(this.state.user.guid + "/S");
      }

    }

  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isOpen) {
        this.toggleOpen();
      }
    }
  }

  getUserPicturePicture(guid) {

    mediaService.getUserPicture(guid).then(response => {
        if (response.data && response.data.error) {

        } else {
            this.setState({userPicture: URL.createObjectURL(response)});
        }

    });
    
  }

  logout(){
    this.setState({showLoader: true});
    authService.logout();
  }

  toggleOpen() {
    const {
      current: { children }
    } = this.listRef;

    this.setState({
      isOpen: !(this.listRef.current && children) ? false : !this.state.isOpen
    });
  }

  render() {
    return this.state.loggedIn ? (
      <div ref={this.setWrapperRef}>
        <ProfileDropDown onClick={this.toggleOpen} ref={this.listRef} id="ProfileDropDown">
          
          <SmallProfileThumbnail>
            {/*this.state.userPicture ? "" : "..."*/}
            {this.state.userPicture ? <ProfileThumbnailImg src={this.state.userPicture}/> : <ProfileThumbnailImg src={ProfilePic}/>}
            {/* <input type="file" name="Logo" placeholder="logo" onChange={this.uploadPhoto}/>*/}
          </SmallProfileThumbnail>
          
          {localStorage.user && JSON.parse(localStorage.user).first_name &&
          <span style={{marginLeft: "7px"}}>{JSON.parse(localStorage.user).first_name}</span>}
          
          <List isOpen={this.state.isOpen} dropDown>

            <ListItem as={Link} to={"/profile"}>
              Profile
            </ListItem>

            <ListItem as={Link} to={"/my-messages"}>
              My Messages
            </ListItem>

            <ListItem as={StyledButton} style={{width:"inherit"}} onClick={()=>this.logout()}>
              Logout
            </ListItem>
          </List>

          <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
            this.setState({showLoader: false});
          }}>
            <p>Loading, please be patient...</p>
          </Loading>

        </ProfileDropDown>
      </div>
    ) : (
      <LoginButton>Login</LoginButton>
    );
  }
}

export default Profile;
