import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const messagesService = {
    sendMessage,
    viewMessage,
    getMessages
};


function sendMessage(message) {
    return ajax.handlePost(`${API_ROOT}/message/send`, message)
}

function viewMessage(messageId,dateString) {
    return ajax.handleGet(`${API_ROOT}/message/content/${messageId}/${dateString}`)
}

function getMessages(search) {
    return ajax.handlePost(`${API_ROOT}/message/search`, {search: search ? search : ""})
}

