import styled, {css} from "styled-components";
import {Link, NavLink} from "react-router-dom";
import Accent from "../images/Accent.svg";
import rem from "../utils/rem";
import { Field } from "redux-form";

export const ROBOTO_THIN = css`
  font-family: "robotothin";
`;

export const ROBOTO_LIGHT = css`
  font-family: "robotolight";
`;

export const ROBOTO_REGULAR = css`
  font-family: "robotoregular";
`;

export const ROBOTO_REGULAR_SMALL = css`
  font-family: "robotoregular";
  font-size: 10pt;
`;

export const ROBOTO_MEDIUM = css`
  font-family: "robotomedium";
`;

export const ROBOTO_BOLD = css`
  font-family: "robotobold";
`;

export const GREEN = "#009b77";

export const LIGHT_GREEN = "#f2faf8";

export const OFF_GREEN = "#78cdba";

export const DARK_GRAY = "#40474A";

export const DARK_GRAY_OPACITY = "#00000098";

export const GRAY = "#a8a8a8";

export const LIGHT_GRAY = "#f2f2f2";

export const SEPERATOR_GRAY = "#CDCDCD";

export const WHITE = "#FFFFFF";

export const SHADOW = `rgba(0, 0, 0, 0.1)`;

export const FLEX_CENTER = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FLEX_BETWEEN = css`
  display: flex;
  justify-content: space-between;
`;

export const COLORS_1 = css`
  background-color: ${GREEN};
  color: ${WHITE};
`;

export const COLORS_1_INVERT = css`
  background-color: ${WHITE};
  color: ${GREEN};
`;

export const COLORS_2 = css`
  background-color: ${WHITE};
  color: ${DARK_GRAY};
`;

export const COLORS_3 = css`
  background-color: ${DARK_GRAY};
  color: ${WHITE};
`;

export const COLORS_4 = css`
  background-color: ${LIGHT_GRAY};
  color: ${DARK_GRAY};
`;

export const COLORS_4S = css`
  background-color: ${LIGHT_GREEN};
  color: ${DARK_GRAY};
`;

export const COLORS_5 = css`
  background-color: ${GRAY};
  color: ${WHITE};
`;

export const COLORS_6 = css`
  background-color: ${LIGHT_GREEN};
  color: ${GREEN};
`;

const TOP_ACCENT = css`
  background-image: url(${Accent});
  background-repeat: no-repeat;
  background-position: top center;
`;

export const NAV_Z_INDEX = 100;

export const BUTTON_HOVER = css`
  opacity: 0.9;
`;

export const StyledLink = styled(Link)`
  user-select: none;
  text-decoration: none;
`;

export const StyledNavLink = styled(NavLink)`
  ${ROBOTO_REGULAR};
  user-select: none;
  text-decoration: none;
`;

export const Heading1 = styled.h1`
  ${ROBOTO_THIN};
  font-size: ${rem(36)};
`;

export const Heading2 = styled.h2`
  ${ROBOTO_THIN};
`;

export const Bar = styled.div`
  background-color: ${WHITE};
  ${FLEX_CENTER};
  align-items: center;
  height: ${rem(72)};
  flex-basis: 1;
  padding: 0 ${rem(20)};
  box-shadow: 0 1px 1px 1px ${SHADOW};
`;

export const LogoBar = styled(Bar)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${NAV_Z_INDEX};
  justify-content: ${props => (props.center ? "center" : "space-between")};
`;

export const Logo = styled(Link)`
  width: 120px;
  display: block;

  > img {
    width: 100%;
  }
`;

export const TitleBar = styled(Bar)`
  ${TOP_ACCENT};
  ${ROBOTO_REGULAR};
  color: #40474a;
  text-transform: uppercase;
  width: 100%;
`;

export const SideBar = styled.div`
  ${ROBOTO_MEDIUM};
  font-size: ${rem(11)};
  letter-spacing: ${rem(1)};
  background-color: ${WHITE};
  text-transform: uppercase;
  box-shadow: 1px 0 8px 1px ${SHADOW};
  position: fixed;
  top: 71px;
  left: ${props => props.isOpen ? '0' : '-250px'};
  z-index: ${NAV_Z_INDEX - 1};
  height: calc(100% + 40px);
  width: ${rem(250)};
  padding: ${rem(72)} ${rem(1)} 0;
  padding-bottom: 80px
  overflow-y: auto;
  transition: left 0.1s linear;

  > ${StyledNavLink} {
    display: block;
    text-decoration: none;
    line-height: ${rem(42)};
    padding: 0 ${rem(20)};
    color: ${DARK_GRAY};

    &.active,
    :hover {
      ${COLORS_4};
    }

    &.active {
      ${ROBOTO_BOLD};
      border-left: solid 3px ${GREEN};
    }
  }
`;

const SQUARE = css`
  content: " ";
  width: ${rem(5)};
  height: ${rem(5)};
  border: solid ${rem(3)} ${GREEN};
`;

export const CIRCLE = css`
  ${SQUARE};
  border-radius: 50%;
`;

export const CHEVRON = css`
  ${SQUARE};
  transform: rotate(45deg);
  border-width: 0 1px 1px 0;
  width: ${rem(7)};
  height: ${rem(7)};
`;

export const CHECKMARK = css`
  content: " ";
  width: 5px;
  height: 11px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: ${GREEN};
  transform: rotate(45deg);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ROUND_CONTAINER = css`
  border-radius: ${rem(4)};
`;

export const CARD = css`
  ${ROUND_CONTAINER};
  ${FLEX_CENTER};
  background-color: ${WHITE};
  flex-direction: column;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "flex-start"};
  align-items: ${props => props.align || "flex-start"};
`;

export const FormRow = styled(Flex)`
  padding: 0 ${rem(40)};
`;

export const FlexRow = styled(FormRow)`
  flex-direction: column;
`;

export const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const ContentRow = styled(Flex)`
  position: relative;
  height: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const TEXT_FIELD = css`
  ${COLORS_2};
  width: 100%;
  padding: ${rem(10)};
  border: solid 1px ${GRAY};
  font-size: ${rem(12)};
  // can use outline none if no selection should be displayd when control gets focus
  // outline: none;
`;

export const Input = styled.input`
  ${TEXT_FIELD};
  border-radius: ${rem(3)};
  font-size: ${rem(15)};
  text-transform: initial;

  ::placeholder {
    color: ${GRAY};
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 2px #009B77;
  }
`;

export const SelectContainer = styled.label`
  position: relative;
  ${FLEX_CENTER};
  width: 100%;
  
  select {
    ${TEXT_FIELD};
    border-width: 0 0 1px;
    padding: 0 ${rem(10)};
    line-height: ${rem(33)};
    appearance: none;
    border-radius: 0;

    &:focus {
      outline: 0;
      box-shadow: 0 0 2px #009B77;
    }

  }

  :after {
    ${CHEVRON};
    position: absolute;
    right: ${rem(5)};
    pointer-events: none;
  }

`;

export const SingleColumn = styled.div`
  ${FLEX_BETWEEN};
  width: 100%;
`;

export const MultiColumn = styled.div`
  ${FLEX_BETWEEN};
  width: 100%;

  > :first-of-type {
    margin-left: 0;
  }

  > :last-of-type {
    margin-right: 0;
  }

  > *,
  > :only-of-type {
    margin: 0 ${rem(5)};
    flex-grow: 1;
  }
`;

export const Center = styled.div`
  margin: 0 auto;
  max-width: ${rem(1024)};
`;

export const Box = styled.div`
  ${CARD};
`;

export const Card = styled.div`
  ${CARD};
  padding: ${rem(40)};
`;

export const Form = styled.form`
  ${CARD};
`;

export const AccessForm = styled(Form)`
  ${TOP_ACCENT};
  justify-content: space-between;
  width: ${rem(900)};
  padding: ${rem(50)} ${rem(40)} ${rem(40)};
`;

export const DashboardForm = styled(Form)`
  display: block;
  padding: 0;
  background-image: none;
  width: ${rem(900)};
  margin-top: -30px;

  ${Input} {
    border-width: 0 0 1px;
    border-radius: 0;
    font-size: ${rem(12)};
  }
`;

export const LegendQuestionair = styled.legend`
  ${ROBOTO_REGULAR};
  color: ${GREEN};
  text-align: center;
  text-transform: capitalize;
  padding: ${rem(10)};
  width: 100%;
`;

export const Legend = styled.legend`
  ${ROBOTO_REGULAR};
  background-color: ${LIGHT_GRAY};
  border-width: 1px 0;
  border-color: #cdcdcd;
  border-style: solid;
  color: ${GREEN};
  text-align: center;
  text-transform: capitalize;
  padding: ${rem(10)};
  width: 100%;
`;

export const LegendInfoSection = styled.legend`
  ${ROBOTO_REGULAR_SMALL};
  background-color: ${LIGHT_GRAY};
  border-width: 0px 0;
  color: ${OFF_GREEN};
  text-align: center;
  padding: ${rem(5)};
  width: 100%;
`;

export const FieldSet = styled.div`
  background: ${WHITE};

  > ${Legend} {
    width: 100%;
    margin: ${rem(20)} 0;
  }

  > ${SingleColumn}, > ${MultiColumn} {
    padding: 0 ${rem(40)};
  }
`;

export const Banner = styled(FieldSet)`
  border: solid 1px #cdcdcd;
  margin-bottom: 40px;
  border-radius: 5px;

  ${Legend} {
    border-top-width: 0;
  }
`;

export const CustomTable = styled.div`
  ${ROUND_CONTAINER};
  background-color: white;
  width: ${rem(900)};
  border-radius: ${rem(4)} ${rem(4)} 0 0;
`;

export const TableItem = styled.div`
  border-width: 0 0 1px;
  ${TEXT_FIELD};
  ${FLEX_BETWEEN};
  align-items: flex-start;
  color: ${GRAY};
  line-height: ${rem(18)};
  border-color: ${LIGHT_GRAY};
  min-width:0
  overflow: hidden;
  text-overflow: ellipsis;
  > blockquote {
    margin: 0;
    width: ${rem(155)};
    height: ${rem(57)};
    overflow: hidden;
  }
`;

export const TableItemActions = styled(TableItem)`
  width: 1140px;
`;

export const TableRow = styled.div`
  display: flex;

  &:hover {
    

    ${TableItem} {
      ${COLORS_4S};
    }
  }

  ${props =>
    props.center &&
    css`
      justify-content: center;
    `};
`;

export const TableHeader = styled.div`
  ${COLORS_1};
  width: 100%;
  padding: ${rem(10)};
  position: relative;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: ${rem(12)};

  :hover {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  ${props =>
    props.onClick &&
    css`
      :after {
        ${CHEVRON};
        border-color: ${WHITE};
        position: absolute;
        right: 20px;
        pointer-events: none;
      }
    `};

  ${props =>
    props.sortedColumn &&
    css`
      :after {
        transform: rotate(225deg);
      }
    `};
`;

export const TableHeaderNested = styled.div`
  ${COLORS_6};
  width: 100%;
  padding: ${rem(5)};
  position: relative;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: ${rem(12)};

  :hover {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  ${props =>
    props.onClick &&
    css`
      :after {
        ${CHEVRON};
        border-color: ${WHITE};
        position: absolute;
        right: 20px;
        pointer-events: none;
      }
    `};

  ${props =>
    props.sortedColumn &&
    css`
      :after {
        transform: rotate(225deg);
      }
    `};
`;

export const TableHeaderAccordion = styled.div`
  ${COLORS_1};
  width: 100%;
  
  padding: ${rem(10)};
  padding-left: 45px;
  padding-right: 20px;
  position: relative;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: ${rem(12)};

  :hover {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  ${props =>
    props.onClick &&
    css`
      :after {
        ${CHEVRON};
        border-color: ${WHITE};
        position: absolute;
        right: 20px;
        pointer-events: none;
      }
    `};

  ${props =>
    props.sortedColumn &&
    css`
      :after {
        transform: rotate(225deg);
      }
    `};
`;

export const TableHeaderAccordionNested = styled.div`
  ${COLORS_6};
  width: 100%;
  
  padding: ${rem(5)};
  padding-left: 45px;
  padding-right: 20px;
  position: relative;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: ${rem(12)};

  :hover {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  ${props =>
    props.onClick &&
    css`
      :after {
        ${CHEVRON};
        border-color: ${WHITE};
        position: absolute;
        right: 20px;
        pointer-events: none;
      }
    `};

  ${props =>
    props.sortedColumn &&
    css`
      :after {
        transform: rotate(225deg);
      }
    `};
`;

export const TableEditHeader = styled.div`
  ${COLORS_1};
  width: 64px;
  padding: ${rem(10)};
  position: relative;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: ${rem(12)};

  :hover {
    opacity: 1;
    transition: opacity 0.1s linear;
  }

  ${props =>
    props.onClick &&
    css`
      :after {
        ${CHEVRON};
        border-color: ${WHITE};
        position: absolute;
        right: 20px;
        pointer-events: none;
      }
    `};

  ${props =>
    props.sortedColumn &&
    css`
      :after {
        transform: rotate(225deg);
      }
    `};
`;

export const EditField = styled.div`
  width: ${rem(48)};
  ${FLEX_BETWEEN};
  align-items: center;

  > a {
    max-height: ${rem(16)};
  }

  > img {
    width: ${rem(16)};
    height: ${rem(16)};
    &:hover{
      cursor:pointer;
    }
  }
`;

export const Buttons = styled.div`
  width: 100%;
  ${FLEX_CENTER};
  align-self: center;
  padding: ${rem(40)} ${rem(20)};
  background-color: ${WHITE};
  border-radius: 0 0 ${rem(4)} ${rem(4)};

  > * {
    min-width: 25%;
  }
`;

export const ButtonsLessPadding = styled.div`
  width: 100%;
  ${FLEX_CENTER};
  align-self: center;
  padding: 5px;
  background-color: ${WHITE};
  border-radius: 0 0 ${rem(4)} ${rem(4)};

  > * {
    min-width: 25%;
  }
`;

export const PaginationButtons = styled.div`
  width: 100%;
  ${FLEX_BETWEEN};
  align-self: center;
  padding: ${rem(40)} ${rem(20)};
  border-radius: 0 0 ${rem(4)} ${rem(4)};

  > * {
    min-width: 25%;
  }
`;

export const PILL_BUTTON = css`
  padding: ${rem(10)} ${rem(20)};
  display: inline-block;
  margin: ${rem(4)} ${rem(2)};
  border-radius: ${rem(20)};
`;

export const BUTTON = css`
  text-decoration: none;
  text-align: center;
  border: 0;
  border-radius: ${rem(20)};
  padding: ${rem(10)};
  white-space: nowrap;
  cursor: pointer;
`;

export const Button = styled.button`
  ${BUTTON};
  ${COLORS_1};
  transition: opacity 0.1s linear;
  &:focus {
      outline: 0;
      box-shadow: 0 0 2px #009B77;
  }
  &:hover {
    ${BUTTON_HOVER}
  }
`;

export const PillButton = styled(Button)`
  ${PILL_BUTTON};

  ${props =>
    props.disabled &&
    css`
      ${COLORS_4};
      cursor: initial;
    `}
`;

export const BTN_1 = css`
  ${COLORS_1};
  ${BUTTON};
  ${PILL_BUTTON};
  text-decoration: none;
  &:focus {
      outline: 0;
      box-shadow: 0 0 2px #009B77;
  }
  ${props =>
    props.disabled &&
    css`
      ${COLORS_4};
      cursor: initial;
    `}
`;

export const PrimaryButton = styled.button`
  ${BTN_1};
  transition: opacity 0.1s linear;

  &:hover {
    ${BUTTON_HOVER}
  }
`;

// TODO: make this just the Secondary and use as={Link} instead
export const PrimaryLink = styled(Link)`
  ${BTN_1};
  user-select: none;
  text-decoration: none;
  transition: opacity 0.1s linear;

  &:hover {
    ${BUTTON_HOVER}
  }
`;

export const BTN_2 = css`
  ${COLORS_6};
  ${BUTTON};
  ${PILL_BUTTON};
  border: solid 1px #bfe6dd;
  margin-right: ${rem(10)};
  transition: opacity 0.1s linear;

  &:hover {
    ${BUTTON_HOVER}
  }
  
  &:focus {
      outline: 0;
      box-shadow: 0 0 2px #009B77;
  }
`;

export const SecondaryButton = styled.button`
  ${BTN_2};
  transition: opacity 0.1s linear;

  &:hover {
    ${BUTTON_HOVER}
  }
  
  &:focus {
        outline: 0;
        box-shadow: 0 0 2px #009B77;
  }
  
`;

// TODO: make this just the Secondary and use as={Link} instead
export const SecondaryLink = styled(Link)`
  user-select: none;
  text-decoration: none;
  ${BTN_2};
  transition: opacity 0.1s linear;

  &:hover {
    ${BUTTON_HOVER}
  }
  
  &:focus {
      outline: 0;
      box-shadow: 0 0 2px #009B77;
  }
`;

export const Search = styled.div`
  ${Input};
  display: flex;
  align-items: center;
  padding-bottom: 40px;

  div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  img {
    border: solid 2px ${GREEN};
    border-radius: 20px;
    padding: 10px;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    transition: opacity 0.1s linear;
  }

  input {
    ${PILL_BUTTON};
    width: 100%;
    border-radius: 3px 30px 30px 3px;
    border: solid 1px #cdcdcd;
    padding: 20px;
  }
`;

export const Footer = styled.footer`
  ${FLEX_CENTER};
  ${ROBOTO_LIGHT};
  ${COLORS_3};
  font-size: ${rem(12)};
  padding: ${rem(20)};
  letter-spacing: ${rem(2)};
  margin-top: ${rem(40)};
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100%);
`;

export const AccessFooter = styled.footer`
  ${FLEX_CENTER};
  ${ROBOTO_LIGHT};
  ${COLORS_3};
  font-size: ${rem(12)};
  padding: ${rem(20)};
  letter-spacing: ${rem(2)};
  margin-top: ${rem(40)};
  position: fixed;
  bottom: 0;
  width:100%;
  height:34px;
`;

export const FormMeta = styled.div`
  ${ROBOTO_REGULAR};
  text-transform: uppercase;
  font-size: ${rem(14)};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${rem(20)} ${rem(10)} 0;
  color: ${DARK_GRAY};
  position: fixed;
  right: 0;
  top: 80px;

  :before {
    ${CIRCLE};
    margin-right: ${rem(15)};
  }
`;

export const EditDate = styled.div`
  ${ROBOTO_LIGHT};
  margin-left: ${rem(5)};
  text-transform: initial;
  margin-bottom: 1px;

  :before {
    content: " / ";
  }
`;

export const Overlay = styled.div`
  ${FLEX_CENTER};
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: ${NAV_Z_INDEX + 10};
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: ${rem(20)};
  border-radius: ${rem(20)};
  min-width: ${rem(600)};
  min-height: ${rem(370)};
  ${FLEX_CENTER};
  justify-content: space-evenly;
  flex-direction: column;
`;

const BIG_CIRCLE = css`
  ${CIRCLE};
  background-color: ${GREEN};
  width: ${rem(56)};
  height: ${rem(56)};
`;

export const CheckIcon = styled.div`
  ${BIG_CIRCLE};
  ${FLEX_CENTER};
  align-items: center;

  :after {
    content: "";
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: white;
    transform: rotate(45deg);
    margin-bottom: 10px;
    width: 16px;
    height: 29px;
  }
`;

export const WarningIcon = styled.div`
  ${BIG_CIRCLE};
  background-color: ${DARK_GRAY};
  position: relative;
  border-color: ${DARK_GRAY};
  :before,
  :after {
    content: " ";
    position: absolute;
    width: 2px;
    height: 30px;
    background: ${WHITE};
    top: 10px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
  }

  :after {
    top: 9px;
    transform: rotate(-45deg) translateX(-50%);
  }
`;

export const InfoIcon = styled.div`
  ${BIG_CIRCLE};
  ${FLEX_CENTER};
  align-items: center;
  background-color: ${DARK_GRAY};
  border-color: ${DARK_GRAY};

  :after {
    content: "i";
    margin-bottom: 10px;
    width: 16px;
    height: 29px;
    color: ${WHITE};
    text-align: center;
    font-size: xx-large;
    ${ROBOTO_BOLD}
  }
`;

export const PictureLogo = styled.div`
  ${CIRCLE};
  ${FLEX_CENTER};
  ${COLORS_1};
  position: relative;
  width: 120px;
  height: 120px;
  font-size: 13px;
  margin-top: 40px;

  input {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;

    :hover {
      cursor: pointer;
    }
  }
`;

export const SmallProfileThumbnail = styled.div`
  ${CIRCLE};
  ${FLEX_CENTER};
  ${COLORS_1};
  position: relative;
  width: 47px;
  height: 44px;
  font-size: 13px;
  margin-right: 0px;

  input {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;

  }
`;

export const LogoImg = styled.img`
  border-radius: 50%
  ${FLEX_CENTER};
  flex-shrink: 0;
  position: relative;
  width: 120px;
  height: 120px;
`;

export const ProfileThumbnailImg = styled.img`
  border-radius: 50%
  ${FLEX_CENTER};
  flex-shrink: 0;
  position: relative;
  width: 47px;
  height: 44px;
`;

export const LogoRow = styled(FormRow)`
  justify-content: center;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
  padding: 5px;
  border-radius: 50%;
  background:white;
  z-index:1;
`;

export const TextArea = styled(Field)`
  width: 100%;
  margin-bottom: 40px;
`;

