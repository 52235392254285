import ajax from '../utils/ajax'
import {API_ROOT} from '../api-config';

export const teamService = {
    getTeams,
    getTeam,
    addTeam,
    updateTeam,
    deleteTeam,
    getDepartmentTeams,
    getOrganisationTeams
}


function getTeams(search) {
    return ajax.handlePost(`${API_ROOT}/team/search`, {search: search ? search : ""})
}

function getTeam(guid) {
    return ajax.handleGet(`${API_ROOT}/team/retrieve/${guid}`)
}

function addTeam(data) {
    return ajax.handlePost(`${API_ROOT}/team/add`, data)
}

function updateTeam(data) {
    return ajax.handlePost(`${API_ROOT}/team/update`, data)
}

function deleteTeam(guid) {
    return ajax.handlePost(`${API_ROOT}/team/delete`, {guid: guid})
}

function getDepartmentTeams(guid) {
    return ajax.handlePost(`${API_ROOT}/team/department/${guid}/search`, {search: ""})
}
function getOrganisationTeams(guid) {
    return ajax.handlePost(`${API_ROOT}/team/organisation/${guid}/search`, {search: ""})
}