import React, {Component} from "react";
import styled, {css} from "styled-components";
import {
    COLORS_1,
    COLORS_2,
    CHEVRON,
    GREEN,
    WHITE,
    LIGHT_GREEN,
    ROBOTO_REGULAR
} from "./style";
import List from "./List";

const AccordionWrapper = styled.div` 
  ${ROBOTO_REGULAR};
  ${COLORS_2};
  position: relative;
  line-height: 42px;
  border-top: solid 1px ${LIGHT_GREEN};
  user-select: none;

  span {
    padding: 0 20px;
  }

  :after {
    content: " ";
    ${CHEVRON};
    border-color: ${GREEN};
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 15px;
    right: 17px;
  }

  :last-of-type {
    border-bottom: solid 1px ${LIGHT_GREEN};
  }

  ${props =>
    props.isOpen &&
    css`
      ${COLORS_1};

      :after {
        border-color: ${WHITE};
        transform: rotate(-135deg);
        top: 17px;
      }
    `};

  :hover {
    cursor: pointer;
  }
`;

class Accordion extends Component {

    constructor(props) {

        super(props);

    }

    render() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (this.props.roles.includes(user.user_type)) {
            return (
              <AccordionWrapper onClick={this.props.toggleOpen} isOpen={this.props.isOpen}>
                  <span>{this.props.label}</span>
                  <List isOpen={this.props.isOpen}>{this.props.children}</List>
              </AccordionWrapper>
            );
        } else {
            return null;
        }

    }
}

export default Accordion;
