import React from "react";
import styled, { css } from "styled-components";
import { SelectContainer } from "./style";

const LabelContainer = styled(SelectContainer)`
  padding: 25px 0;
`;

const Label = styled.span`
  position: absolute;
  top: 35px;
  left: 10px;
  color: gray;
  font-size: 12px;
  opacity: 1;
  transition: all 0.1s linear;
  pointer-events: none;

  ${props =>
    props.focus &&
    css`
      opacity: 1;
      top: 6px;
    `};
`;

const Error = styled.span`
  position: absolute;
  bottom: 6px;
  left: 10px;
  color: red;
  opacity: 0;
  font-size: 12px;
  transition: all 0.1s linear;
  white-space: nowrap;

  ${props => props.error && css`
    opacity: 1;
  `};
`;

const demoOptions = [
  { label: "Active", value: "1" },
  { label: "Inactive", value: "0" }
];

const renderSelect = props => {
  const { input, options, meta, placeholder, disabled, required } = props;
  const opts = options || demoOptions;
  return (
    <LabelContainer>
      {required ?
        <Label focus={input.value||input.value===0}>{((input.value||input.value===0))&&required ? placeholder : placeholder + ' *'}</Label>
        :
        <Label focus={input.value||input.value===0}>{placeholder}</Label>
      }
      <select
        {...input}
        disabled={disabled}
      >
        <option key="-1" value={undefined} />
        {opts.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <Error error={meta.error && meta.dirty}>{meta.error}</Error>
    </LabelContainer>
  );
};

export default renderSelect;
