import ajax from '../utils/ajax'
import {API_ROOT} from '../api-config';

export const reportService = {
    getTrendReport,
    getComparisonReport,
    getStatusReport,
    getIncreaseReport,
    getBonusReport
}

function getTrendReport() {
    return ajax.handlePost(`${API_ROOT}/retrieve/assessment-cycle/trend-report/`, {})
}

function getComparisonReport(cycle_guid) {
    return ajax.handleGet(`${API_ROOT}/reports/retrieve/assessment-cycle/comparison-report/${cycle_guid}`);
}
    
function getStatusReport(cycle_guid) {
    return ajax.handleGet(`${API_ROOT}/reports/retrieve/assessment-cycle/status-report/${cycle_guid}`);
}
        
function getBonusReport() {
    return ajax.handlePost(`${API_ROOT}/reports/test`, {})
}

function getIncreaseReport() {
    return ajax.handlePost(`${API_ROOT}/reports/test`, {})
}
