import React from "react";
import styled, { css } from "styled-components";
import { Input as ImportedInput, GRAY, GREEN } from "./style";

const Input = styled(ImportedInput)`
  ${props =>
    props.focus &&
    props.type === "password" &&
    css`
      color: #009b77;
      font-size: 16px;
      max-height: 40px;
    `}
`;

const LabelContainer = styled.label`
  position: relative;
  width: 100%;
  padding: 25px 0;
`;

const Label = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: gray;
  font-size: 12px;
  opacity: 0;
  transition: all 0.1s linear;

  ${props =>
    props.focus &&
    css`
      opacity: 1;
      top: 6px;
    `};
`;

const Hint = styled.div`
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
  color: ${GRAY};
  transition: opacity 0.1s linear;

  ${props => props.hide && css`
    display: none;
  `}; 
`;

const HintSegment = styled.span`
  color: red;

  ${props => props.valid && css`
    color: ${GREEN};
  `};
`;

const upperK = val => /[A-Z]/.test(val);
const hasNumber = val => /[0-9]/.test(val);
const specialK = val => /[^A-Za-z0-9]/.test(val);

const renderPassword = ({
  input,
  placeholder,
  meta: { touched, dirty, error, warning },
  type = "text",
  required
}) => {
  const { value } = input; 
  const valid1 = value.length > 7 && value.length < 13;
  const valid2 = upperK(value);
  const valid3 = hasNumber(value);
  const valid4 = specialK(value);

  const allVAlid = valid1 && valid2 && valid3 && valid4;

  return (
    <LabelContainer>
      <Label focus={dirty}>{placeholder}</Label>
      <Input 
        {...input} 
        placeholder={required ? placeholder + ' *' : placeholder}
        type={type} 
        focus={dirty} 
      />
      <Hint hide={allVAlid}>Must contain <HintSegment valid={valid1}>8 - 12 characters</HintSegment>, <HintSegment valid={valid2}>1 upper case letter</HintSegment>, <HintSegment valid={valid3}>1 number</HintSegment> &amp; <HintSegment valid={valid4}>special character(s)</HintSegment>.</Hint>
    </LabelContainer>
  );
};

export default renderPassword;
