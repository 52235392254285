import React, { Fragment } from 'react';
import { ROBOTO_REGULAR } from "../../components/style";
import styled from "styled-components";

const Label = styled.label`
  ${ROBOTO_REGULAR};
  margin-left: 25px;

  :hover {
    cursor: pointer;
  }

  > input {
    margin-right: 5px;
  }
`;

const RatingScale = props => { 
  const { input, options, name, key, meta, rdoGroup } = props;
  const isRequired = meta.touched && meta.error;
  return (
    <Fragment>
      {options.map((a, i) => {
        return (
          <Label key={`${name}-${key+1}-${i}`}>
            <input
              type="radio"
              {...input}
              value={a.value}
              name={rdoGroup}
            />
            {a.title}
          </Label>
        )
      })}
      {isRequired && <span>{meta.error}</span>}
    </Fragment>
  )
};

export default RatingScale;