import React from "react";
import styled, {css} from "styled-components";
import {Input as ImportedInput} from "./style";

const Input = styled(ImportedInput)`
  ${props =>
    props.focus &&
    css`
      color: #009b77;
      font-size: 16px;
      max-height: 40px;
    `}
`;

const LabelContainer = styled.label`
  position: relative;
  width: 100%;
  padding: 25px 0; 
`;

const Label = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: gray;
  font-size: 12px;
  opacity: 0;
  transition: all 0.1s linear;
  ${props =>
    props.focus &&
    css`
      opacity: 1;
      top: 6px;
    `};
`;

const Error = styled.span`
  position: absolute;
  bottom: 6px;
  left: 10px;
  color: red;
  opacity: 0;
  font-size: 12px;
  transition: all 0.1s linear;
  white-space: nowrap;

  ${props => props.error && css`
    opacity: 1;
  `};
`

const renderField = ({input, placeholder, meta, type = "text", disabled, required}) => {
    return (
        <LabelContainer>
            <Label focus={input.value}>{placeholder}</Label>
            <Input
                {...input}
                value={input.value ? input.value : ''}
                placeholder={required ? placeholder + ' *' : placeholder}
                type={type}
                focus={meta.dirty}
                disabled={disabled}
            />
            <Error error={meta.error && meta.dirty}>{meta.error}</Error>
        </LabelContainer>
    );
};

export default renderField;
