import React, {Component} from 'react';
import {Field, initialize, reduxForm} from 'redux-form';
import {Layout} from '../../components';
import {
    Buttons,
    DashboardForm,
    EditDate,
    FieldSet,
    FormMeta,
    Legend,
    MultiColumn,
    PillButton,
    SecondaryLink,
} from '../../components/style';
import {required, email} from '../../utils/validation';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {departmentService, employeeService, teamService} from "../../services";
import moment from "moment";
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from '../../components/modals/PromptModal';
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

const emailValidate = val => {
    return (!email(val)) ? undefined : "Not a valid email.";
}

class AddEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: {},
            departments: [],
            employees: [],
            selectedManager: {},
            currentUser: JSON.parse(localStorage.getItem('user')), 
            showloader: false,
            manager_fields_disabled: true
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);


    }

    handleConfirmation() {
        window.location = "/teams";
    }

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.loadTeam(this.props.match.params.id);
        } else {
            if (this.state.currentUser.user_type !== 'SYSADMIN') {
                this.getDepartments(this.state.currentUser.fk_organisation);
                this.getEmployees(this.state.currentUser.fk_organisation);
            } else {
                this.getDepartments();
            }
        }
    }

    loadTeam(guid) {
        this.setState({showloader: true});
        teamService.getTeam(guid).then(result => {
            this.setState({showloader: false});
            this.setState({team: result.data});
            this.getEmployees(result.data.organisation_guid, result.data.fk_employee_guid);
            this.getDepartments(result.data.organisation_guid);
            this.props.dispatch(initialize(
                'teams-add-edit',
                this.state.team
            ))
        }).catch(error => {
            this.setState({showloader: false});
            this.showModal('modal', error, 'error')
        })
    }

    getDepartments(orgGuid) {

        if (orgGuid) {
            departmentService.getOrganisationDepartments(orgGuid).then(result => {

                this.setState({departments: result.data})
            }).catch(error => {
                this.showModal('modal', error, 'error')
            })
        } else {
            departmentService.getDepartments().then(result => {

                this.setState({departments: result.data})
            }).catch(error => {
                this.showModal('modal', error, 'error')
            })
        }
    }

    getEmployees(orgGuid, managerGuid) {
        employeeService.getOrganisationEmployees(orgGuid).then(result => {
            let employee = {guid: "new", "first_name": "New", "last_name": ""};
            let arr = [];
            arr.push(employee);
            arr = arr.concat(result.data);
            this.setState({employees: arr});
            if (managerGuid) {
                this.setState({selectedManager: result.data.find(emp => emp.guid === managerGuid)})
            }
        })
    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message: message, type: type});
    }

    hideModal(modal) {
        if (this.state.type === 'success') {
            this.setState({[modal]: false, message: null, type: null});
            this.props.history.push('/teams')
        } else {
            this.setState({[modal]: false, message: null, type: null});
        }
    }

    saveTeam(data, id) {
        this.setState({showloader: true});
        const saveData = {
            id: data.id,
            name: data.name,
            email: this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : '',
            guid: data.guid,
            leader_first_name: this.state.selectedManager.first_name,
            phone_number: this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : '',
            leader_last_name: this.state.selectedManager.last_name,
            manager_first_name:  !this.state.manager_fields_disabled ? data.manager_first_name_editable : this.state.selectedManager.first_name,
            manager_last_name: !this.state.manager_fields_disabled ? data.manager_last_name_editable : this.state.selectedManager.last_name,
            manager_email: !this.state.manager_fields_disabled ? data.manager_email_editable : this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : '',
            manager_mobile: !this.state.manager_fields_disabled ? data.manager_mobile_editable : this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : '',
            fk_department_guid: data.fk_department_guid,
            fk_employee_guid: data.fk_employee_guid
        };
        if (id === 'new') {
            // do add
            teamService.addTeam(saveData).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            })
        } else {
            //do update
            teamService.updateTeam(saveData).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            })
        }
    }

    render() {
        return (
            <Layout 
                title={`Team ${this.props.match.params.id === 'new' ? "Add" : "Edit"}`}
                dateCreated={this.state.team.date_created}
                dateModified={this.state.team.date_modified}
                dirty={this.props.dirty}
            >
                <DashboardForm onSubmit={this.props.handleSubmit((e) => this.saveTeam(e, this.props.match.params.id))}
                               onKeyPress={e => {
                                   if (e.key === "Enter") {
                                       e.preventDefault();
                                   }
                               }}>
                    
                    <FieldSet>
                        <Legend>Team information</Legend>
                        <MultiColumn>
                            <Field
                                component={renderSelect}
                                options={this.state.departments.map(dept => {
                                    let label = "";
                                    if (this.state.currentUser.user_type === "SYSADMIN") {
                                        if (dept.organisation_name) label+=dept.organisation_name + " > ";
                                    }
                                    if (dept.branch_name) label+=dept.branch_name + " > ";
                                    if (dept.name) label+=dept.name;
                                    return {label: label, value: dept.guid}
                                })}
                                placeholder="Select Department"
                                name="fk_department_guid"
                                onChange={({target: {value}}) => {
                                    departmentService.getDepartment(value).then(result => {
                                        this.getEmployees(result.data.organisation_guid);
                                    })
                                }}
                                validate={required}
                                required
                            />
                            <Field component={renderField} placeholder="Team Name" name="name" validate={required}
                                   required/>
                            <Field
                                component={renderSelect}
                                options={this.state.employees.map(emp => {
                                    return {label: `${emp.first_name} ${emp.last_name}`, value: emp.guid}
                                })}
                                placeholder="Select Leader"
                                name="fk_employee_guid"
                                onChange={({target: {value}}) => {
                                    if (value.toUpperCase() === "NEW") {
                                        this.setState({
                                            selectedManager: {},
                                            manager_fields_disabled: false
                                        });
                                    } else {
                                        const managerIndex = this.state.employees.findIndex(emp => emp.guid === value);
                                        this.setState({
                                            selectedManager: this.state.employees[managerIndex],
                                            manager_fields_disabled: true
                                        });
                                    }
                                }}
                                validate={required}
                                required
                            />
                        </MultiColumn>
                    </FieldSet>

                    {
                        this.state.manager_fields_disabled
                        ?
                        <FieldSet>
                            <Legend>Team Leader</Legend>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Manager Name" name="manager_first_name" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.first_name : ""}}
                                    required/>
                                <Field component={renderField} placeholder="Manager Surname" name="manager_last_name" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.last_name : ""}}
                                    required/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Email" name="manager_email" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : "" : ""}}
                                    required/>
                                <Field component={renderField} placeholder="Telephone" name="manager_mobile" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : "" : ""}}/>
                            </MultiColumn>
                        </FieldSet>
                        :
                        <FieldSet>
                            <Legend>Team Leader</Legend>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Manager Name" name="manager_first_name_editable" 
                                    validate={[required]} required/>
                                <Field component={renderField} placeholder="Manager Surname" name="manager_last_name_editable"
                                    validate={[required]} required/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Email" name="manager_email_editable" 
                                    validate={[required, emailValidate]} required/>
                                <Field component={renderField} placeholder="Telephone" name="manager_mobile_editable"/>
                            </MultiColumn>
                        </FieldSet>
                    }
                    
                    <Buttons>
                        <SecondaryLink to="/teams" onClick={this.props.dirty? (e) => 
                            {
                                e.preventDefault();
                                this.showModal('promptModal', 'You have made changes that you will be losing if you do not save! Do you still want to cancel?');
                            }
                        :undefined}>Cancel</SecondaryLink>
                        <PillButton disabled={!this.props.valid} type="submit">Save</PillButton>
                    </Buttons>
                </DashboardForm>

                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleConfirmation}  message={this.state.message}/>}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                 type={this.state.type}/>}
                       
                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </Layout>
        );
    }
}

export default reduxForm({
    form: 'teams-add-edit',
    destroyOnUnmount: true
})(AddEdit);