import React from "react";
import {AccessFooter, Footer} from "./style";
import {statusService} from '../services'
import TypedModal from "./modals/TypedModal";
import styled from 'styled-components';

const StyledLink = styled.a`
font-family: "robotolight";
color: #FFFFFF;
text-decoration: none;
`;


class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }   

    showModal(modal, message, type, meta) {
        this.setState({[modal]: true, message: message, type: type, meta: meta});
    }

    hideModal(modal) {
        this.setState({[modal]: false, message: null, type: null, meta: null});
    }

    getStatus() {
        statusService.getStatus().then(result => {
            let message = `Web App Version: ${process.env.REACT_APP_VERSION}, Web App Environment: ${process.env.NODE_ENV}, ${result}`;
            this.showModal('modal', "Service is online.", 'info', message);
        }).catch(error => {
            this.showModal('modal', error, 'error');
        })
    }

    render() {
        if(this.props.isRoot){
            return (
                <AccessFooter>
                    <small>&copy; Copyright Assessme Learning (Pty) Ltd. 2021. All rights reserved. | <StyledLink href="#"
                                                                                                onClick={() => this.getStatus()}>Status</StyledLink></small>
                    {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                     type={this.state.type} meta={this.state.meta}/>}
                </AccessFooter>

            );
        }else{
            return (
                <Footer>
                    <small>&copy; Copyright Assessme Learning (Pty) Ltd. 2021. All rights reserved. | <StyledLink href="#"
                                                                                                onClick={() => this.getStatus()}>Status</StyledLink></small>
                    {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                     type={this.state.type} meta={this.state.meta}/>}
                </Footer>

            );
        }

    }

}

export default FooterComponent;
