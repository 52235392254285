import styled from "styled-components";
import {Center, Logo, LogoBar} from "./style";
import logo from "../images/logo.svg";
import React from "react";

const Constrict = styled.div`
  width: 900px;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const NotFoundLayout = props => {
    return (
        <Container>
            <LogoBar center>
                <Constrict>
                    <Logo to={"/"}>
                        <img src={logo} alt="AssessMe" />
                    </Logo>
                </Constrict>
            </LogoBar>
            <Center>{props.children}</Center>
        </Container>
    );
};

export default NotFoundLayout;