import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BUTTON, PILL_BUTTON, COLORS_1, Search } from "../components/style";
import Finder from "../images/Finder.svg";

const AddButton = styled(Link)`
  ${COLORS_1};
  ${BUTTON};
  ${PILL_BUTTON};
  max-height: 40px;
  transition: opacity 0.1s linear;

  &:hover {
    opacity: 0.9;
  }
`;

const SearchImg = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
    &:focus {
        outline: 0;
        box-shadow: 0 0 2px #009B77;
    }
`;

export const PaddedDivContainer = styled.div`
  margin-right: 25px;
`;

const SearchComponent = ({ match, handleSearch, noAdd, btnText = "Add", apiSearch }) => (
  <Search>
    <PaddedDivContainer onKeyDown={(event)=>{
        if(event.key==="Enter"){
            apiSearch();
        }
    }}>
      <StyledInput placeholder="Search..." onChange={handleSearch} />
      <SearchImg src={Finder} alt="Search" onClick={apiSearch} />
    </PaddedDivContainer>
    {!noAdd && (
      <AddButton to={`${match.path}/add-edit/new`}>{btnText}</AddButton>
    )}
  </Search>
);

export default SearchComponent;
