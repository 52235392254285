export default [
  {
    column1: "organisation 1",
    column2: "Team 1",
    column3: "John",
    column4: "100000",
    column5: "5",
    column6: "5000",
    column7: "105000",
  },
  {
    column1: "organisation 2",
    column2: "JTeam 1",
    column3: "John",
    column4: "100000",
    column5: "5",
    column6: "5000",
    column7: "105000",
  },
  {
    column1: "organisation 3",
    column2: "Team 1",
    column3: "John",
    column4: "100000",
    column5: "5",
    column6: "5000",
    column7: "105000",
  },
  {
    column1: "organisation 4",
    column2: "FriTeam 1",
    column3: "John",
    column4: "100000",
    column5: "5",
    column6: "5000",
    column7: "105000",
  },
  {
    column1: "organisation 5",
    column2: "Team 1",
    column3: "John",
    column4: "100000",
    column5: "5",
    column6: "5000",
    column7: "105000",
  },
  {
    column1: "organisation 55",
    column2: "Team 1",
    column3: "John",
    column4: "100000",
    column5: "5",
    column6: "5000",
    column7: "105000",
  }
];
