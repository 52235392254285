import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import {
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  Flex,
  Box as ImportedBox
} from '../components/style';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import Form from './trend-report/Form';
import * as strings from "../utils/appstring";
import {reportService} from "../services";

const Box = styled(ImportedBox)`
  form {
    margin: 0;
  }
  
  legend {
    margin-top: 0!important;
  }
`;

class TrendReport extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      successModal: false,
      promptIndex: '',
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  showModal(modal) {
    this.setState({ [modal]: true });
  }

  hideModal(modal) {
    this.setState({ [modal]: false });
  }

  handleSubmit() {
    console.log('woop');
  }

  getReport(){
    this.setState({showloader: true});
    reportService.getTrendReport().then(result =>{
      this.setState({showloader: false});
      let url = URL.createObjectURL(result);
      window.open(url, "_blank");
    })
  }

  promptDelete(index) {
    this.showModal('promptModal');
    this.setState({ promptIndex: index });
  }

  render() {
    return (
      <Layout title="Review Assessment Trend Report">
        <div>
          <Flex justify="center">
            <Heading2 as={'h4'}>Review Assessment Trend Report</Heading2>
          </Flex>
          
          <Box>
            <Form handleSubmit={this.handleSubmit} />
            <Buttons>
              <SecondaryLink to="#">
                Cancel
              </SecondaryLink>
              <PillButton 
                type="submit"
                onClick={() => this.getReport()}>
                Generate Report
              </PillButton>
            </Buttons>
          </Box>
        </div>
        {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')} />}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} />}
      </Layout>
    );
  }
};

export default TrendReport;