import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
//import styled from 'styled-components';
import styled, {css} from "styled-components";
import DatePicker from "react-datepicker";
import calendar from '../images/Calendar.svg';
import parseDate from '../utils/parseDate';
import moment from "moment";
import './datepicker.css'

const RequiredAstrix = styled.span` 
  color: gray;
  display: inline-flex;
  align-items: center;
  `;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  
  img {
    position: absolute;
    right: 0;
    bottom: 30px;
  }
`;

const Label = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: gray;
  font-size: 12px;
  opacity: 0;
  transition: all 0.1s linear;
  ${props =>
    props.focus &&
    css`
      opacity: 1;
      top: 6px;
    `};
`;

class renderDatePicker extends Component {

  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.openDatepicker = this.openDatepicker.bind(this);
    this.handleCalendarOpen = this.handleCalendarOpen.bind(this);
  }

  componentDidMount() {
    
    this.props.input.onChange(parseDate(new Date()));

  }

  handleChange (date) {

    console.log("handleChange", date)
    if (date) {
      this.props.input.onChange(parseDate(date));
    } else {
      this.props.input.onChange(null);
    }

  }

  onClickOutside(event) {

    //console.log("onClickOutside", event)

  }

  openDatepicker(event) {

    //console.log("openDatepicker", this.props.input.name, event)

    var object = document.getElementsByName(this.props.input.name);

    if (object) {
      object[0].blur();
    }

    this._calendar.setOpen(true);

  } 

  handleCalendarOpen() {

    //console.log("handleCalendarOpen", this.props.input.name)

    var object = document.getElementsByName(this.props.input.name);

    if (object) {
      object[0].blur();
    }

  } 

  

  render () {

    const { input, meta: {touched, error}, minDate, maxDate, required, placeholder } = this.props;

    return (
      <Fragment>   
        <Wrapper>
          <Label focus={input.value}>{(input.value&&required) ? placeholder : placeholder + ' *'}</Label>
            <DatePicker className="assessMeDatePicker"
              ref={(c) => this._calendar = c} 
              {...input}
              onChange={this.handleChange}
              selected={input.value ? parseDate(input.value) : null}
              placeholderText={required ? placeholder + ' *' : placeholder}
              minDate={minDate?minDate:null}
              maxDate={maxDate?maxDate:null}
              dateFormat="dd-MM-yyyy"
              autoComplete="off"
              defaultValue={null}
              dropdownMode="select"
              onClickOutside={this.onClickOutside}
              onCalendarOpen={this.handleCalendarOpen}
              />
              <img src={calendar} alt="calendar icon" onClick={this.openDatepicker} style={{marginRight: '5px'}}/>
        </Wrapper>
        {touched && error && <RequiredAstrix style={{color: 'red'}}>*</RequiredAstrix>}
      </Fragment>
    )
  }
};

renderDatePicker.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.bool,
  }),
};

export default renderDatePicker;