import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { 
  Buttons,
  SecondaryLink,
  PillButton
} from '../../../components/style';
import Modal from '../../../components/Modal';
import Question from './renderQuestions/Question';
import FormValues from './FormValues';
import close from '../../../images/Close.svg';
import { QuestionTally, MainButtons } from './renderQuestions/styles';
import {lookupService} from "../../../services";
import {withRouter} from 'react-router-dom'
import TypedModal from "../../../components/modals/TypedModal";

const PreviewContainer = styled.div`
  width: 580px;
  max-height: 500px;
  overflow-y: auto;
  background: white;
  padding: 40px 20px 20px;
  border-radius: 10px;
  position: relative;
`;

const Close = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;

  :hover {
    cursor: pointer;
  }
`;

const PreviewModal = ({ hideModal }) => (
  <Modal hideModal={() => hideModal('previewModal')}>
    <PreviewContainer>
      <Close src={close} onClick={() => hideModal('previewModal')} />
      <FormValues />
    </PreviewContainer>
  </Modal>
);

class renderQuestions extends Component {

  constructor(props) {

    super(props);

    this.state = {
      previewModal: false,
      questionTypes:[],
      questionCategories:[]
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    this.getLookups();

  }

  getLookups() {
    lookupService.getQuestionTypes().then(types => {
      let qtypes = [];
      types.data.map(({name, guid}) => {
        return qtypes.push({label: name, value: guid});
      });
      this.setState({questionTypes: qtypes})
    }).catch(error => {
      this.showModal('modal', error, 'error')
    });
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    if(this.state.type==='success'){
      this.setState({[modal]: false, message:null, type:null});
    }else{
      this.setState({[modal]: false, message:null, type:null});
    }
  }

  render() {
    const  { fields } = this.props;
    return (
      <Fragment>
        {fields.map((question, index) => (
          <Question 
              question={`${question}`} 
              index={index} 
              questionFields={fields} 
              key={question} 
              questionTypes={this.state.questionTypes} 
              questionCategories={this.props.questionCategories} 
              onCreateCategory={this.props.onCreateCategory} 
              updateQuestionCount={this.props.updateQuestionCount} 
              unChanged={this.props.unChanged}
          />
        ))}

        <Buttons>

          <PillButton type={"button"} onClick={() => {
                if (this.props.unChanged()) {
                  window.open(`/external/assessment?assessment_guid=${this.props.match.params.id}`, `_blank`);
                } else {
                  this.showModal('modal', "Please save your work first!", 'info')
                }
              }
            }
            disabled={!this.props.unChanged()}
            style={{marginRight: '0.625rem'}}
            >
            View Assessment
          </PillButton>

          <PillButton type={"button"} onClick={() => {
                fields.push({});
                this.props.updateQuestionCount(1);
              }
            }
            disabled={!this.props.canAdd}
            >
            Add Question
          </PillButton>
          
        </Buttons>

        <QuestionTally>
          <div>
            <span>Question Total</span> <span>{fields.length}</span>
          </div>
          {/* <div>
            <span>Question Total percentage</span><span>100%</span>
          </div> */}
        </QuestionTally>

        {this.state.previewModal && <PreviewModal hideModal={() => this.hideModal('previewModal')} />}
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
        
      </Fragment>
    )
  }
};

export default withRouter(renderQuestions);