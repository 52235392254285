import React, { Component } from 'react';
import {EditField, Layout, Search, NoResults } from '../components';
import {
  CustomTable,
  PaginationButtons,
  PrimaryButton,
  TableHeader,
  TableItem,
  TableRow,
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  Flex,
  FormRow as PaddedFlex,
  DARK_GRAY_OPACITY, GREEN
} from "../components/style";
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import TypedModal from '../components/modals/TypedModal';
import PromptModal from '../components/modals/PromptModal';
import {assessmentService} from "../services";
import Loading from "../components/loader/Loading";

class KnowledgeSkills extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      successModal: false,
      items: [],
      promptIndex: '',
      sortedColumn: '',
      searchTerm: '', 
      pageNumber: 1,
      showLoader:false
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.paginate = this.paginate.bind(this);
    this.handleApiSearch = this.handleApiSearch.bind(this);
    
  }

  componentDidMount() {
    if (JSON.parse(localStorage.user).user_type === 'SYSADMIN') {
      this.getAssessments(null);
    } else {
      this.getAssessments(JSON.parse(localStorage.user).organisation_obj.guid);
    }
  }

  getAssessments(guid) {
    this.setState({showLoader:true});
    if (guid) {
      assessmentService.getKnowledgeSkillsAssessmentsByOrganisation(guid).then(result => {
        this.setState({items:result.data, showLoader:false});
        //console.log(result.data);
    });
    } else {
      assessmentService.getKnowledgeSkillsAssessmentsAll().then(result => {
        this.setState({items:result.data, showLoader:false});
        //console.log(result.data);
    });
    }
    
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    this.setState({ [modal]: false });
  }

  handleSearch({ target: { value } }) {
    //const items = SEARCH_COLLECTION(this.state.items, value);
    this.setState({searchTerm: value});
    if(value===""){
      this.getAssessments();
    }
  }
  handleApiSearch(){
    this.getAssessments(this.state.searchTerm);
  }

  handleDelete() {
    const self = this;
    this.setState({showLoader:true});
    assessmentService.deleteAssessment(this.state.promptIndex).then(result => {
        self.getAssessments();
        this.hideModal('promptModal');
        this.setState({showLoader:false});
      }).catch(error => {
      this.hideModal('promptModal');
      this.showModal('modal', error, 'error')
      this.setState({showLoader:false});
    });
  }

  handleSort(column) {
    const items = SORT_COLUMN(this.state.items, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  promptDelete(index) {
    this.showModal('promptModal');
    this.setState({ promptIndex: index });
  }

  handleNext() {
    this.setState({ pageNumber: this.state.pageNumber+1 });
  }

  handleBack() {
    let currentPage = this.state.pageNumber;
    let setVal=0;
    if(currentPage-1===0){
      setVal=1
    }else{
      setVal=currentPage-1
    }
    this.setState({ pageNumber: setVal });
  }

  paginate (array, page_size, page_number) {
    --page_number; // because pages logically start with 1, but technically with 0
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  render() {
    const { match } = this.props;
    return (
      <Layout title="Store Performance Assessment">
        <div>
          <Flex justify="center">
            <Heading2 as={'h4'}>
              Create and maintain Knowledge and Skill Assessments(360&deg; Assessments)
            </Heading2>
          </Flex>
          <PaddedFlex justify="center">
            <SecondaryLink to={`${match.path}/create-new`}>Create New Assessment</SecondaryLink>
            {/* <SecondaryLink to={`${match.path}/from-store`}>New Assessment from Store</SecondaryLink> */}
          </PaddedFlex>
          
          <Search match={this.props.match} handleSearch={this.handleSearch} apiSearch={this.handleApiSearch} noAdd />
          
          <CustomTable>
            <TableRow>
              <TableHeader onClick={() => this.handleSort('column1')}>
                Name
              </TableHeader>
              <TableHeader onClick={() => this.handleSort('column2')}>
                Description
              </TableHeader>
              {/* <TableHeader>
                version
              </TableHeader> */}
              <TableHeader>
                role
              </TableHeader>
              <TableHeader>
                Questions
              </TableHeader>
            </TableRow>
            {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((assessment, i) =>
              <TableRow key={i}>
                <TableItem>
                  {assessment.name}
                </TableItem>
                <TableItem>
                  {assessment.description}
                </TableItem>
                {/* <TableItem>
                  {assessment.version}
                </TableItem> */}
                <TableItem>
                  {assessment.role}
                </TableItem>
                <TableItem>
                  {assessment.question_total ? assessment.question_total : 0}
                  <EditField
                      match={this.props.match}
                      promptDelete={this.promptDelete}
                      index={assessment.guid}
                  />
                </TableItem>
              </TableRow>
            ) : <NoResults searchTerm={this.state.searchTerm} />}
          </CustomTable>
          <PaginationButtons>
            {this.state.pageNumber>1?<PrimaryButton onClick={this.handleBack}>Back</PrimaryButton>:<div></div>}
            {10*this.state.pageNumber<this.state.items.length?<PrimaryButton  onClick={this.handleNext}>Next</PrimaryButton>:<div></div>}
          </PaginationButtons>

          {/*<Buttons>*/}
          {/*  <SecondaryLink to="#">*/}
          {/*    Cancel*/}
          {/*  </SecondaryLink>*/}
          {/*</Buttons>*/}
        </div>
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} />}
        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>
      </Layout>
    );
  }
};

export default KnowledgeSkills;