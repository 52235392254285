import React, { Fragment, Component } from "react";
import styled, { css } from "styled-components";
import Footer from "./Footer";
import SideBar from "./SideBar";
import Hamburger from "./Hamburger";
import { 
  TitleBar, 
  NAV_Z_INDEX,
  FormMeta,
  EditDate,
} from "./style";
import moment from "moment";
import ScrollToTop from "react-scroll-to-top";

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  position: relative;
  padding: 84px 0 91px;
  min-height: calc(100vh - (163px));

  ${TitleBar} {
    position: fixed;
    top: 73px;
    left: 0;
    width: calc(100%);
    height: 72px;
    z-index: ${NAV_Z_INDEX - 1}
  }
`;

const HamburgerPlaceholder = styled.div`
  position: fixed;
  top: 87px;
  z-index: ${NAV_Z_INDEX};
`;

class Layout extends Component {
  constructor(props) {
    super(props);
    
    this.state = { isOpen: false };
    this.toggleSideBar = this.toggleSideBar.bind(this);
  }

  toggleSideBar() {

    this.setState({ isOpen: !this.state.isOpen });

  }

  render() {
    return (
      <Fragment>
        <Content>
          <HamburgerPlaceholder style={this.state.isOpen?{left:"13px"}:{left:"20px"}}>
              <Hamburger handleClick={this.toggleSideBar} isOpen={this.state.isOpen} />
          </HamburgerPlaceholder>
          <TitleBar>
            {this.props.title}
            {(this.props.dateModified || this.props.dateCreated) && 
              <FormMeta>
                  Last Edit
                  <EditDate style={this.props.dirty ? {fontWeight:'bold'} : {}}>{this.props.dirty ? "NOW" : moment(this.props.dateModified?this.props.dateModified: this.props.dateCreated).format("DD MMMM YYYY")}</EditDate>
              </FormMeta>
            }
          </TitleBar>
          <SideBar isOpen={this.state.isOpen}/>
          <Center>{this.props.children}</Center>
        </Content>
        <Footer />
        <ScrollToTop smooth style={{marginBottom: "25px", marginRight: "-20px"}} />
      </Fragment>
    );
  }
};

export default Layout;
