import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const userService = {
    getUser
};


function getUser(guid) {
    return ajax.handleGet(`${API_ROOT}/user/retrieve/${guid}`)
}

