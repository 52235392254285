import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const employeeService = {
    getEmployees,
    getEmployee,
    addEmployee,
    updateEmployee,
    deleteEmployee,
    createEmployee,
    searchEmployees,
    getOrganisationEmployees
};


function getEmployees(search) {
    return ajax.handlePost(`${API_ROOT}/employee/search`, {search: search ? search : ""})
}
function searchEmployees(data) {
    return ajax.handlePost(`${API_ROOT}/employee/search`, data)
}
function getEmployee(guid) {
    return ajax.handleGet(`${API_ROOT}/employee/retrieve/${guid}`)
}

function addEmployee(data) {
    return ajax.handlePost(`${API_ROOT}/employee/add`, data)
}

function createEmployee(data) {
    return ajax.handlePost(`${API_ROOT}/employee/create`, data)
}

function updateEmployee(data) {
    return ajax.handlePost(`${API_ROOT}/employee/update`, data)
}

function deleteEmployee(guid) {
    return ajax.handlePost(`${API_ROOT}/employee/delete`, {guid:guid})
}

function getOrganisationEmployees(guid, excludeOrganisationHead) {
    let payload = excludeOrganisationHead!==undefined || excludeOrganisationHead===true ? {search : "", meta : { excludeOrganisationHead: true} } : {search : ""};
    return ajax.handlePost(`${API_ROOT}/employee/organisation/${guid}/search`, payload)
}