import React from "react";
import styled, { css } from "styled-components";
import { SHADOW } from "./style";


const ListNested = styled.div`
  ${props =>
    props.dropDown
        ? css`
          display: ${props => (props.open ? "block" : "none")};
          width: 100%;
          position: absolute;
          top: 100%;
          right: 0;
          box-shadow: 0 1px 1px 1px ${SHADOW};
        `
        : css`
          overflow: hidden;
          height: ${props.open ? "auto" : 0};
        `};
`;


const ListComponentNested = props => {
    return(
        <ListNested open={props.open} dropDown={props.dropDown}>
            {props.children}
        </ListNested>
    );
}


export default ListComponentNested;
