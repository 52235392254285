import React, {Component} from 'react';
import {EditField, Layout, NoResults, Search} from '../components';
import {
    CustomTable,
    DARK_GRAY_OPACITY, GREEN,
    PaginationButtons,
    PrimaryButton,
    TableHeader,
    TableItem,
    TableRow,
} from '../components/style';
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import PromptModal from '../components/modals/PromptModal';
//import data from './teams/data';
import {departmentService, teamService} from '../services'
import TypedModal from "../components/modals/TypedModal";
import Loading from "../components/loader/Loading";

class Teams extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(localStorage.getItem("user"));

        if (user.user_type==="SYSADMIN") {
            this.state = {
                modal: false, 
                items: [], 
                promptIndex: '', 
                searchTerm: '',
                pageNumber:1,
                sysadmin: true,
                showLoader:false
            };
        } else {
            this.state = {
                modal: false, 
                items: [], 
                promptIndex: '', 
                searchTerm: '',
                pageNumber:1,
                sysadmin: false,
                showLoader:false
            };
        }

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.promptDelete = this.promptDelete.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.paginate = this.paginate.bind(this);
        this.handleApiSearch = this.handleApiSearch.bind(this);

    }

    componentDidMount() {
        this.getTeams()
    }

    getTeams(value) {
        const self = this;
        this.setState({showLoader:true});
        teamService.getTeams(value).then(teams => {
            departmentService.getDepartments().then(departments =>{
                for(let team of teams.data){
                    for(const department of departments.data){
                        if(team.fk_department_guid === department.guid){
                            team.department_name = department.name;
                        }
                    }
                }
                self.setState({items:teams.data, showLoader:false});
            }).catch(error => {
                this.setState({showLoader:false});
                self.showModal('modal', error, 'error');
            });
        }).catch(error => {
            this.setState({showLoader:false});
            self.showModal('modal', error, 'error');
        });

    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {
        this.setState({[modal]: false, message:null, type:null});
    }

    handleApiSearch(){
        this.getTeams(this.state.searchTerm);
    }

    handleSearch({target: {value}}) {
        //const items = SEARCH_COLLECTION(this.state.items, value);
        this.setState({searchTerm: value});
        if(value===""){
            this.getTeams();
        }

    }

    handleDelete() {
        // const items = this.state.items.filter((_, i) => i !== this.state.promptIndex);
        // this.setState({ items, promptIndex: '' });
        this.hideModal('promptModal')
        this.setState({showLoader:true});
        teamService.deleteTeam(this.state.promptIndex).then(result=>{
            this.setState({showLoader:false});
            this.getTeams();
        }).catch(error => {
            this.setState({showLoader:false});
            this.showModal('modal', error, 'error')
        });
    }

    handleSort(column) {
        const items = SORT_COLUMN(this.state.items, column);
        this.setState({items});
    }

    promptDelete(index) {
        this.showModal('promptModal');
        this.setState({promptIndex: index});
    }

    handleNext() {
        this.setState({ pageNumber: this.state.pageNumber+1 });
    }
    handleBack() {
        let currentPage = this.state.pageNumber;
        let setVal=0;
        if(currentPage-1===0){
            setVal=1
        }else{
            setVal=currentPage-1
        }
        this.setState({ pageNumber: setVal });
    }
    paginate (array, page_size, page_number) {
        --page_number; // because pages logically start with 1, but technically with 0
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    handleRowClick(guid) {
        //please review this
        this.props.history.push(this.props.match.path + "/add-edit/" + guid);
      }

    render() {
        return (
            <Layout title="Team Search">
                <div>
                    <Search match={this.props.match} handleSearch={this.handleSearch} apiSearch={this.handleApiSearch}/>
                    {!this.state.sysadmin
                    ?
                    <CustomTable>
                        <TableRow>
                            <TableHeader onClick={() => this.handleSort('name')}>
                                Team Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('name')}>
                                Department Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('branch_name')}>
                                Branch Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('leader_last_name')}>
                                Team Leader
                            </TableHeader>
                        </TableRow>
                        {(this.state.items.length > 0) ? this.paginate(this.state.items, 10, this.state.pageNumber).map((team, i) =>
                            <TableRow key={i}>
                                <TableItem onClick={() => this.handleRowClick(team.guid)}>
                                    {team.name}
                                </TableItem>
                                <TableItem onClick={() => this.handleRowClick(team.guid)}>
                                    {team.department_name}
                                </TableItem>
                                <TableItem onClick={() => this.handleRowClick(team.guid)}>
                                    {team.branch_name}
                                </TableItem>
                                <TableItem>
                                    {team.leader_first_name + ' ' + team.leader_last_name}
                                    <EditField
                                        match={this.props.match}
                                        promptDelete={this.promptDelete}
                                        index={team.guid}
                                    />
                                </TableItem>
                            </TableRow>
                        ) : <NoResults searchTerm={this.state.searchTerm}/>}
                    </CustomTable>
                    :
                    <CustomTable>
                        <TableRow>
                            <TableHeader onClick={() => this.handleSort('name')}>
                                Team Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('name')}>
                                Department Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('branch_name')}>
                                Branch Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('organisation_name')}>
                                Organisation Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('leader_last_name')}>
                                Team Leader
                            </TableHeader>
                        </TableRow>
                        {(this.state.items.length > 0) ? this.paginate(this.state.items, 10, this.state.pageNumber).map((team, i) =>
                            <TableRow key={i}>
                                <TableItem>
                                    {team.name}
                                </TableItem>
                                <TableItem>
                                    {team.department_name}
                                </TableItem>
                                <TableItem>
                                    {team.branch_name}
                                </TableItem>
                                <TableItem>
                                    {team.organisation_name}
                                </TableItem>
                                <TableItem>
                                    {team.leader_first_name + ' ' + team.leader_last_name}
                                    <EditField
                                        match={this.props.match}
                                        promptDelete={this.promptDelete}
                                        index={team.guid}
                                    />
                                </TableItem>
                            </TableRow>
                        ) : <NoResults searchTerm={this.state.searchTerm}/>}
                    </CustomTable>
                    }

                    <PaginationButtons>
                        {this.state.pageNumber>1?<PrimaryButton onClick={this.handleBack}>Back</PrimaryButton>:<div></div>}
                        {10*this.state.pageNumber<this.state.items.length?<PrimaryButton  onClick={this.handleNext}>Next</PrimaryButton>:<div></div>}
                    </PaginationButtons>

                </div>

                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
                {this.state.promptModal &&
                <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete}/>}
                
                <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
                    this.setState({showLoader: false});
                }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </Layout>

        );
    }
}
;

export default Teams;