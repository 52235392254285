import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
  DashboardForm,
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  PrimaryLink,
  Flex,
  Banner,
  FlexRow,
  Legend,
  CustomTable,
  TableRow,
  TableHeader,
  TableItem,
  TableItemActions,
  CHECKMARK,
  DARK_GRAY_OPACITY,
  GREEN,
  FLEX_CENTER, 
  FieldSet, 
  FLEX_BETWEEN, 
  SecondaryButton,
  ButtonsLessPadding
} from '../components/style';
import renderField from '../components/RenderField';
import renderSelect from '../components/RenderSelect';
import SORT_COLUMN from '../utils/sortCollection';
import * as strings from "../utils/appstring";
import {assessmentCycleService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import Loading from "../components/loader/Loading";
import renderDatePicker from "../components/RenderDatePicker";
import moment from "moment";

const Checkmark = styled.div`
  ${FLEX_CENTER};
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;

  &:after {
    ${CHECKMARK};
  }
`;

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;

  select {
    margin-left: 10px;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
  }

 
`;

const TableLegend = styled(Legend)`
  width: 100%;
  border-width: 1px 1px 0;
`;

class EmployAssessments extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      successModal: false,
      items: [],
      cycles:[],
      promptIndex: '',
      sortedColumn: '',
      refresh: function(){}
    };

    this.handleSort = this.handleSort.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  componentDidMount() {

    this.getAssessmentCycles();

    this.state.refresh = () => {
      this.getAssignees(this.state.selectedScheduleGuid);
    };

    window.removeEventListener('refresh', this.state.refresh);
    window.addEventListener('refresh', this.state.refresh, false);

  }

  getAssignees(guid) {
    this.setState({showLoader:true});
    let obj = {};
    obj.guid = guid;
    assessmentCycleService.getAssignees(obj).then(result => {
      this.setState({items:result.data, showLoader:false})
    }).catch(error => {
      this.setState({showLoader:false});
      this.showModal('modal', error, 'error')
    })
  }

  getAssessmentCycles() {
    this.setState({showLoader: true});
    assessmentCycleService.getAssessmentCyclesOwn().then(result => {
        this.setState({cycles: result.data, showLoader: false});
    })

  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
      this.getAssignees(this.state.selectedScheduleGuid);
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  back(){
    this.props.history.goBack();
  }

  handleSort(column) {
    const items = SORT_COLUMN(this.state.items, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  declineAssessmentReview(link) {

    this.setState({showLoader: true});

    assessmentCycleService.declineAssessmentCycleReview(link).then(result => {
      this.setState({showLoader: false});
      this.showModal('modal', result.message, 'success')
    }).catch(error => {
      this.setState({showLoader:false});
      this.showModal('modal', error, 'error')
    });

  }

  acceptAssessmentReview(link) {
    
    this.setState({showLoader: true});

    assessmentCycleService.acceptAssessmentCycleReview(link).then(result => {
      this.setState({showLoader: false});
      this.showModal('modal', result.message, 'success')
    }).catch(error => {
      this.setState({showLoader:false});
      this.showModal('modal', error, 'error')
    });

  }

  startAssessmentReview(link) {

    this.setState({showLoader: true});

    assessmentCycleService.startAssessmentCycleReview(link).then(result => {
      this.setState({showLoader: false});
    }).catch(error => {
      this.setState({showLoader:false});
      this.showModal('modal', error, 'error')
    });

  }

  render() {
    return (
      <Layout title="Assessment">
        <div>

          <Flex justify="center">
            <Heading2 as={'h4'}>
              Please complete the assessments assigned to you
            </Heading2>
          </Flex>

              <DashboardForm>
                <FieldSet>
                  <Legend>Schedule</Legend>
                  <Wrapper>
                    <label>Schedule</label>
                    <Field
                        component={renderSelect}
                        id="due-date"
                        name="date_end"
                        options={this.state.cycles.map(({name, guid, date_due}) => {
                          return {label: `${name} (${moment(date_due).format("DD/MM/YYYY")})`, value: guid}
                        })}

                        onChange={({target: {value}}) => {
                          this.setState({selectedScheduleGuid:value});
                          this.getAssignees(value);
                        }}

                    />
                  </Wrapper>
                </FieldSet>
              </DashboardForm>

          <CustomTable>
            <TableRow>
              <TableHeader onClick={() => this.handleSort('person_full_name')}>
                Employee Name
              </TableHeader>
              {/* <TableHeader>
                Email
              </TableHeader> */}
              <TableHeader>
                Due Date
              </TableHeader>
              <TableHeader onClick={() => this.handleSort('status')}>
                Status
              </TableHeader>
              <TableHeader>
                Actions
              </TableHeader>
            </TableRow>
            {this.state.items && this.state.items.map((assignee, i) =>
              <TableRow key={i}>
                <TableItem>
                  {assignee.person_full_name}
                </TableItem>
                {/* <TableItem>
                  {assignee.person_email}
                </TableItem> */}
                <TableItem>
                {moment(assignee.date_due).format("DD MMMM YYYY")}
                </TableItem>
                <TableItem>
                  {assignee.status}
                </TableItem>
                {/* <TableItem>
                  {org.column5 && org.column5 ? <Checkmark /> : null}
                </TableItem> */}
                {assignee.status === "INVITED" &&
                <TableItemActions>
                    <SecondaryLink to="#" onClick={this.props.handleSubmit((e) => this.declineAssessmentReview(assignee.decline_link))}>Decline</SecondaryLink>
                    <PrimaryLink to="#" onClick={this.props.handleSubmit((e) => this.acceptAssessmentReview(assignee.accept_link))}>Accept</PrimaryLink>
                </TableItemActions>
                }
                {assignee.status === "ACCEPTED" &&
                <TableItemActions>
                    <SecondaryLink to="#" onClick={this.props.handleSubmit((e) => this.declineAssessmentReview(assignee.decline_link))}>Decline</SecondaryLink>
                    <PrimaryLink to="#" onClick={this.props.handleSubmit((e) => this.startAssessmentReview(assignee.start_link))}>Start</PrimaryLink>
                </TableItemActions>
                }
                {assignee.status === "DECLINED" &&
                <TableItemActions>
                    <PrimaryLink to="#" onClick={this.props.handleSubmit((e) => this.acceptAssessmentReview(assignee.accept_link))}>Accept</PrimaryLink>
                </TableItemActions>
                }
                {assignee.status === "STARTED" &&
                <TableItemActions>
                    <SecondaryLink to="#" onClick={this.props.handleSubmit((e) => this.declineAssessmentReview(assignee.decline_link))}>Decline</SecondaryLink>
                    <PrimaryLink to="#" onClick={this.props.handleSubmit((e) => this.startAssessmentReview(assignee.start_link))}>Start Again</PrimaryLink>
                </TableItemActions>
                }{assignee.status === "COMPLETED" &&
                <TableItemActions>
                    {"–"}
                </TableItemActions>
                }
               </TableRow>
            )}

            <ButtonsLessPadding>

              <SecondaryButton onClick={()=>this.back()}>
                Back
              </SecondaryButton>
              
            </ButtonsLessPadding>
          </CustomTable>
        
        </div>

        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type}/>}

      </Layout>
    );
  }
};

export default reduxForm({
  form: 'emp-assessment'
})(EmployAssessments);