import React, { Fragment } from  'react';
import ReactDOM from 'react-dom';
import { Overlay } from './style';

const modalRoot = document.getElementById('modal-root');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }
  
  render() {
    return ReactDOM.createPortal(
      <Overlay>
        <Fragment>{this.props.children}</Fragment>
      </Overlay>,
      this.el,
    );
  }
}

export default Modal;