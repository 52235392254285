import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo.svg";
import {Container, LogoBar, Center, Logo, PrimaryLink} from "./style";
import Footer from "./Footer";

const Constrict = styled.div`
  width: 900px;
  display: flex;
  justify-content: center;
  position: relative;

  ${PrimaryLink} {
    position: absolute;
    right: 0;
  }
`;

const AccessLayout = props => {
  const { pathname } = props.location;
  const isLoginLocation =
    pathname === "/sign-up" || pathname === "/forgot-password";

  return (
    <Container>
      <LogoBar center>
        <Constrict>
          <Logo to={"/"}>
            <img src={logo} alt="AssessMe" />
          </Logo>
          {isLoginLocation ? (
            <PrimaryLink to={"/"}>Login</PrimaryLink>
          ) : (
            <PrimaryLink to={"sign-up"}>Sign Up</PrimaryLink>
          )}
        </Constrict>
      </LogoBar>
      <Center>{props.children}</Center>
     <Footer isRoot={true}/>
    </Container>
  );
};

export default withRouter(AccessLayout);
