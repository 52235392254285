import ajax from "../utils/ajax";
import {API_ROOT} from "../api-config";

export const assessmentCycleService = {
    getAssessmentCycles,
    getAssessmentCyclesOwn,
    getAssessmentCyclesMyCompleted,
    getAssessmentCycle,
    addAssessmentCycle,
    updateAssessmentCycle,
    deleteAssessmentCycle,
    resendAssessmentCycleInvitations,
    launchAssessmentCycle,
    assessmentStartReminders,
    getAssignees,
    declineAssessmentCycleReview,
    acceptAssessmentCycleReview,
    startAssessmentCycleReview,
    getAssessmentCycleResultSummary,
    getAssessmentCycleResultAllowNote,
    getAssessmentCycleResultRemoveNote,
    completeAssessmentCycle,
    getAssessmentCyclesFeedback,
    getAssessmentCyclesManagersFeedback
}

function getAssessmentCycles(search) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/search`, {search: search?search:""})
}

function getAssessmentCyclesOwn() {
    return ajax.handleGet(`${API_ROOT}/assessment-cycle/search/own`);
}

function getAssessmentCyclesMyCompleted() {
    return ajax.handleGet(`${API_ROOT}/assessment-cycle/search/my-completed`);
}

function getAssessmentCyclesFeedback(cycle_guid, cycle_fk_employee) {
    return ajax.handleGet(`${API_ROOT}/reports/retrieve/assessment-cycle/my-feedback/${cycle_guid}/${cycle_fk_employee}`);
}

function getAssessmentCyclesManagersFeedback(cycle_guid, cycle_fk_employee) {
    return ajax.handleGet(`${API_ROOT}/reports/retrieve/assessment-cycle/manager-feedback/${cycle_guid}/${cycle_fk_employee}`);
}

function getAssessmentCycle(guid) {
    return ajax.handleGet(`${API_ROOT}/assessment-cycle/retrieve/${guid}`)
}

function addAssessmentCycle(data) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/add`, data)
}

function updateAssessmentCycle(data) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/update`, data)
}

function deleteAssessmentCycle(guid) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/delete`, {guid: guid})
}

function resendAssessmentCycleInvitations(data) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/resend-invites`, data)
}

function launchAssessmentCycle(data) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/launch`, data)
}

function assessmentStartReminders(data) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/assessment-start-resend`, data)
}

function getAssignees(data) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/assignees/todo`, data)
}

function declineAssessmentCycleReview(link) {
    return ajax.handleGet(link)
}

function acceptAssessmentCycleReview(link) {
    return ajax.handleGet(link)
}

function startAssessmentCycleReview(link) {
    return ajax.handleGet(link)
}

function getAssessmentCycleResultSummary(assessment_cycle_guid) {
    return ajax.handleGet(`${API_ROOT}/questionare/cycle/results/summary/${assessment_cycle_guid}`)
}

function getAssessmentCycleResultAllowNote(assessment_cycle_guid) {
    return ajax.handleGet(`${API_ROOT}/questionare/cycle/results/allow-note/${assessment_cycle_guid}`)
}

function getAssessmentCycleResultRemoveNote(assessment_cycle_guid) {
    return ajax.handleGet(`${API_ROOT}/questionare/cycle/results/allow-note/delete/${assessment_cycle_guid}`)
}

function completeAssessmentCycle(assessment_cycle) {
    return ajax.handlePost(`${API_ROOT}/assessment-cycle/complete`, assessment_cycle)
}