import styled from "styled-components";
import {
  ROBOTO_REGULAR,
  FormRow as Row,
  FLEX_BETWEEN,
  COLORS_1,
  GRAY,
  DARK_GRAY,
  GREEN,
  FLEX_CENTER,
  MultiColumn as TwoColumn,
  PrimaryButton
} from "../../../../components/style";

export const SubHeading = styled.div`
  ${ROBOTO_REGULAR};
  ${FLEX_CENTER};
  color: ${GREEN};
  position: relative;
  line-height: 36px;
  user-select: none;
  margin-bottom: 10px;
  text-decoration: underline;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #40474a;
  white-space: nowrap;
  font-family: sans-serif;
`;

export const DeleteAnswerFieldBtn = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const QuestionHeading = styled.div`
  ${ROBOTO_REGULAR};
  ${FLEX_CENTER};
  color: ${DARK_GRAY};
  position: relative;
  line-height: 36px;
  user-select: none;
  margin: 0 10px;
  position: relative;

  ${DeleteAnswerFieldBtn} {
    position: absolute;
    right: 7px;
    top: 7px;
    height: 20px;
  }
  
  span {
    text-decoration: underline;
  }
`;

export const FormRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  ${Label} {
    flex-grow: 1;
    width: 25%;
 }

  > div {
    flex-grow: 1;
    width: 75%;
  }

  ${DeleteAnswerFieldBtn} {
    flex-grow: initial;
    width: auto;
    display: inline-flex;
    margin: 0 0 0 10px;
    height: 16px;
  }

  input,
  select {
    font-size: 14px;
    width: 100%;
    font-family: sans-serif;
  }

  input {
    font-size: 14px;
    width: 100%;
    font-family: sans-serif;
    padding: 5px;
  }
`;

export const AddAnswerBtn = styled(PrimaryButton)`
  margin: 0 auto;
  font-size: 12px;
  user-select: none;
  width: 100%
`;

export const MultiColumn = styled(TwoColumn)`
  padding: 20px !important;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0;

  :first-of-type {
    border-right: solid #cdcdcd 1px;
    padding-right: 20px;
  }

  :nth-of-type(2) {
    padding-left: 20px;
  }
`;

export const FlexCenter = styled.div`
  ${FLEX_CENTER};
`;

export const MainButtons = styled(FormRow)`
  justify-content: center;
  padding: 10px;
  background: #FFFFFF;
`;

export const QuestionTally = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 82px;
  margin: 0 auto;
  padding: 10px 0;
  border-style: solid;
  border-width: 0 0 1px;
  border-color: #cdcdcd;
  justify-content: space-evenly;
  color: ${DARK_GRAY};

  > div {
    ${FLEX_BETWEEN};
  }

  span:nth-of-type(2) {
    color: ${GREEN};
    font-weight: bold;
    width: 45px;
  }
`;

export const FieldSet = styled.fieldset`
  ${ROBOTO_REGULAR};
  border: solid 1px ${GRAY};
  margin: 0 10px;
  &:focus {
    outline: 0;
    box-shadow: 0 0 2px #009B77;
  }
`;

export const Legend = styled.legend`
  color: ${GREEN};
  font-family: sans-serif;
  font-size: 14px;
  padding: 0 3px;
  text-transform: capitalize;
  
`;
