import React from 'react';
import styled from 'styled-components';
import { Layout} from '../components';
import { 
  PrimaryLink,
  SecondaryLink,
  Flex, 
  Heading2 as ImportedHeading, 
  Card,
  ROBOTO_LIGHT
} from '../components/style';
import * as strings from "../utils/appstring";

const Heading2 = styled(ImportedHeading)`
  ${ROBOTO_LIGHT};
  max-width: 600px;
  padding: 0 100px;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
`;

const FlexButtons = styled(Flex)`
  width: 50%;
  
  > a {
    width: 100%;
  }
`;

const PersonalAssessment = () => {
  return (
    <Layout title="Personal Assessment Report">
      <Card>
        <Flex justify="center">
          <Heading2 as={'h4'}>
            Please complete your personal assessment that has been issued by current assessment schedule
          </Heading2>
        </Flex>
        <FlexButtons justify="center">
          <SecondaryLink to="#">Cancel</SecondaryLink>
          <PrimaryLink to={'/'}>{strings.ok_Title}</PrimaryLink>
        </FlexButtons>
      </Card>
    </Layout>
  );
};

export default PersonalAssessment;