import { SecondaryButton} from "../components/style";
import React from "react";
import NotFoundLayout from "../components/NotFoundLayout";
import styled from "styled-components";
import {authService} from '../services'

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%
`;
class NotFound extends React.Component{
    constructor(props){
        super(props);
        if(JSON.parse(localStorage.getItem('user'))===null){
            this.props.history.push('/')
        }

    }

    back(){
        this.props.history.goBack();
    }
    
    logout(){
        authService.logout();
    }
    
    render() {
        return (
            <NotFoundLayout>
                <Center>
                    <h2>Page Not Found</h2>
                </Center>
                <Center>
                    <SecondaryButton type="button" onClick={()=>this.back()}>Back</SecondaryButton>
                    <SecondaryButton type="button" onClick={()=>this.logout()}>Logout</SecondaryButton>
                </Center>
            </NotFoundLayout>
        )
    }
}
export default NotFound;
