import React, {Component} from 'react';
import {Layout} from '../../components';
import {Field, initialize, reduxForm} from 'redux-form';
//import Loading from '../../components/loader/Loading'; //https://github.com/shamin/react-fullscreen-loading
import Loading from '../../components/loader/Loading'; //https://github.com/shamin/react-fullscreen-loading
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

import {
    Buttons,
    DashboardForm,
    FormMeta,
    EditDate,
    FieldSet,
    Legend,
    MultiColumn,
    PillButton,
    SecondaryLink
} from '../../components/style';
import {required, email} from '../../utils/validation';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {branchService, departmentService, employeeService, organisationService} from "../../services";
import moment from "moment";
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from '../../components/modals/PromptModal';

const emailValidate = val => {
    return (!email(val)) ? undefined : "Not a valid email.";
}
  
  class AddEdit extends Component{
    constructor(props){
        super(props);
        this.state = { 
            branch: {}, 
            organisations:[], 
            currentUser:JSON.parse(localStorage.getItem("user")), 
            employees:[], 
            selectedManager:{}, 
            showloader: false,
            manager_fields_disabled: true
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.getOrganisations();

    }

    handleConfirmation() {
        window.location = "/branches";
    }

   componentDidMount() {

        if(this.props.match.params.id !== 'new'){
            this.loadBranch(this.props.match.params.id);
        }else{
            if (this.state.currentUser.user_type !== 'SYSADMIN') {
                this.getEmployees(this.state.currentUser.fk_organisation);
            }
        }

    }

    loadBranch(guid){

        this.setState({showloader: true});

        branchService.getBranch(guid).then(result => {
            this.setState({showloader: false});
            this.setState({branch:result.data});
            this.getEmployees(result.data.fk_organisation_guid, result.data.fk_employee_guid);
            this.props.dispatch(initialize(
                'branches-add-edit',
                this.state.branch
            ));
        }).catch(error => {
            this.setState({showloader: false});
            this.showModal('modal', error, 'error')
        });

    }

    getOrganisations(){

        organisationService.getOrganisations().then(result=>{
            let orgs = []
            result.data.map(({name, guid})=>{
                return orgs.push({label:name, value:guid});
            })
            this.setState({organisations:orgs})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

    }

    getEmployees(orgGuid, managerGuid) {
        employeeService.getOrganisationEmployees(orgGuid).then(result => {
            let employee = {guid: "new", "first_name": "New", "last_name": ""};
            let arr = [];
            arr.push(employee);
            arr = arr.concat(result.data);
            this.setState({employees: arr});
            if (managerGuid) {
                this.setState({selectedManager: result.data.find(emp => emp.guid === managerGuid)})
            }
        })
    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {
        if(this.state.type==='success'){
            this.setState({[modal]: false, message:null, type:null});
            this.props.history.push('/branches')
        }else{
            this.setState({[modal]: false, message:null, type:null});
        }
    }
    
    saveBranch(val, id) {
        this.setState({showloader: true});
        const saveData = {
            id: val.id,
            name: val.name,
            email: this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : '',
            guid: val.guid,
            phone_number: this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : '',
            manager_first_name:  !this.state.manager_fields_disabled ? val.manager_first_name_editable : this.state.selectedManager.first_name,
            manager_last_name: !this.state.manager_fields_disabled ? val.manager_last_name_editable : this.state.selectedManager.last_name,
            manager_email: !this.state.manager_fields_disabled ? val.manager_email_editable : this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : '',
            manager_mobile: !this.state.manager_fields_disabled ? val.manager_mobile_editable : this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : '',
            fk_organisation_guid: this.state.currentUser.user_type==='SYSADMIN'? val.fk_organisation_guid : this.state.currentUser.fk_organisation,
            fk_employee_guid: val.fk_employee_guid
        };

        if (id === 'new') {
            // do add
            branchService.addBranch(saveData).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success');
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            });
        } else {
            //do update
            branchService.updateBranch(saveData).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success');
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error');
            });
        }

    }
    render() {
        return (
            <Layout 
                title={`Branch ${this.props.match.params.id==='new'?"Add":"Edit"}`}
                dateCreated={this.state.branch.date_created}
                dateModified={this.state.branch.date_modified}
                dirty={this.props.dirty}
            >

                <DashboardForm onSubmit={this.props.handleSubmit((e) => this.saveBranch(e, this.props.match.params.id))} onKeyPress={e=> {
                    if(e.key==="Enter"){
                        e.preventDefault();
                    }
                }}>

                    <FieldSet>
                        <Legend>Branch information</Legend>
                        <MultiColumn>
                            {JSON.parse(localStorage.getItem('user')).user_type==='SYSADMIN'?
                            <Field 
                                component={renderSelect} 
                                options={this.state.organisations.map(org => {
                                    let label = "";
                                    if (this.state.currentUser.user_type === "SYSADMIN") {
                                        if (org.name) label+=org.name + " > ";
                                    }
                                    return {label: label, value: org.guid}
                                })}
                                options={this.state.organisations} placeholder="Select Organisation"
                                name="fk_organisation_guid" 
                                onChange={({target: {value}}) => {

                                    this.getEmployees(value);

                                }}
                                validate={required}
                                required
                            />:
                            // <Field 
                            //     component={renderField} 
                            //     placeholder="Organisation" 
                            //     disabled={true} 
                            //     name="UNUSED" 
                            //     input={{value:this.state.currentUser.organisation_obj.name}} 
                            // /> rather putting null
                            null
                            }
                            {/*<Field component={renderField} placeholder="ID" name="id"/>*/}
                            <Field component={renderField} placeholder="Branch name" name="name" validate={required} required/>
                            <Field
                                component={renderSelect}
                                options={this.state.employees.map(emp => {
                                    return {label: `${emp.first_name} ${emp.last_name}`, value: emp.guid}
                                })}
                                placeholder="Select Manager"
                                name="fk_employee_guid"
                                onChange={({target: {value}}) => {

                                    if (value.toUpperCase() === "NEW") {
                                        this.setState({
                                            selectedManager: {},
                                            manager_fields_disabled: false
                                        });
                                    } else {
                                        const managerIndex = this.state.employees.findIndex(emp => emp.guid === value);
                                        this.setState({
                                            selectedManager: this.state.employees[managerIndex],
                                            manager_fields_disabled: true
                                        });
                                    }
                                }}
                                validate={required}
                                required
                            />
                        </MultiColumn>
                    </FieldSet>

                    {
                        this.state.manager_fields_disabled
                        ?
                        <FieldSet>
                            <Legend>Branch Manager</Legend>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Manager Name" name="manager_first_name" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.first_name : ""}}
                                    required/>
                                <Field component={renderField} placeholder="Manager Surname" name="manager_last_name"
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.last_name : ""}}
                                    required/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Email" name="manager_email" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.email : "" : ""}}
                                    required/>
                                <Field component={renderField} placeholder="Telephone" name="manager_mobile" 
                                    disabled={this.state.manager_fields_disabled}
                                    input={{value: this.state.selectedManager ? this.state.selectedManager.contact_obj ? this.state.selectedManager.contact_obj.phone_number : "" : ""}}/>
                            </MultiColumn>
                        </FieldSet>
                        :
                        <FieldSet>
                            <Legend>Branch Manager</Legend>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Manager Name" name="manager_first_name_editable" 
                                    validate={required} required/>
                                <Field component={renderField} placeholder="Manager Surname" name="manager_last_name_editable" 
                                    validate={required} required/>
                                </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Email" name="manager_email_editable" 
                                    validate={[required, emailValidate]} required/>
                                <Field component={renderField} placeholder="Telephone" name="manager_mobile_editable"/>
                            </MultiColumn>
                        </FieldSet>
                    }

                    <Buttons>
                        <SecondaryLink to="/branches" onClick={this.props.dirty? (e) => 
                            {
                                e.preventDefault();
                                this.showModal('promptModal', 'You have unsaved changes that will be lost. Are you sure you want to cancel?');
                            }
                        :undefined}>Cancel</SecondaryLink>
                        <PillButton disabled={!this.props.valid} type="submit">Save</PillButton>
                    </Buttons>
                </DashboardForm>
                
                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleConfirmation}  message={this.state.message}/>}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}

                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </Layout>
        );
    }
}

AddEdit = reduxForm({
    form: 'branches-add-edit',
    destroyOnUnmount: true
})(AddEdit);

export default AddEdit