import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const assessmentService = {
    getPerformanceAssessmentsAll,
    getPerformanceAssessmentsByOrganisation, 
    getKnowledgeSkillsAssessmentsAll, 
    getKnowledgeSkillsAssessmentsByOrganisation, 
    getClimateAssessmentsAll,
    getClimateAssessmentsByOrganisation,
    getFullAssessment, 
    getFullAssessmentExternal,
    assessmentOrganisationAdd,
    assessmentOrganisationUpdate,
    deleteAssessment,
    getReviewerAssigneeDetails
}

function getPerformanceAssessmentsAll() {
    return ajax.handlePost(`${API_ROOT}/assessment/performance/search`, {search: ""})
}

function getPerformanceAssessmentsByOrganisation(guid) {
    return ajax.handlePost(`${API_ROOT}/assessment/performance/${guid}/search`, {search: ""})
}

function getKnowledgeSkillsAssessmentsAll() {
    return ajax.handlePost(`${API_ROOT}/assessment/knowledge/search`, {search: ""})
}

function getKnowledgeSkillsAssessmentsByOrganisation(guid) {
    return ajax.handlePost(`${API_ROOT}/assessment/knowledge/${guid}/search`, {search: ""})
}

function getClimateAssessmentsAll() {
    return ajax.handlePost(`${API_ROOT}/assessment/climate/search`, {search: ""})
}

function getClimateAssessmentsByOrganisation(guid) {
    return ajax.handlePost(`${API_ROOT}/assessment/climate/${guid}/search`, {search: ""})
}


function getFullAssessment(guid) {
    return ajax.handleGet(`${API_ROOT}/assessment/retrieve/full/${guid}`)
}

function getFullAssessmentExternal(guid) {
    return ajax.handleGet(`${API_ROOT}/email/link/assessment/retrieve/${guid}`)
}

function assessmentOrganisationAdd(data) {
    return ajax.handlePost(`${API_ROOT}/assessment/add/organisation`, data)
}

function assessmentOrganisationUpdate(data) {
    return ajax.handlePost(`${API_ROOT}/assessment/update`, data)
}

function deleteAssessment(guid) {
    return ajax.handlePost(`${API_ROOT}/assessment/delete`, {guid: guid})
}

function getReviewerAssigneeDetails(reviewer_guid) {
    return ajax.handleGet(`${API_ROOT}/assessment-cycle/reviewer-assignee-details/${reviewer_guid}`)
}