import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import {
  Heading2,
  Buttons, 
  ButtonsLessPadding,
  PillButton,
  SecondaryLink,
  Flex,
  Box as ImportedBox,
  SecondaryButton,
  FieldSet,
  Legend,
  DARK_GRAY_OPACITY,
  GREEN,
  DashboardForm,
  FLEX_BETWEEN,
  EditDate,
  FormMeta, MultiColumn, SingleColumn
} from '../components/style';
import * as strings from "../utils/appstring";
import {assessmentCycleService} from "../services";
import {Field, initialize, reduxForm} from "redux-form";
import renderDatePicker from "../components/RenderDatePicker";
import Loading from "../components/loader/Loading";
import moment from "moment";
import {required} from '../utils/validation';

import ObjectMapper from '../utils/objectMapper'
import PromptModal from '../components/modals/PromptModal';
import TypedModal from "../components/modals/TypedModal";
import formatDate from "../utils/formatDate";
import parseDate from "../utils/parseDate";

const Box = styled(ImportedBox)`
  form {
    margin: 0;
  }
  
  legend {
    margin-top: 0!important;
  }
`;

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;

  label {
    display: flex;
    align-items: center;
    width: 90px;
    font-size: 12px;
    white-space: nowrap;
  }

 
`;

class LaunchSchedule extends Component {

  constructor(props) {

    super(props);

    this.state = { 
      showLoader: false,
      successModal: false,
      promptIndex: '',
      assessmentCycle:{}
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    console.log(this.props.dirty);

  }

  componentDidMount() {
    this.getCycle(this.props.match.params.id);
  }

  getCycle(guid){

    this.setState({showLoader:true});

    assessmentCycleService.getAssessmentCycle(guid).then(result=>{

      this.setState({assessmentCycle:result.data, showLoader:false});
      
      this.props.dispatch(initialize(
          'launch-scheduler',
          this.state.assessmentCycle
      ));

    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
      this.props.history.push('/assessment-cycle/schedule')
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  launch(data, guid) {

    this.setState({showLoader: true});

    ObjectMapper.remapObject(data, false);

    assessmentCycleService.launchAssessmentCycle(data).then(result => {
      this.setState({showLoader: false});
      this.showModal('modal', result.message, 'success')
    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }

  sendAssessmentStartReminders(data, guid) {

    this.setState({showLoader: true});

    ObjectMapper.remapObject(data, false);

    assessmentCycleService.assessmentStartReminders(data).then(result => {
      this.setState({showLoader: false});
      this.showModal('modal', result.message, 'success')
    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }

  promptDelete(index) {
    this.showModal('promptModal');
    this.setState({ promptIndex: index });
  }

  render() {

    return (

      <Layout 
        title="Launch Assessment Schedule"
        dateCreated={this.state.assessmentCycle.date_created}
        dateModified={this.state.assessmentCycle.date_modified}
        dirty={this.props.dirty}
      >
        
        <div>
          <Flex justify="center">
            <Heading2 as={'h4'}>Launch Assessment Schedule and define time frame</Heading2>
          </Flex>

            <DashboardForm>
              
              <FieldSet>
                <Legend>Launch Schedule details</Legend>
                <Wrapper>
                  <label>End date</label>
                  <Field
                      component={renderDatePicker}
                      placeholder="End date for cycle"
                      id="due-date"
                      name="date_end"
                      normalize={parseDate}
                      validate={required}                      
                      required
                  />
                </Wrapper>
              </FieldSet>

              <ButtonsLessPadding>

                <SecondaryLink to={`/assessment-cycle/schedule`} onClick={this.props.dirty? (e) => 
                {
                    e.preventDefault();
                    this.showModal('modal', 'You have made changes that you will be losing if you do not save!', 'info');
                }
                :undefined}>Cancel</SecondaryLink>

                <PillButton onClick={this.props.handleSubmit((e) => this.launch(e, this.props.match.params.id))}>
                  Launch
                </PillButton>

              </ButtonsLessPadding>
              
              <ButtonsLessPadding>
                <PillButton onClick={this.props.handleSubmit((e) => this.sendAssessmentStartReminders(e, this.props.match.params.id))}>
                  Send Start Assessment Notification Again
                </PillButton>
              </ButtonsLessPadding>

            </DashboardForm>

            <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                <p>Loading, please be patient...</p>
            </Loading>

        </div>

        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handlePromptAffirm} message="Are you sure you want to continue?"/>}
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                 type={this.state.type}/>}

      </Layout>

    );

  }

};

export default reduxForm({
  form: 'launch-scheduler'
})(LaunchSchedule);