import Integrators from "../pages/Integrators";
import IntegratorsAddEdit from "../pages/integrators/AddEdit";
import Organisation from "../pages/Organisation";
import OrganisationAddEdit from "../pages/organisation/AddEdit";
import Branches from "../pages/Branches";
import BranchesAddEdit from "../pages/branches/AddEdit";
import Departments from "../pages/Departments";
import DepartmentsAddEdit from "../pages/departments/AddEdit";
import Teams from "../pages/Teams";
import TeamsAddEdit from "../pages/teams/AddEdit";
import Employees from "../pages/Employees";
import EmployeesAddEdit from "../pages/employees/AddEdit";
import AdminUsers from "../pages/AdminUsers";
import AdminUsersAddEdit from "../pages/admin-users/AddEdit";
import ProfileEdit from "../pages/Profile";
import Performance from "../pages/Performance";
import AssessmentAddEdit from "../pages/assessment/AddEdit";
import MessagesAddEdit from "../pages/messages/AddEdit";
import KnowledgeSkills from "../pages/KnowledgeSkills";
//import KnowledgeSkillsAddEdit from "../pages/knowledge-skills/AddEdit";
import Climate from "../pages/Climate";
//import ClimateAddEdit from "../pages/climate/AddEdit";
import ReviewResults from "../pages/ReviewResults";
import ReviewFeedback from "../pages/ReviewFeedback";
import Schedule from "../pages/Schedule";
import ScheduleStartNew from "../pages/schedule/AddEdit";
import ScheduleCompletion from "../pages/ScheduleCompletion";
import ScheduleStatus from "../pages/ScheduleStatus";
import SubmitFeedback from "../pages/SubmitFeedback";
import BonusAllocation from "../pages/BonusAllocation";
import IncreaseAllocation from "../pages/IncreaseAllocation";
import DraftReviewFeedback from "../pages/DraftReviewFeedback";
import PersonalAssessment from "../pages/PersonalAssessment";
import ReviewPersonalFeedback from "../pages/ReviewPersonalFeedback";
import Messages from "../pages/Messages";
import MyMessages from "../pages/MyMessages";
import EmployeeAssessments from "../pages/EmployeeAssessments";
import LaunchSchedule from "../pages/LaunchSchedule";
import TrendReport from "../pages/TrendReport";
import ComparisonReport from "../pages/ComparisonReport";
import Assign from "../pages/Assign";
import OrganisationOrganogram from "../pages/organisation/Organogram";

export const MainRoutes = [
];

export const SubRoutes = [
  {
    path: "/integrators/add-edit/:id",
    label: "Integrators",
    page: IntegratorsAddEdit,
    sidebar: false
  },
  {
    path: "/organisation/add-edit/:id",
    label: "Organisation",
    page: OrganisationAddEdit,
    sidebar: false
  },
  {
    path: "/organisation/organogram/:id",
    label: "Organisation Organogram",
    page: OrganisationOrganogram,
    sidebar: false
  },
  {
    path: "/branches/add-edit/:id",
    label: "Branches",
    page: BranchesAddEdit,
    sidebar: false
  },
  {
    path: "/departments/add-edit/:id",
    label: "Departments",
    page: DepartmentsAddEdit,
    sidebar: false
  },
  {
    path: "/teams/add-edit/:id",
    label: "Teams",
    page: TeamsAddEdit,
    sidebar: false
  },
  {
    path: "/employees/add-edit/:id",
    label: "Employees",
    page: EmployeesAddEdit,
    sidebar: false
  },
  {
    path: "/admin-users/add-edit/:id",
    label: "User Accounts",
    page: AdminUsersAddEdit,
    sidebar: false
  },
  {
    path: "/profile",
    label: "User Accounts",
    page: ProfileEdit,
    sidebar: false
  },
  {
    path: "/assessments/performance/create-new",
    label: "Performance Create New",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/performance/from-store",
    label: "Performance From Store",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/performance/add-edit/:id",
    label: "Performance",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/knowledge-skills/create-new",
    label: "Knowledge & Skills Create New",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/knowledge-skills/from-store",
    label: "Knowledge & Skills from Store",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/knowledge-skills/add-edit/:id",
    label: "Knowledge",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/climate/create-new",
    label: "Climate Create New",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessments/climate/from-store",
    label: "Climate from Store",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/assessment-cycle/schedule/add-edit/:id",
    label: "Schedule",
    page: ScheduleStartNew,
    sidebar: false
  },
  {
    path: "/assessments/climate/add-edit/:id",
    label: "Climate",
    page: AssessmentAddEdit,
    sidebar: false
  },
  {
    path: "/messages/add-edit/:id",
    label: "Message",
    page: MessagesAddEdit,
    sidebar: false
  },
  {
    path: "/my-messages",
    label: "My Message",
    page: MyMessages,
    sidebar: false
  }
];

export const AccordionRoutes = [
  {
    label: "Organisation Setup",
    roles:["SYSADMIN","ORGADMIN", "MANAGER"],
    children: [
      {
        path: "/organisation",
        label: "Organisation",
        page: Organisation,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      { path: "/branches",
        label: "Branches",
        page: Branches,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/departments",
        label: "Departments",
        page: Departments,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/teams",
        label: "Teams",
        page: Teams,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/employees",
        label: "Employees",
        page: Employees,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN", "MANAGER"]
      }
    ]
  },
  {
    label: "AssessMe Setup",
    roles:["SYSADMIN","ORGADMIN"],
    children: [
      {
        path: "/integrators",
        label: "Integrators",
        page: Integrators,
        sidebar: true,
        roles:["SYSADMIN"]
      },
      {
        path: "/admin-users",
        label: "User Accounts",
        page: AdminUsers,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      }
    ]
  },
  {
    label: "Assessments Setup",
    roles:["SYSADMIN","ORGADMIN"],
    children: [
      {
        path: "/assessments/performance",
        label: "Performance",
        page: Performance,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/assessments/knowledge-skills",
        label: "Knowledge & Skills",
        page: KnowledgeSkills,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/assessments/climate",
        label: "Climate",
        page: Climate,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      }
    ]
  },
  {
    label: "Schedules",
    roles:["SYSADMIN","ORGADMIN", "MANAGER"],
    children: [
      {
        path: "/assessment-cycle/schedule",
        label: "Schedule Setup",
        page: Schedule,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/assessment-cycle/assign/:id",
        label: "Assign",
        page: Assign,
        sidebar: false,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/assessment-cycle/launch-schedule/:id",
        label: "Launch Schedule",
        page: LaunchSchedule,
        sidebar: false,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/assessment-cycle/schedule-completion",
        label: "Schedule Completion",
        page: ScheduleCompletion,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN","MANAGER"]
      },
      {
        path: "/cycle-status",
        label: "Assessment Cycle Status",
        page: ScheduleStatus,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      }
    ]
  },
  {
    label: "Performance Management",
    roles:["SYSADMIN","ORGADMIN","MANAGER","USER"],
    children: [
      {
        path: "/performance-management/review-results/:id",
        label: "Review Results",
        page: ReviewResults,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/performance-management/draft-review-feedback/:id",
        label: "Draft Review Feedback",
        page: DraftReviewFeedback,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN","MANAGER"]
      },
      {
        path: "/performance-management/submit-feedback",
        label: "Submit Feedback",
        page: SubmitFeedback,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN","MANAGER"]
      },
      {
        path: "/performance-management/trend-report",
        label: "Trend Report",
        page: TrendReport,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/performance-management/comparison-report",
        label: "Comparison Report",
        page: ComparisonReport,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      // {
      //   path: "/performance-management/personal-assessment",
      //   label: "Personal Assessment",
      //   page: PersonalAssessment,
      //   sidebar: true,
      //   roles:["USER","ORGADMIN"]
      // },
      {
        path: "/performance-management/employee-assessments",
        label: "Assessments",
        page: EmployeeAssessments,
        sidebar: true,
        roles:["SYSADMIN","USER","ORGADMIN","MANAGER"]
      },
      {
        path: "/performance-management/review-personal-feedback",
        label: "Review Personal Feedback",
        page: ReviewPersonalFeedback,
        sidebar: true,
        roles:["SYSADMIN","USER","ORGADMIN","MANAGER"]
      }
    ]
  },
  {
    label: "Financial Management",
    roles:["SYSADMIN","ORGADMIN"],
    children: [
      {
        path: "/financial-management/increase-allocation",
        label: "Increase Allocation",
        page: IncreaseAllocation,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/financial-management/bonus-allocation",
        label: "Bonus Allocation",
        page: BonusAllocation,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/financial-management/review-performance",
        label: "Review Performance",
        page: BonusAllocation,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      },
      {
        path: "/financial-management/override-bonus-performance",
        label: "Override Bonus Performance",
        page: BonusAllocation,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      }
    ]
  },
  {
    label: "Messages",
    roles:["SYSADMIN","ORGADMIN"],
    children: [
      {
        path: "/messages",
        label: "System Messages",
        page: Messages,
        sidebar: true,
        roles:["SYSADMIN","ORGADMIN"]
      }
    ]
  }
];

export default [...MainRoutes, ...SubRoutes];
