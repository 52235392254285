import React, {Component} from 'react';
import styled from 'styled-components';
import {reduxForm} from 'redux-form';
import {AccessLayout} from '../components';
import {authService} from '../services'
import * as queryString from 'query-string'
import {Heading1, Heading2,} from '../components/style';
import LoginForm from './login/Form';
import TypedModal from "../components/modals/TypedModal";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";
import Loading from '../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../components/style";

const Heading = styled(Heading1)`
  margin-top: 40px;
  text-align: center;
`;

const SubHeading = styled(Heading2)`
  margin: 20px 0 40px;
  text-align: center;
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state={showloader:false};
    this.reCaptchaRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  componentDidMount() {

    const parsed = queryString.parse(this.props.location.search);
    if(parsed && parsed.error){
      this.showModal('modal', parsed.error, 'error');
    } else if(parsed && parsed.message){
      this.showModal('modal', parsed.message, 'success');
    } else if(parsed && parsed.info){
      this.showModal('modal', parsed.info, 'info');
    }

    this.setState({load: true})
    loadReCaptcha('6LeGIZsUAAAAAL7LJx-bPtNaFqWMdo7B_e2HPdFr');

    this.checkIfLoggedIn();

  }

  hideModal(modal) {
    this.setState({[modal]: false});
  }

  handleSubmit(user) {
    this.setState({showloader: true});
    authService.login(user.userID, user.password).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error.toString(), 'error')
    })
  }

  checkIfLoggedIn() {

    const token = localStorage.getItem("authToken");

    if (token) {

      authService.loginStatus(token).then(result => {

        this.showModal('modal', result.message, 'success')

        if (result.data.user_type === "SYSADMIN") {
            window.location = `/integrators`
        } else if (result.data.user_type === "ORGADMIN") {
            window.location = `/organisation/add-edit/${result.data.fk_organisation}`
        } else if (result.data.user_type === "MANAGER") {
            window.location = `/employees`
        } else if (result.data.user_type === "USER") {
            window.location = `/profile`
        } else {
            window.location = `/?error=No user type?`
        }

      }).catch(error => {
        console.log(error.message);
      });

    }

  }

  handleCaptcha(value) {
    console.log('Captcha Value', value)
  }
  
  render() {
    return (
      <AccessLayout center>
        <Heading>Welcome</Heading>
        <SubHeading>Log in to your account below</SubHeading>
        <LoginForm login={this.handleSubmit}>
          <ReCaptcha
              sitekey="6LeGIZsUAAAAAL7LJx-bPtNaFqWMdo7B_e2HPdFr"
              action='login'
              verifyCallback={this.handleCaptcha}
          />
        </LoginForm>
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
                
        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

      </AccessLayout>
    );
  }
};

export default reduxForm({
  form: 'login'
})(Login);