import React, {Fragment} from "react";
import {Router, Route, Switch} from "react-router-dom";
import {createBrowserHistory as createHistory} from 'history';
import Login from "./Login";
import PrivateRoute from '../components/PrivateRoute'
import Routes, {AccordionRoutes} from "../components/Routes";
import ExternalAssessment from "../pages/ExternalAssessment";
import SignUp from "../pages/sign-up/Form";
import ForgotPassword from "../pages/ForgotPassword";
import NotFound from "../pages/404";
import {Logo, LogoBar} from "../components/style";
import logo from "../images/logo.svg";
import Profile from "../components/Profile";
import moment from "moment";

const history = createHistory();

const App = () => {
    return (
        <Router history={history}>
            {localStorage.getItem('user') && (moment().diff(moment(Number(localStorage.getItem("lastRequestTime"))), 'seconds') < 10800) ?
                (<LogoBar>
                    <Logo to={"/"}>
                        <img src={logo} alt="AssessMe"/>
                    </Logo>
                    <Profile/>
                </LogoBar>) : (<div></div>)
            }
            <Fragment>
                <Switch>
                    <Route key="/sign-up" path="/sign-up" component={SignUp} exact/>
                    <Route key="/forgot-password" path="/forgot-password" component={ForgotPassword} exact/>

                    {Routes.map(({path, page}) => (
                        <PrivateRoute key={path} path={path} component={page} exact/>
                    ))}
                    {AccordionRoutes &&
                    AccordionRoutes.map(({children}, key) =>
                        children.map(({path, page}) =>  (
                                <PrivateRoute key={`${path}${key}`} path={path} component={page} exact/>
                            ))
                    )}
                    <Route
                        key="external-assessment"
                        path="/external/assessment"
                        component={ExternalAssessment}
                        exact
                    />
                    <Route key="fallback-route" exact path="/" component={Login}/>
                    <Route component={NotFound}/>
                </Switch>
            </Fragment>
        </Router>
    );
};

export default App;
