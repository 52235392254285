export default [
  {
    column1: "1997-04-02",
    column2: "45",
    column3: false
  },
  {
    column1: "2001-08-29",
    column2: "22",
    column3: true
  },
  {
    column1: "2018-12-17",
    column2: "100",
    column3: false
  },
  {
    column1: "1992-05-07",
    column2: "78",
    column3: true
  },
  {
    column1: "1970-11-13",
    column2: "56",
    column3: false
  },
  {
    column1: "1983-07-25",
    column2: "89",
    column3: false
  }
];
