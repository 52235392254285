import ajax from '../utils/ajax'
import {API_ROOT} from '../api-config';

export const statusService = {
    getStatus
}

function getStatus() {
    return ajax.handleGet(`${API_ROOT}`)
}
