import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Field, FieldArray } from 'redux-form';
import renderSelect from './RenderSelect';
import reactSelect from './ReactSelect';
import renderField from './RenderField';
import renderAnswers from './RenderAnswers';
import { selector } from '../../AddEdit'; // formValueSelector
import {
    MultiColumn,
    Column,
    QuestionHeading,
    SubHeading,
    Legend,
    FieldSet,
    DeleteAnswerFieldBtn
} from './styles';
import {DARK_GRAY, SEPERATOR_GRAY} from "../../../../components/style";
import Trash from "../../../../images/Trash.svg";
import {required} from '../../../../utils/validation';
import PromptModal from '../../../../components/modals/PromptModal';
import TypedModal from "../../../../components/modals/TypedModal";

const Container = styled.div`
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  border-bottom: solid 1px ${SEPERATOR_GRAY};
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #cdcdcd;
`;

class Question extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      successModal: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.promptDelete = this.promptDelete.bind(this);
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    this.setState({[modal]: false, message:null, type:null});
  }

  promptDelete(index) {
    this.showModal('promptModal',null, null);
    this.setState({ promptIndex: index });
  }

  handleDelete() {
    //do the delete
    this.props.updateQuestionCount(-1);
    this.props.questionFields.remove(this.props.index);
    this.hideModal('promptModal');
  }

  //TODO NEED TO FIX THIS SOMEHOW Check the code in RenderAnswer.js
  promptDeleteAnswer(index) {
    this.showModal('promptModal',null, null);
    this.setState({ promptIndex: index });
  }

  handleDeleteAnswer() {
    //do the delete
    this.hideModal('promptModal');
  }

  validateAnswers(value) {
    if(!value) {
      return 'Required'
    }
  }
  
  render() {

    return (
        <Container>
          <QuestionHeading>
            <span>Question {this.props.index + 1}</span>
            <DeleteAnswerFieldBtn src={Trash} alt="Delete" onClick={() => {
              this.promptDelete(this.props.index);
            }} />
          </QuestionHeading>
          <MultiColumn>
            <Column>
              <SubHeading>Question</SubHeading>
              <Field component={renderSelect} name={`${this.props.question}.fk_question_type`} label="Type" validate={[required]} props={{placeHolder: 'Please Select'}}>
                {this.props.questionTypes}
              </Field>
              <Field component={reactSelect} name={`${this.props.question}.category`} label="Category" options={this.props.questionCategories} onCreate={this.props.onCreateCategory} />
              <FieldSet>
                <Legend>Type your question here</Legend>
                <Field
                    component={renderField}
                    name={`${this.props.question}.description`}
                    type="text"
                    validate={[required]}
                />
              </FieldSet>
            </Column>

            <Column>
              <SubHeading>
                Answer
              </SubHeading>
              {this.props.questionType ?
                  <FieldArray
                      name={`${this.props.question}.answers`}
                      component={renderAnswers}
                      question={this.props.question}
                      questionType={this.props.questionType}
                      answerOptions={this.props.answerOptions}
                      // TODO answerDelete={this.promptDeleteAnswer}
                      props={{validateAnswers: this.validateAnswers}}
                  /> :
                  <Placeholder>First specify the Question Type</Placeholder>
              }
            </Column>
          </MultiColumn>
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} message="Are you sure you want to delete this question?"/>}
        </Container>
    );
  }

}

export default Question = connect(
  (state, props) => {
    return({
    questionType: function(){
        let questionType = props.questionTypes.find(item=>{
            if(item.value === selector(state, `${props.question}.fk_question_type`)){
                return true;
            }
        });
        if(questionType){
            return questionType.label
        }else{
            return undefined;
        }

    }(),

    answerOptions:selector(state, `${props.question}.answers`)
  })}
)(Question);