import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
  DashboardForm,
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  PrimaryLink,
  Flex,
  Banner,
  FlexRow,
  Legend,
  CustomTable,
  TableRow,
  TableHeader,
  TableItem,
  CHECKMARK,
  DARK_GRAY_OPACITY,
  GREEN,
  FLEX_CENTER, FieldSet, FLEX_BETWEEN, SecondaryButton, ButtonsLessPadding
} from '../components/style';
import renderField from '../components/RenderField';
import SORT_COLUMN from '../utils/sortCollection';
import * as strings from "../utils/appstring";
import {assessmentCycleService, reportService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import Loading from "../components/loader/Loading";
import renderDatePicker from "../components/RenderDatePicker";
import renderSelect from '../components/RenderSelect';
import moment from "moment";

const Checkmark = styled.div`
  ${FLEX_CENTER};
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;

  &:after {
    ${CHECKMARK};
  }
`;

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;
  
  select {
    margin-left: 10px;
  }
  
  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
  }

 
`;

const TableLegend = styled(Legend)`
  width: 100%;
  border-width: 1px 1px 0;
`;

class SubmitFeedback extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = { 
      showloader: false,
      successModal: false,
      send_notification: true,
      selectedSchedule:"",
      items: [],
      cycle: {},
      cycles:[],
      assignees:[],
      promptIndex: '',
      sortedColumn: '',
      currentUser: user
    };

    this.handleSort = this.handleSort.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  componentDidMount() {
    this.getAssessmentCycles();
  }

  getAssessmentCycles() {

    this.setState({showLoader: true});

    assessmentCycleService.getAssessmentCycles("locked").then(result => {
        this.setState({cycles: result.data, showLoader: false});
    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }

  generateReport(guid) {
    
    this.setState({showLoader: true});
    reportService.getComparisonReport(guid).then(result => {

        this.setState({showLoader: false});
        let url = URL.createObjectURL(result);
        window.open(url, "_blank");
  
    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
      this.props.history.push('/assessment-cycle/schedule')
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  handleSort(column) {
    const items = SORT_COLUMN(this.state.items, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  render() {
    return (
      <Layout title="Comparison Report">
        <div>

          <Flex justify="center">
            <Heading2 as={'h4'}>
                Review Assessment Cycle Comparison Report
            </Heading2>
          </Flex>

          <DashboardForm>
            <FieldSet>
              <Legend>Schedule</Legend>
              <Wrapper>
                <label>Schedule</label>
                <Field
                    component={renderSelect}
                    id="due-date"
                    name="date_end"
                    options={this.state.cycles.map(({name, guid, date_due, organisation_name}) => {
                      let label = `${name} (${moment(date_due).format("DD/MM/YYYY")})`;
                      if (this.state.currentUser.user_type === "SYSADMIN") {
                          label= organisation_name + " > " + label;
                      }
                      return {label: label, value: guid}
                    })}

                    onChange={({target: {value}}) => {
                      this.setState({selectedSchedule:value});
                    }}

                />
              </Wrapper>
            </FieldSet>
          </DashboardForm>

          <Legend>Generate Report</Legend>

          <ButtonsLessPadding>

            <SecondaryButton type="button" onClick={()=>this.props.history.goBack()}>Cancel</SecondaryButton>

            <PillButton disabled={this.state.selectedSchedule ===""} onClick={this.props.handleSubmit((e) => this.generateReport(this.state.selectedSchedule))}>
                Download Report
            </PillButton>

          </ButtonsLessPadding>
          
        </div>

        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type}/>}

      </Layout>
    );
  }
};

export default reduxForm({
  form: 'submit-feedback'
})(SubmitFeedback);