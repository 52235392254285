import React from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { RenderField } from '../../components';
import { 
  AccessForm,
  PrimaryButton,
  SecondaryLink,
  Buttons as ImportedButtons,
} from '../../components/style';
import { minLength, email, phoneNumber, required } from '../../utils/validation';

const minLength1 = minLength(1);

const userIDValidate = val => {
  return (!email(val) || !phoneNumber(val) || minLength1(1)) ? undefined : "Not a valid email.";
}

const Buttons = styled(ImportedButtons)`
  border: 0;
  background: transparent;
`;

const LoginForm = styled(AccessForm)`
  height: 350px;
`;

const Form = props => {
  const { handleSubmit, valid, login } = props;

  return (
    <LoginForm onSubmit={handleSubmit(e=>login(e))}>
      <Field
        component={RenderField} 
        placeholder="Email"
        name="userID"
        validate={userIDValidate}
      />
      <Field
        component={RenderField} 
        type="password"
        name="password"
        placeholder="Password"
        validate={[minLength1, required]}
      />
      <Buttons>
        <SecondaryLink to="forgot-password">Forgot Password?</SecondaryLink>
        <PrimaryButton
          to={'integrators'}
          disabled={!valid}>
          Log in
        </PrimaryButton>
      </Buttons>
    </LoginForm>
  );
};

export default reduxForm({
  form: 'login'
})(Form);