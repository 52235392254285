import React, {Component} from 'react';
import {EditField, Layout, Search, NoResults} from '../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
    CustomTable,
    DARK_GRAY_OPACITY, GREEN,
    PaginationButtons,
    PrimaryButton,
    TableHeader,
    TableItem,
    TableRow,
    Flex,
    Heading2,
    PrimaryLink
} from "../components/style";
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import {assessmentCycleService, assessmentService} from "../services";
import moment from "moment";
import Loading from "../components/loader/Loading";
import TypedModal from "../components/modals/TypedModal";
import checkNullOrEmpty from "../utils/checkNull"

class ReviewPersonalFeedback extends Component {

    constructor(props) {
        super(props);

        this.state = {
            successModal: false,
            items: [],
            promptIndex: '',
            sortedColumn: '',
            searchTerm: '',
            pageNumber: 1,
            showloader: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.paginate = this.paginate.bind(this);

    }

    componentDidMount() {
        this.getAssessmentCyclesThatHaveBeenCompletedOnMe();
    }

    getAssessmentCyclesThatHaveBeenCompletedOnMe() {

        this.setState({showloader: true});
        assessmentCycleService.getAssessmentCyclesMyCompleted().then(result => {
            this.setState({items: result.data, showloader: false});
        }).catch(error => {
          this.setState({showloader: false});
          this.showModal('modal', error, 'error')
        });

    }

    getAssessmentFeedback(cycle_guid, cycle_fk_employee) {

        this.setState({showloader: true});
        assessmentCycleService.getAssessmentCyclesFeedback(cycle_guid, cycle_fk_employee).then(result => {

            this.setState({showloader: false});

            try {
                let url = URL.createObjectURL(result);
                window.open(url, "_blank");
            } catch(err) {
                this.showModal('modal', result.message, 'error')
            }      

        }).catch(error => {
          this.setState({showloader: false});
          this.showModal('modal', error, 'error')
        });

    }

    showModal(modal, message, type) {
      this.setState({[modal]: true, message: message, type: type});
    }
  
    hideModal(modal) {
      if (this.state.type === 'success') {
        this.setState({[modal]: false, message: null, type: null});
      } else {
        this.setState({[modal]: false, message: null, type: null});
      }
    }
    
    handleSort(column) {
        const items = SORT_COLUMN(this.state.items, column);
        const alreadySorted = column === this.state.sortedColumn;

        if (alreadySorted) items.reverse();

        this.setState({items, sortedColumn: !alreadySorted ? column : ''});
    }

    handleNext() {
        this.setState({pageNumber: this.state.pageNumber + 1});
    }

    handleBack() {
        let currentPage = this.state.pageNumber;
        let setVal = 0;
        if (currentPage - 1 === 0) {
            setVal = 1
        } else {
            setVal = currentPage - 1
        }
        this.setState({pageNumber: setVal});
    }

    paginate(array, page_size, page_number) {
        --page_number; // because pages logically start with 1, but technically with 0
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    render() {
        return (
            <Layout title="Review Personal Feedback">
                <div>

                    <Flex justify="center">
                      <Heading2 as={'h4'}>Review your feedback from previous assessments</Heading2>
                    </Flex>

                    <CustomTable>

                        <TableRow>

                            <TableHeader onClick={() => this.handleSort('description')}>
                                Schedule Description
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('completion_date')}>
                                Completion Date
                            </TableHeader>
                            <TableHeader >
                                Action
                            </TableHeader>
      
                        </TableRow>
                        {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((cycle, i) =>
                            <TableRow key={i}>
                                <TableItem>
                                    {checkNullOrEmpty(cycle.description)}
                                </TableItem>
                                <TableItem>
                                    {moment(cycle.completion_date).format("DD MMMM YYYY")}
                                </TableItem>
                                <TableItem>
                                  <PrimaryLink to="#" onClick={this.props.handleSubmit((e) => this.getAssessmentFeedback(cycle.guid, cycle.fk_employee))}>Get Feedback Report</PrimaryLink>
                                </TableItem>
                            </TableRow>
                        ) : <NoResults searchTerm={this.state.searchTerm}/>}
                    </CustomTable>
                    <PaginationButtons>
                        {this.state.pageNumber > 1 ? <PrimaryButton onClick={this.handleBack}>Back</PrimaryButton> :
                            <div></div>}
                        {10 * this.state.pageNumber < this.state.items.length ?
                            <PrimaryButton onClick={this.handleNext}>Next</PrimaryButton> : <div></div>}
                    </PaginationButtons>

                </div>
                {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')}/>}
                {this.state.promptModal &&
                <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete}/>}
                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type}/>}

            </Layout>
        );
    }
};

export default reduxForm({
  form: 'review-personal-feedback'
})(ReviewPersonalFeedback);