export const required = value => {
  return (value ? undefined : "Required")
};

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = value =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value)
    ? "Enter a valid email address"
    : undefined;

export const alpha = value =>
  value && /[^a-zA-Z ]/i.test(value)
    ? "Only alphabetical characters"
    : undefined;

export const numeric = value =>
  value && /[^0-9]/i.test(value) ? "Only numerical characters" : undefined;

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Invalid phone number, must be 10 digits"
    : undefined;

export const url = value =>
  value &&
  !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
    value.toLowerCase()
  )
    ? "Only valid URL"
    : undefined;
