import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const integratorService = {
    getIntegrators,
    getIntegrator,
    updateIntegrator,
    addIntegrator,
    deleteIntegrator
}

function getIntegrators(search) {
    return ajax.handlePost(`${API_ROOT}/integrator/search`, {search: search ? search : ""})
}

function getIntegrator(guid) {
    return ajax.handleGet(`${API_ROOT}/integrator/retrieve/${guid}`)
}

function updateIntegrator(data) {
    return ajax.handlePost(`${API_ROOT}/integrator/update`, data)
}

function deleteIntegrator(guid) {
    return ajax.handlePost(`${API_ROOT}/integrator/delete`, {guid: guid})
}

function addIntegrator(data) {
    return ajax.handlePost(`${API_ROOT}/integrator/create`, data)
}
