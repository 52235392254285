import React from "react";
import styled, { css } from "styled-components";
import { SHADOW } from "./style";

const List = styled.div`
  ${props =>
    props.dropDown
      ? css`
          display: ${props => (props.isOpen ? "block" : "none")};
          width: 100%;
          position: absolute;
          top: 100%;
          right: 0;
          box-shadow: 0 1px 8px 1px ${SHADOW};
        `
      : css`
          overflow: hidden;
          height: ${props.isOpen ? "auto" : 0};
        `};
`;

const ListComponent = props => (
  <List isOpen={props.isOpen} dropDown={props.dropDown}>
    {props.children}
  </List>
);



export default ListComponent;
