import React from "react";
import styled from "styled-components";
import { GREEN, GRAY, WHITE, FLEX_CENTER, CHECKMARK } from "./style";

const LabelContainer = styled.label`
  ${FLEX_CENTER};
  user-select: none;

  :hover {
    cursor: pointer;
  }

  :after {
    content: "";
    margin-left: 10px;
  }

  input[type="checkbox"] {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    visibility: hidden;
  }

  span {
    ${FLEX_CENTER};
    border: solid 1px ${GRAY};
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin-right: 10px;
  }

  span:after {
    ${CHECKMARK};
    border-color: ${WHITE};
    margin-bottom: 3px;
    display: none;
  }

  input[type="checkbox"]:checked + span {
    background: ${GREEN};
  }

  input[type="checkbox"]:checked + span:after {
    display: block;
  }

  label {
    user-select: none;
  }
`;

const SpecialCheckBox = (props) => {
    const { input, placeholder, meta, type = "text", changeFunc } = props;
  return (
    <LabelContainer>
      <input {...input} checked={input?(input.value ? true : false):false} onChange={({target:value}) => changeFunc(value)} type="checkbox"/>
      <span />
      {placeholder}
    </LabelContainer>
  );
};

export default SpecialCheckBox;
