import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import {
  Button,
  DARK_GRAY,
  Heading1,
  Heading2,
  WarningIcon,
  CheckIcon,
  InfoIcon,
  ModalContainer,
  ButtonsLessPadding
} from "../style";

const ConfirmBtn = styled(Button)`
  width: 50%;
  border-radius: 20px;
  margin: 5px;
`;

const DismissBtn = styled(ConfirmBtn)`
  background: ${DARK_GRAY};
  color: white;
  margin: 5px;
`;

const PromptModal = ({ hideModal, affirmAction, message, type }) => (

  <Modal hideModal={hideModal}>
    <ModalContainer>

      {type == "success" && <CheckIcon/>}
      {type == "info" && <InfoIcon/>}
      {type == "warning" && <WarningIcon/>}
      {type == "error" && <WarningIcon/>}
      {!type && <WarningIcon/>}

      {type == "success" && <Heading1>Success</Heading1>}
      {type == "info" && <Heading1>Information</Heading1>}
      {type == "warning" && <Heading1>Warning</Heading1>}
      {type == "error" && <Heading1>Error</Heading1>}
      {!type && <Heading1>Warning</Heading1>}

      <Heading2 style={
          {
              maxWidth: "400px",
              textAlign: "center"
          }
      }>
        {message ? message : "Are you sure you want to do this?"}
      </Heading2>

      <ButtonsLessPadding>
        <DismissBtn onClick={hideModal}>No</DismissBtn>
        <ConfirmBtn onClick={affirmAction}>Yes</ConfirmBtn>
      </ButtonsLessPadding>

    </ModalContainer>
  </Modal>
);

export default PromptModal;
