import React, { Component  } from 'react';
import styled from 'styled-components';
import { Layout, Search } from '../components';
import {
  Heading2,
  Buttons,
  PillButton,
  Flex,
  CustomTable,
  FormMeta,
  DashboardForm,
  EditDate,
  TableRow,
  TableHeader as ImportedTableHeader,
  TableItem as ImportedTableItem, 
  SecondaryLink, 
  DARK_GRAY_OPACITY, 
  GREEN, 
  PrimaryLink,
  ButtonsLessPadding
} from '../components/style';
import {Field, initialize, reduxForm} from "redux-form";
import moment from "moment";
import ObjectMapper from "../utils/objectMapper";
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import data from './assign/data';
import Accordion from './assign/AssignAccordion';
import {assessmentCycleService} from "../services";
import Loading from "../components/loader/Loading";
import PromptModal from '../components/modals/PromptModal';
import TypedModal from "../components/modals/TypedModal";

const TableHeader = styled(ImportedTableHeader)`
  &:first-of-type {
    padding-left: 15px;
    padding-right: 50px;
  }
`;

const TableItem = styled(ImportedTableItem)`
  border: 0;
`;

const makeTableRow = ({ person_full_name, employee_no, fk_employee_role, weight, status }, index) => (
  <TableRow key={index}>
    <TableItem>{person_full_name}</TableItem>
    <TableItem>{fk_employee_role}</TableItem>
    <TableItem>{weight < 100 ? 0 : weight}</TableItem>
    <TableItem>{status}</TableItem>
  </TableRow>
);

class Assign extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      successModal: false,
      items: [],
      promptIndex: '',
      sortedColumn: '',
      searchTerm: '',
      assessmentCycle:{},
      showLoader:false
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

  }

  componentDidMount() {
    this.getCycle(this.props.match.params.id);
  }

  getCycle(guid){
    this.setState({showLoader:true});
    assessmentCycleService.getAssessmentCycle(guid).then(result=>{
      this.setState({items:result.data.assignees, showLoader:false});
      this.setState({assessmentCycle:result.data, showLoader:false});
      this.props.dispatch(initialize(
          'launch-scheduler',
          this.state.assessmentCycle
      ))
    }).catch(error => {
      this.setState({showLoader: false});
      console.log(error);
    })
  }

  handleSearch({ target: { value } }) {
    const items = SEARCH_COLLECTION(data, value);
    this.setState({ items, searchTerm: value });
  }

  handleSort(column) {
    const items = SORT_COLUMN(data, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
      this.setState({[modal]: false, message: null, type: null});
  }

  resendInvitations(data, guid) {
    this.setState({showLoader: true});

    let assessment_cycle = {};
    assessment_cycle.guid = guid;

    assessmentCycleService.resendAssessmentCycleInvitations(assessment_cycle).then(result => {
      this.setState({showLoader: false});
      this.showModal('modal', result.message, 'success')
    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }



  render() {
    return (
      <Layout 
        title="Assign Assessment Reviewers Schedule"
        dateCreated={this.state.assessmentCycle.date_created}
        dateModified={this.state.assessmentCycle.date_modified}
        dirty={this.props.dirty}
      >
        
        <div>
          <DashboardForm>

          <Flex justify="center">
            <Heading2 as={'h4'} style={{marginTop:'30px'}}>
              View Assessment reviewers or raters (who will assess who)
            </Heading2>
          </Flex>

          <CustomTable>
            <TableRow>
              <TableHeader>
                Full Name
              </TableHeader>
              <TableHeader>
                Role
              </TableHeader>
              <TableHeader>
                Weight Percentage
              </TableHeader>
              <TableHeader>
                Status
              </TableHeader>
            </TableRow>
            {this.state.items.length > 0 && this.state.items.map((assignee, i) => {
              return (
                <Accordion key={i} label={makeTableRow(assignee, i)}>
                  {assignee.reviewers && assignee.reviewers.map((reviewer, j) => makeTableRow(reviewer, j))}
                </Accordion>
              )
            })}
            <ButtonsLessPadding>
              <TableRow>
                <SecondaryLink to={`/assessment-cycle/schedule/add-edit/${this.props.match.params.id}`}>
                  Back
                </SecondaryLink>
                <PrimaryLink to={`/assessment-cycle/launch-schedule/${this.props.match.params.id}`}>
                  Launch Schedule
                </PrimaryLink>
              </TableRow>
            </ButtonsLessPadding>
            <TableRow center>
              <PillButton onClick={this.props.handleSubmit((e) => this.resendInvitations(e, this.props.match.params.id))}>
                Resend Assessment Invitations
              </PillButton>
            </TableRow>
          </CustomTable>

          </DashboardForm>

        </div>

        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handlePromptAffirm} message="Are you sure you want to continue?"/>}
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                 type={this.state.type}/>}

      </Layout>

    );
  }
};

export default reduxForm({
  form: 'scheduler'
})(Assign);