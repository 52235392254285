import React from "react";
import {FormRow, Label, DeleteAnswerFieldBtn} from "./styles";
import Trash from "../../../../images/Trash.svg";

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error },
  deleteBtn = false,
  deleteBtnOnClick,
  fields,
  index,
  required
}) => (
  <FormRow>
    {label && <Label>{label}</Label>}
    <div>
      <input 
        {...input} type={type} 
        placeholder={required ? label + ' is required!' : label}
        maxLength={500}
      />
      {touched && error && <span style={{color: 'red'}}>{error}</span>}
    </div>

    {/*{deleteBtn && <DeleteFieldBtn type={"button"} onClick={() => fields.remove(index)} />}*/}
    {deleteBtn && <DeleteAnswerFieldBtn src={Trash} alt="Delete" onClick={!deleteBtnOnClick ? () => fields.remove(index) : deleteBtnOnClick}/>}
  </FormRow>
);

export default renderField;
