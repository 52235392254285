import React, {Component} from 'react';
import { Layout} from '../../components';
import {
    Buttons,
    DashboardForm, EditDate, FormMeta, GRAY, Legend, SecondaryLink, LIGHT_GREEN,
} from '../../components/style';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import {organisationService} from "../../services";
import styled from 'styled-components';
import moment from "moment";
import {Link} from "react-router-dom";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";
import TypedModal from "../../components/modals/TypedModal";

const Node = styled.div`
    border: solid 2px #009b77;
    border-radius: 5px;
    display: inline-block;
    padding: 2px
`;

const StyledLink = styled(Link)`
text-decoration: none;
color: #000000;
`;

const CustomListItem = styled.li`
    font-size: 12px;
    text-align: start;
`;

const CustomUnorderedList = styled.ul`
    padding-left: 25px;
    padding-right: 25px;
`;

const Label = styled.span`
font-size: 10px;
  color: ${GRAY};
  white-space: nowrap;
  font-family: sans-serif;
`

const OrganizationStructure = ({node}) => {

    return (
        <div>

            <Node>

                <Label style={{fontSize: '8pt'}}>{node.type}</Label>

                <div>
                    <StyledLink to={getEditLink(node.type, node.guid)}>{node.name}</StyledLink>
                </div>
                
                <StyledLink to={getEditLink('', node.head_guid)} style={{color: GREEN, fontSize: '10pt'}}>{node.head}</StyledLink>

                {node.employees.length > 0 && <CustomUnorderedList>
                {node.employees.map((employee, index)=>{
                    return (
                        <CustomListItem key={index}><StyledLink to={getEditLink('', employee.guid)}>{employee.name}</StyledLink></CustomListItem>
                    )
                })}

                </CustomUnorderedList>}

            </Node>

        </div>


    );
};

const getEditLink = (type, guid) => {
    switch (type) {
        case 'Organisation':
            return `/organisation/add-edit/${guid}`;
        case 'Branch':
            return `/branches/add-edit/${guid}`;
        case 'Department':
            return `/departments/add-edit/${guid}`;
        case 'Team':
            return `/teams/add-edit/${guid}`;
        default:
            return `/employees/add-edit/${guid}`;
    }
};

const UnstyledLegend = styled(Legend)`
  border: 0;
  background: transparent;
  color: ${GRAY};
`;

class OrganisationOrganogram extends Component {

    constructor(props, context) {

        super(props, context);
        this.state = {
            organisation:{},
            showLoader: false,
            organisation_guid: props.match.params.id
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

    }

    componentDidMount() {

        this.loadOrganisationOrganogram(this.state.organisation_guid);

    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {

        if(this.state.type==='success' && JSON.parse(localStorage.getItem('user')).user_type==='SYSADMIN'){
            this.setState({[modal]: false, message:null, type:null});
        }else{
            this.setState({[modal]: false, message:null, type:null});
        }

    }

    loadOrganisationOrganogram(orgGuid){

        this.setState({showLoader: true});
        organisationService.getOrganisationGraphData(orgGuid).then(result=>{

            const organisationData = {
                name: result.data.organisation.name,
                type: 'Organisation',
                guid : result.data.organisation.guid,
                head_guid : result.data.organisation.fk_employee_guid,
                head: result.data.organisation.organisational_head ? `${result.data.organisation.organisational_head.first_name} ${result.data.organisation.organisational_head.last_name}`:'',
                employees: result.data.organisation.employees? result.data.organisation.employees.map(({first_name, last_name, guid})=>{
                    return {
                        name: `${first_name} ${last_name}`,
                        guid: guid
                    }
                }):[],
                children:result.data.organisation.branches ? result.data.organisation.branches.map(({manager_first_name, manager_last_name, name, departments, employees, guid, fk_employee_guid})=>{
                    return {
                        name: name,
                        type: 'Branch',
                        guid : guid,
                        head_guid : fk_employee_guid,
                        head : manager_first_name && manager_last_name ?`${manager_first_name} ${manager_last_name}`:'',
                        employees: employees ? employees.map(({first_name, last_name, guid})=>{
                            return {
                                name: `${first_name} ${last_name}`,
                                guid: guid
                            }
                        }):[],
                        children:departments?departments.map(({manager_first_name, manager_last_name, name, teams, employees, guid, fk_employee_guid})=>{
                            return {
                                name: name,
                                type:'Department',
                                guid : guid,
                                head_guid : fk_employee_guid,
                                head : manager_first_name && manager_last_name ? `${manager_first_name} ${manager_last_name}`: '',
                                employees: employees? employees.map(({first_name, last_name, guid})=>{
                                    return {
                                        name: `${first_name} ${last_name}`,
                                        guid: guid
                                    }
                                }):[],
                                children:teams? teams.map(({leader_first_name, leader_last_name, name, employees, guid, fk_employee_guid})=>{
                                    return {
                                        name: name,
                                        guid : guid,
                                        type: 'Team',
                                        head_guid : fk_employee_guid,
                                        head : leader_first_name && leader_last_name ?  `${leader_first_name} ${leader_last_name}`: '',
                                        employees: employees? employees.map(({first_name, last_name, guid})=>{
                                                return {
                                                    name: `${first_name} ${last_name}`,
                                                    guid: guid
                                                }
                                            }):[],
                                        children:[]
                                    }
                                }):[]
                            }
                        }):[]
                    }
                }):[]

            }

            this.setState({organisation:organisationData});

            this.setState({showLoader: false});

        }).catch(error => {
            this.setState({showLoader: false});
            this.showModal('modal', error, 'error')
        });

    }

    render() {

        return (
            <Layout title={`Organisation Organogram`}>

                <DashboardForm onSubmit={e=>e.preventDefault()} onKeyPress={e=> {
                    if(e.key==="Enter"){
                        e.preventDefault();
                    }
                }} style={!this.state.showLoader?{marginTop:'-10px'}:{display:'none'}}>

                    <UnstyledLegend style={{marginTop:'13px'}}>Organisation Structure</UnstyledLegend>
                    
                    {
                        Object.keys(this.state.organisation).length > 0 && 
                        <div style={{overflow: 'overlay', margin: '20px', paddingBottom: '20px'}}>
                            <OrgChart tree={this.state.organisation} NodeComponent={OrganizationStructure}/>
                        </div>
                    }
                    
                    <Buttons>
                        <SecondaryLink to={`/organisation/add-edit/${this.props.match.params.id}`}>Back</SecondaryLink>
                    </Buttons>

                </DashboardForm>

                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}

                <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
                    this.setState({showLoader: false});
                }}>
                    <p>Loading, please be patient...</p>
                </Loading>
                
            </Layout>
        );
    }
}

export default OrganisationOrganogram