import React, {Component} from 'react';
import {EditField, Layout, Search, NoResults} from '../components';
import {
    CustomTable,
    DARK_GRAY_OPACITY, GREEN,
    PaginationButtons,
    PrimaryButton,
    TableHeader,
    TableItem,
    TableRow
} from "../components/style";
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import {assessmentCycleService, assessmentService} from "../services";
import moment from "moment";
import Loading from "../components/loader/Loading";
import TypedModal from "../components/modals/TypedModal";

class Schedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            successModal: false,
            items: [],
            promptIndex: '',
            sortedColumn: '',
            searchTerm: '',
            pageNumber: 1,
            showLoader: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.promptDelete = this.promptDelete.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.paginate = this.paginate.bind(this);
        this.handleApiSearch = this.handleApiSearch.bind(this);


    }

    componentDidMount() {
        this.getAssessmentCycles();
    }

    getAssessmentCycles(search) {

        this.setState({showLoader: true});
        assessmentCycleService.getAssessmentCycles(search).then(result => {
            this.setState({items: result.data, showLoader: false});
        }).catch(error => {
            this.setState({showLoader: false});
            this.showModal('modal', error, 'error')
        });

    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message: message, type: type});
      }
    
      hideModal(modal) {
        if (this.state.type === 'success') {
          this.setState({[modal]: false, message: null, type: null});
        } else {
          this.setState({[modal]: false, message: null, type: null});
        }
      }
        
    handleSearch({target: {value}}) {
        //const items = SEARCH_COLLECTION(this.state.items, value);
        this.setState({searchTerm: value});
        if (value === "") {
            this.getAssessmentCycles();
        }
    }

    handleApiSearch() {
        this.getAssessmentCycles(this.state.searchTerm);
    }

    handleDelete() {
        const self = this;
        assessmentCycleService.deleteAssessmentCycle(this.state.promptIndex).then(result => {
            self.getAssessmentCycles();
            this.hideModal('promptModal');
        }).catch(error => {
            this.setState({showLoader: false});
            this.showModal('modal', error, 'error')
        });
    }
    
    handleSort(column) {
        const items = SORT_COLUMN(this.state.items, column);
        const alreadySorted = column === this.state.sortedColumn;

        if (alreadySorted) items.reverse();

        this.setState({items, sortedColumn: !alreadySorted ? column : ''});
    }

    promptDelete(index) {
        this.showModal('promptModal');
        this.setState({promptIndex: index});
    }

    handleNext() {
        this.setState({pageNumber: this.state.pageNumber + 1});
    }

    handleBack() {
        let currentPage = this.state.pageNumber;
        let setVal = 0;
        if (currentPage - 1 === 0) {
            setVal = 1
        } else {
            setVal = currentPage - 1
        }
        this.setState({pageNumber: setVal});
    }

    paginate(array, page_size, page_number) {
        --page_number; // because pages logically start with 1, but technically with 0
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    render() {
        return (
            <Layout title="Schedule Assessment Search">
                <div>
                    <Search match={this.props.match} handleSearch={this.handleSearch} apiSearch={this.handleApiSearch}
                            btnText="Start New Assessment Schedule"/>

                    <CustomTable>
                        <TableRow>
                            <TableHeader onClick={() => this.handleSort('date_due')}>
                                due date
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('name')}>
                                Name
                            </TableHeader>
                            <TableHeader onClick={() => this.handleSort('description')}>
                                Description
                            </TableHeader>
                            <TableHeader>
                                Completion
                            </TableHeader>
                            <TableHeader>
                            </TableHeader>
                        </TableRow>
                        {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((cycle, i) =>
                            <TableRow key={i}>
                                <TableItem>
                                    {moment(cycle.date_due).format("DD MMMM YYYY")}
                                </TableItem>
                                <TableItem>
                                    {cycle.name}
                                </TableItem>
                                <TableItem>
                                    {cycle.description}
                                </TableItem>
                                <TableItem>
                                    {cycle.total_reviewers > 0 ? (cycle.total_reviewers_completed + " of " + (cycle.total_reviewers - cycle.total_reviewers_declined)) : "—"}
                                </TableItem>
                                <TableItem>
                                    <EditField
                                        match={this.props.match}
                                        promptDelete={this.promptDelete}
                                        index={cycle.guid}
                                    />
                                </TableItem>
                            </TableRow>
                        ) : <NoResults searchTerm={this.state.searchTerm}/>}
                    </CustomTable>
                    <PaginationButtons>
                        {this.state.pageNumber > 1 ? <PrimaryButton onClick={this.handleBack}>Back</PrimaryButton> :
                            <div></div>}
                        {10 * this.state.pageNumber < this.state.items.length ?
                            <PrimaryButton onClick={this.handleNext}>Next</PrimaryButton> : <div></div>}
                    </PaginationButtons>

                </div>
                {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')}/>}
                {this.state.promptModal &&
                <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete}/>}
                <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN}>
                    <p>Loading, please be patient...</p>
                </Loading>

                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type}/>}

            </Layout>
        );
    }
};

export default Schedule;