export default {
    remapObject(obj, numToBool){
        Object.entries(obj).forEach(([key, value]) => {
            if(value instanceof Object){
                this.remapObject(value, numToBool);
            }
            if(numToBool){
                if(value===0 || value === "0"){
                    obj[key]=false;
                }
                if(value===1 || value === "1"){
                    obj[key]=true;
                }
            }else{
                if(key.includes("date")){
                    if(value && value!==null){
                        obj[key]= new Date(value).getTime()
                    }
                }
                if(value===true){
                    obj[key]=1;
                }
                if(value===false){
                    obj[key]=0;
                }
            }

        });
    }
}