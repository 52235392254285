import React, {Component} from 'react';
import styled from 'styled-components';
import {Layout, NoResults, Table} from '../components';
import {
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  Flex,
  FieldSet,
  FormRow,
  Legend,
  ROBOTO_MEDIUM,
  GREEN, SecondaryButton, CustomTable, TableRow, TableHeader, TableItem, PaginationButtons, PrimaryButton
} from '../components/style';
import SORT_COLUMN from '../utils/sortCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
//import data from './bonus-allocation/data';
import * as strings from "../utils/appstring";
import {employeeService, reportService} from "../services";
import InlineEdit from "../components/EditableInput";

const FlexRow = styled(FormRow)`
  flex-direction: column;
`;

// TODO: replace with the new convention see, Trend & Comparison Report
const Banner = styled(FieldSet)`
  border: solid 1px #cdcdcd;
  margin-bottom: 40px;
  border-radius: 5px;

  ${Legend} {
    border-top-width: 0;
  }
`;

const TableLegend = styled(Legend)`
  width: 100%;
  border-width: 1px 1px 0;
`;

const Heading = styled(Heading2)`
  padding: 0;
  margin: 0;
`;

const PrimaryHeading = styled(Heading2)`
  ${ROBOTO_MEDIUM};
  color: ${GREEN};
`;

class BonusAllocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            successModal: false,
            items: [],
            promptIndex: '',
            sortedColumn: '',
            pageNumber: 1,
            bonusTotal: 1000000.00,
            currentUser: JSON.parse(localStorage.getItem('user')),
            orgSetting: {
                branches: JSON.parse(localStorage.getItem('user')).organisation_obj.setup_branches !== 0,
                departments: JSON.parse(localStorage.getItem('user')).organisation_obj.setup_departments !== 0,
                teams: JSON.parse(localStorage.getItem('user')).organisation_obj.setup_teams !== 0,
            }
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.promptDelete = this.promptDelete.bind(this);
        this.dataChangedRow = this.dataChangedRow.bind(this);
        this.dataChanged = this.dataChanged.bind(this);
        this.getUsers();
    }

    showModal(modal) {
        this.setState({[modal]: true});
    }

    hideModal(modal) {
        this.setState({[modal]: false});
    }

    handleDelete() {
        const items = this.state.items.filter((_, i) => i !== this.state.promptIndex);
        this.setState({items, promptIndex: ''});
    }

    handleSort(column) {
        const items = SORT_COLUMN(this.state.items, column);
        const alreadySorted = column === this.state.sortedColumn;

        if (alreadySorted) items.reverse();

        this.setState({items, sortedColumn: !alreadySorted ? column : ''});
    }

    getUsers() {
        employeeService.getOrganisationEmployees(this.state.currentUser.organisation_obj.guid).then(res => {
            this.setState({items: res.data})
        })
    }

    getReport() {
        reportService.getBonusReport().then(result => {
            let url = URL.createObjectURL(result);
            window.open(url, "_blank");
        })
    }

    promptDelete(index) {
        this.showModal('promptModal');
        this.setState({promptIndex: index});
    }

    paginate(array, page_size, page_number) {
        --page_number; // because pages logically start with 1, but technically with 0
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    dataChanged(data) {
        this.setState({...data})
    }

    dataChangedRow(data) {
        let index  = Number(Object.keys(data).map(key => key.split('[')[1].split(']')[0])[0])
        let items = this.state.items;
        items[index].bonus = Object.values(data)[0]+'%';
        this.setState({items:items})
    }

    customValidateTextRow(text) {
        return !isNaN(text) && Number(text)<=100;
    }

    customValidateText(text) {
        return !isNaN(text);
    }

    render() {
        const headers = ['Department', 'Team', 'Percentage', 'Amount'];
        const {match} = this.props;
        return (
            <Layout title="Bonus Allocation">
                <div>
                    <Flex justify="center">
                        <Heading2 as={'h4'}>
                            Define Bonus Allocation Amount
                        </Heading2>
                    </Flex>

                    <Banner>
                        <Legend>Bonus Amounts</Legend>
                        <FlexRow justify="center" align="center">
                            <Heading as="h5">Bonus Amount Total</Heading>
                            <div>
                                <InlineEdit
                                    validate={this.customValidateText}
                                    activeClassName="editing"
                                    text={'R'+this.state.bonusTotal}
                                    paramName="bonusTotal"
                                    change={this.dataChanged}
                                    style={{
                                        fontFamily:"robotomedium",
                                        color:"#009b77",
                                        fontSize:"2em",
                                        margin: 0,
                                        padding: 0,
                                        outline: 0,
                                        border: 0
                                    }}
                                />
                            </div>
                            <SecondaryButton onClick={() => this.getReport()}>
                                Generate Report
                            </SecondaryButton>
                        </FlexRow>
                    </Banner>

                  <TableLegend>{this.state.currentUser.organisation_obj.name} Branch &amp; Departments </TableLegend>

                  <CustomTable>
                    <TableRow>
                      {this.state.orgSetting.branches?<TableHeader>
                        Branch
                      </TableHeader>:null}
                      {this.state.orgSetting.departments?<TableHeader>
                        Department
                      </TableHeader>:null}
                      {this.state.orgSetting.teams?<TableHeader>
                        Team
                      </TableHeader>:null}
                      <TableHeader>
                        Employee
                      </TableHeader>
                      <TableHeader>
                        Percentage
                      </TableHeader>
                      <TableHeader>
                        Amount
                      </TableHeader>
                    </TableRow>
                    {this.state.items.length ? this.paginate(this.state.items, 10, this.state.pageNumber).map((org, i) =>{
                      console.log(org)
                      return( <TableRow key={i}>
                        {this.state.orgSetting.branches? <TableItem>
                          Branch Name
                        </TableItem>:null}
                        {this.state.orgSetting.departments? <TableItem>
                          Department Name
                        </TableItem>:null}
                        {this.state.orgSetting.teams? <TableItem>
                          Team Name
                        </TableItem>:null}
                        <TableItem>
                          {org.first_name} {org.last_name}
                        </TableItem>
                        <TableItem>
                            <InlineEdit
                                validate={this.customValidateTextRow}
                                activeClassName="editing"
                                text={this.state.items[i].bonus?this.state.items[i].bonus:0+'%'}
                                paramName={`items[${i}].bonus`}
                                change={this.dataChangedRow}
                            />
                        </TableItem>
                        <TableItem>
                          R{this.state.items[i].bonus?(this.state.items[i].bonus.split('%')[0]/100)*this.state.bonusTotal:0}
                        </TableItem>
                      </TableRow>)}
                    ) : <NoResults /> }
                  </CustomTable>
                  <PaginationButtons>
                    {this.state.pageNumber>1?<PrimaryButton onClick={this.handleBack}>Back</PrimaryButton>:<div></div>}
                    {10*this.state.pageNumber<this.state.items.length?<PrimaryButton  onClick={this.handleNext}>Next</PrimaryButton>:<div></div>}
                  </PaginationButtons>
                    <Buttons>
                        <SecondaryLink to="#">
                            Cancel
                        </SecondaryLink>
                        <PillButton
                            type="submit"
                            onClick={() => this.showModal('successModal')}>
                            {strings.ok_Title}
                        </PillButton>
                    </Buttons>
                </div>
                {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')}/>}
                {this.state.promptModal &&
                <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete}/>}
            </Layout>
        );
    }
};

export default BonusAllocation;