import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const departmentService = {
    getDepartments,
    getDepartment,
    addDepartment,
    updateDepartment,
    deleteDepartment,
    getBranchDepartments,
    getOrganisationDepartments
}


function getDepartments(search) {
    return ajax.handlePost(`${API_ROOT}/department/search`, {search: search ? search : ""})
}
function getDepartment(guid) {
    return ajax.handleGet(`${API_ROOT}/department/retrieve/${guid}`)
}
function addDepartment(data) {
    return ajax.handlePost(`${API_ROOT}/department/add`, data)
}
function updateDepartment(data) {
    return ajax.handlePost(`${API_ROOT}/department/update`, data)
}
function deleteDepartment(guid) {
    return ajax.handlePost(`${API_ROOT}/department/delete`, {guid: guid})
}
function getBranchDepartments(guid) {
    return ajax.handlePost(`${API_ROOT}/department/branch/${guid}/search`, {search: ""})
}
function getOrganisationDepartments(guid) {
    return ajax.handlePost(`${API_ROOT}/department/organisation/${guid}/search`, {search: ""})
}