import React from "react";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import { DARK_GRAY } from "../../../../components/style";
import { FormRow, Label } from "./styles";

const SelectContainer = styled.div`
  > * {
    color: ${DARK_GRAY};
  }
  
`;

const reactSelect = ({ input, options, label, meta: { touched, error }, onCreate }) => (
  <FormRow style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
    <Label>{label}</Label>
    <SelectContainer>
      <CreatableSelect
        {...input}
        onChange={value => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        onCreateOption={(val)=>onCreate(val)}
      />
      {touched && error && <span>{error}</span>}
    </SelectContainer>
  </FormRow>
);

export default reactSelect;
