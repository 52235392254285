import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const organisationService = {
    getOrganisations,
    getOrganisation,
    updateOrganisation,
    addOrganisation,
    registerOrganisation,
    getOrganisationGraphData
}


function getOrganisations(search) {
    return ajax.handlePost(`${API_ROOT}/organisation/search`, {search: search ? search : ""})
}
function getOrganisation(guid) {
    return ajax.handleGet(`${API_ROOT}/organisation/retrieve/${guid}`)
}
function getOrganisationGraphData(guid) {
    return ajax.handleGet(`${API_ROOT}/organisation/retrieve/organogram/data/${guid}`)
}
function updateOrganisation(data) {
    return ajax.handlePost(`${API_ROOT}/organisation/update`, data)
}
function addOrganisation(data) {
    return ajax.handlePost(`${API_ROOT}/organisation/add`, data)
}
function registerOrganisation(data) {
    return ajax.handlePost(`${API_ROOT}/organisation/register`, data)
}