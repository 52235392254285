import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const questionairService = {
    completeQuestionair
}

function completeQuestionair(reviewer_guid, data) {
    return ajax.handlePost(`${API_ROOT}/questionare/${reviewer_guid}/complete`, data)
}

