import React, { Component } from 'react';
import { Layout, Search, CheckBox, NoResults } from '../components';
import {
  Buttons,
  ButtonsLessPadding,
  PillButton,
  SecondaryLink,
  CustomTable,
  TableRow,
  TableHeader,
  TableHeaderNested,
  TableHeaderAccordion,
  TableHeaderAccordionNested,
  TableItem,
  Heading2,
  PrimaryLink,
  FormRow as PaddedFlex, DARK_GRAY_OPACITY, GREEN, FieldSet, Legend, DashboardForm, FLEX_BETWEEN
} from '../components/style';
import Accordion from "./assign/AssignAccordion";
import AccordionNested from "./assign/AssignAccordionNested";
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import SuccessModal from '../components/modals/SuccessModal';
import PromptModal from '../components/modals/PromptModal';
import SpecialCheckBox from '../components/SpecialCheckBox';
import {assessmentCycleService} from "../services";
import Loading from "../components/loader/Loading";
import {Field, initialize, reduxForm} from "redux-form";
import ObjectMapper from '../utils/objectMapper';
import TypedModal from "../components/modals/TypedModal";
import renderSelect from "../components/RenderSelect";
import moment from "moment";
import styled from "styled-components";
import checkNullOrEmpty from "../utils/checkNull"

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;
  
  select {
    margin-left: 10px;
  }
  
  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
  }

 
`;

const makeAssigneeeRow = (parent, assignee, index, scope) => (
    <TableRow key={index}>
      <TableItem>{assignee.assignee_full_name}</TableItem>
      <TableItem>{checkNullOrEmpty(assignee.average)}</TableItem>
      <PrimaryLink to="#" onClick={parent.props.handleSubmit((e) => parent.getAssessmentManagersFeedback(assignee.assessment_cycle_guid, assignee.employee_guid))}>Get Feedback Report</PrimaryLink>
    </TableRow>
);

const makeReviewerRow = (reviewer, index, scope) => (
    <TableRow key={index}>
      <TableItem>{reviewer.reviewer_full_name}</TableItem>
      <TableItem>{checkNullOrEmpty(reviewer.total)}</TableItem>
    </TableRow>
);

const makeNoteRow = (note, index, name, scope) => (
    <TableRow key={index}>
      <TableItem>{checkNullOrEmpty(note.question)}</TableItem>
      <TableItem>{checkNullOrEmpty(note.category)}</TableItem>
      <TableItem>{Number(note.mark)}</TableItem>
      <TableItem>{checkNullOrEmpty(note.comment)}</TableItem>
      <TableItem >
        {/* name `items[${i}].reviewers[${j}].notes[${x}].allow_note` */}
        <Field component={CheckBox} name={name + `.notes[${index}].allow_note`}
          onChange={
            (evt,val) => 
              {
                if(val === true){
                  scope.allowNote(note.question_detail_guid);
                }else {
                  scope.removeNote(note.question_detail_guid);
                }
              }
          }
        />
      </TableItem>
    </TableRow>
);


class DraftReviewFeedback extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      showloader: false,
      successModal: false,
      data: {
        items:[],
        guid: ''
      },
      promptIndex: '',
      sortedColumn: '',
      searchTerm: '',
      cycles:[],
      currentUser: user,
      handleConfirmation: null
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);

  }

  componentDidMount() {
    this.getAssessmentCycles()
    this.getAssessmentCycleResultSummary(this.props.match.params.id)
  }

  handleConfirmation() {
    window.location = '/employees';
  }

  fakeSave() {

    this.setState({showloader: true});
    this.state.handleConfirmation = this.handleConfirmation;

    setTimeout(() => {
      this.setState({showloader: false});
      this.showModal('modal', 'Save was successful.', 'success');
    }, 1000);

  }

  allowNote(guid){
    //this.setState({showloader: true});
    assessmentCycleService.getAssessmentCycleResultAllowNote(guid).then(result => {
      //this.setState({showloader: false});
    }).catch(error => {
      //this.setState({showloader: false});
      if (error.toLowerCase().includes("invalid user right")) {
        this.state.handleConfirmation = this.handleConfirmation;
        this.showModal('modal', error, 'error')
      } else {
          this.showModal('modal', error, 'error')
      }
    });
  }

  removeNote(guid) {
    //this.setState({showloader: true});
    assessmentCycleService.getAssessmentCycleResultRemoveNote(guid).then(result => {
      //this.setState({showloader: false});
    }).catch(error => {
      //this.setState({showloader: false});
      if (error.toLowerCase().includes("invalid user right")) {
        this.state.handleConfirmation = this.handleConfirmation;
        this.showModal('modal', error, 'error')
      } else {
            this.showModal('modal', error, 'error')
      }
    });
  }

  getAssessmentCycles() {

    this.setState({showLoader: true});

    assessmentCycleService.getAssessmentCycles("locked").then(result => {
      this.setState({cycles: result.data, showLoader: false});
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getAssessmentCycleResultSummary(assessment_cycle_guid) {

    this.setState({showloader: true});
    assessmentCycleService.getAssessmentCycleResultSummary(assessment_cycle_guid).then(result => {
      ObjectMapper.remapObject(result.data, true);
      result.data.forEach(assignee=>{
        assignee.open = false;
        assignee.reviewers.forEach(reviewer=>{
          reviewer.open = false;
          reviewer.notes.forEach(note=>{
            note.open = false;
          })
        })
      });
      this.setState({data:{ items: result.data, guid: assessment_cycle_guid}, showloader: false, send_notification: false});
      this.props.dispatch(initialize(
          'draft-review',
          this.state.data
      ));
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  completeAssessmentCycle(e, assessment_cycle_guid) {

    this.setState({showloader: true});

    let assessment_cycle = {};
    assessment_cycle.guid = assessment_cycle_guid;

    assessmentCycleService.completeAssessmentCycle(assessment_cycle).then(result => {
      
      this.setState({showloader: false, send_notification: false});
      this.showModal('modal', result.message, 'success')

    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getAssessmentManagersFeedback(cycle_guid, cycle_fk_employee) {

    this.setState({showloader: true});
    assessmentCycleService.getAssessmentCyclesManagersFeedback(cycle_guid, cycle_fk_employee).then(result => {

        this.setState({showloader: false});

        try {
          let url = URL.createObjectURL(result);
          window.open(url, "_blank");
        } catch(err) {
          this.showModal('modal', result.message, 'error')
        }  

    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  render() {
    return (
      <Layout title="Draft Review Feedback">
        <div>
          <PaddedFlex justify="center">
            <Heading2 as={'h4'}>Please select comments to include in employee feedback result.</Heading2>
          </PaddedFlex>

          {this.props.match.params.id === ":id" && <DashboardForm>
            <FieldSet>
              <Legend>Schedule</Legend>
              <Wrapper>
                <label>Schedule</label>
                <Field
                    component={renderSelect}
                    id="due-date"
                    name="guid"
                    options={this.state.cycles.map(({name, guid, date_due, organisation_name}) => {
                      let label = `${name} (${moment(date_due).format("DD/MM/YYYY")})`;
                      if (this.state.currentUser.user_type === "SYSADMIN") {
                          label= organisation_name + " > " + label;
                      }
                      return {label: label, value: guid}
                    })}
                    onChange={({target: {value}}) => {
                      this.setState({selectedCycle:{guid:value}});
                      this.getAssessmentCycleResultSummary(value);
                    }}

                />
              </Wrapper>
            </FieldSet>
          </DashboardForm>}

          <CustomTable>
            <TableRow>
              <TableHeaderAccordion>
                Full Name
              </TableHeaderAccordion>
              <TableHeader>
                Average
              </TableHeader>
            </TableRow>
            {this.state.data.items.length > 0 && this.state.data.items.map((assignee, i) => {
              return (
                  <Accordion key={`assignee-${i}`} label={makeAssigneeeRow(this, assignee, i, this)} >
                    {assignee.reviewers.length > 0 && assignee.reviewers.map((reviewer, j) => {
                      return (
                          <CustomTable key={`reviewer-table-${j}`}>
                            <TableRow>
                              <TableHeaderAccordionNested>
                                Reviewer Full Name
                              </TableHeaderAccordionNested>
                              <TableHeaderNested>
                                Score
                              </TableHeaderNested>
                            </TableRow>

                            <AccordionNested chevron={true} key={`reviewer-${j}`} label={makeReviewerRow(reviewer, j, this)} >

                              <TableRow>
                                <TableHeaderNested>
                                  Question Detail
                                </TableHeaderNested>
                                <TableHeaderNested>
                                  Category
                                </TableHeaderNested>
                                <TableHeaderNested>
                                  Mark
                                </TableHeaderNested>
                                <TableHeaderNested>
                                  Feedback
                                </TableHeaderNested>
                                <TableHeaderNested>
                                  Allow
                                </TableHeaderNested>
                              </TableRow>

                              {reviewer.notes.length > 0 && reviewer.notes.map((note, x) =>  
                                <AccordionNested chevron={false} key={`note-${x}`} label={makeNoteRow(note, x, `items[${i}].reviewers[${j}]`, this)} />
                              )}

                            </AccordionNested>
                          
                          </CustomTable>
                      );
                    })}
                  </Accordion>
              )
            })}
          </CustomTable>
          <ButtonsLessPadding>
            <SecondaryLink to="#" onClick={()=>this.props.history.goBack()}>
              Cancel
            </SecondaryLink>
            {(this.state.currentUser.user_type == 'SYSADMIN' || this.state.currentUser.user_type == 'ORGADMIN') &&
            <PillButton disabled={this.state.send_notification} onClick={this.props.handleSubmit((e) => this.completeAssessmentCycle(e, this.props.match.params.id))}>
                Submit and Finalise Assessment Results
            </PillButton>}
            {(this.state.currentUser.user_type == 'MANAGER') &&
            <PillButton disabled={this.state.send_notification} onClick={this.props.handleSubmit((e) => this.fakeSave())}>
                Save
            </PillButton>}
          </ButtonsLessPadding>
          

          {/* <Buttons>
            <SecondaryLink to="#">
              Cancel
            </SecondaryLink>
            <PillButton 
              type="submit"
              onClick={() => this.showModal('successModal')}>
              Save
            </PillButton>
          </Buttons> */}
        </div>
        {this.state.successModal && <SuccessModal hideModal={() => this.hideModal('successModal')} />}
        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete} />}

        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type} affirmAction={this.state.handleConfirmation}/>}
         
      </Layout>
    );
  }
};

export default reduxForm({
  form: 'draft-review'
})(DraftReviewFeedback);