import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  Heading1,
  Heading2,
  AccessForm,
  MultiColumn as ImportedMultiColumn,
  ROBOTO_REGULAR,
  GRAY,
  DARK_GRAY
} from "../../components/style";

export const Heading = styled(Heading1)`
  margin-top: 30px;
  text-align: center;
`;

export const SubHeading = styled(Heading2)`
  margin: 20px 0 40px;
  text-align: center;
`;

export const SignUpForm = styled(AccessForm)`
  height: 500px;
  overflow-y: auto;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;

  > * {
    width: 200px;
  }
`;

export const CenterText = styled.div`
  ${ROBOTO_REGULAR}
  text-align: center;
  width: 58%;
  margin: 0 auto;
  color: ${GRAY};
  font-size: 15px;

  > em {
    color: #009b77;
    font-style: normal;
  }
`;

export const MultiColumn = styled(ImportedMultiColumn)`
  width: 100%;

  input {
    max-width: 400px;
  }
`;

export const StyledLink = styled(Link)`
  color: #009b77;
`;

export const Terms = styled.label`
  ${ROBOTO_REGULAR};
  color: ${DARK_GRAY};
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  padding: 15px 0 15px 0 

  > input {
    width: initial;
    margin-right: 10px;
  }

  ${StyledLink} {
    margin: 0 5px;
  }
`;

export const CheckBoxAndLabel = styled.label`
  ${ROBOTO_REGULAR};
  color: ${DARK_GRAY};
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;

  > input {
    width: initial;
    margin-right: 10px;
  }

  ${StyledLink} {
    margin: 0 5px;
  }
`;

