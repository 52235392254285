import React, {Component} from 'react';
import {Layout, CheckBox} from '../../components';
import {
    Heading2,
    Buttons,
    PillButton,
    SecondaryLink,
    Flex,
    CustomTable,
    TableRow,
    FormMeta,
    DashboardForm, 
    EditDate,
    TableHeader,
    TableItem, 
    FieldSet, 
    Legend, 
    MultiColumn, 
    PrimaryLink,
    ButtonsLessPadding
} from '../../components/style';
import SORT_COLUMN from '../../utils/sortCollection';
import data from './data';
import {
    assessmentCycleService,
    assessmentService,
    branchService,
    departmentService, employeeService, lookupService,
    organisationService,
    teamService
} from "../../services";
import {Field, initialize, reduxForm} from "redux-form";
import renderField from "../../components/RenderField";
import renderSelect from "../../components/RenderSelect";
import renderDatePicker from "../../components/RenderDatePicker";
import ObjectMapper from "../../utils/objectMapper";
import PromptModal from '../../components/modals/PromptModal';
import TypedModal from "../../components/modals/TypedModal";
import SpecialCheckBox from "../../components/SpecialCheckBox";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";
import {required} from '../../utils/validation';
import ComparisonReport from '../ComparisonReport';

const CHAR_LIMIT = 120;

const parseText = txt => {
    console.log(txt);
    if (txt.length > CHAR_LIMIT) return <blockquote>{txt}</blockquote>;
    return `${txt}`;
};

class Schedule extends Component {

    constructor(props) {

        super(props);

        this.state = {
            modal: false,
            items: data,
            promptIndex: '',
            sortedColumn: '',
            assessments: [],
            organisations: [],
            departments: [],
            branches: [],
            teams: [],
            employees: [],
            assessmentCycle: {},
            fullOrgs: [],
            assignees: [],
            currentUser: JSON.parse(localStorage.getItem('user')), 
            showLoader: false,
            finishedAsigneeAsignment: true
        };

        this.getLookups();
        this.getAssessments(this.state.currentUser.user_type !== "SYSADMIN" ? this.state.currentUser.fk_organisation : null);

        if(this.state.currentUser.user_type !== "SYSADMIN"){

            this.state.selectedOrganisation = this.state.currentUser.fk_organisation;
            this.getOrganisations();
            this.getAssessments(this.state.currentUser.fk_organisation);
            this.getBranches(this.state.currentUser.fk_organisation);
            this.getDepartments(this.state.currentUser.fk_organisation);
            this.getTeams(this.state.currentUser.fk_organisation);
            this.getEmployees(this.state.currentUser.fk_organisation);

        } else {

            this.getOrganisations();
            this.getAssessments();
            this.getBranches();
            this.getDepartments();
            this.getTeams();

        }

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.promptConfirmation = this.promptConfirmation.bind(this);
        this.handlePromptAffirm = this.handlePromptAffirm.bind(this);
        this.handleModalAffirm = this.handleModalAffirm.bind(this);
        this.getAssessmentCycle =  this.getAssessmentCycle.bind(this);

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.getAssessmentCycle(this.props.match.params.id);
        }
    }

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.getAssessmentCycle(this.props.match.params.id);
        } 
    }

    promptConfirmation(e, guid) {

        this.showModal('promptModal');
        this.setState(
            {
                submitEvent: e,
                submitGuid: guid
            }
        );

    }

    handlePromptAffirm() {

        this.hideModal('promptModal');
        
        this.saveAssessmentSchedule(this.state.submitEvent, this.state.submitGuid);

        this.setState(
            {
                submitEvent: null,
                submitGuid: null
            }
        );

    }

    handleModalAffirm() {
        window.location = `/assessment-cycle/schedule`;
    }

    getAssessmentCycle(guid) {

        this.setState({showLoader: true});

        assessmentCycleService.getAssessmentCycle(guid).then(result => {

            this.setState({showLoader: false});
            this.setupPageSettings(result.data.fk_organisation);
            this.setState({
                assessmentCycle: result.data,
                selectedOrganisation: result.data.fk_organisation,
                selectedBranch: result.data.fk_branch,
                selectedDepartment: result.data.fk_department,
                selectedTeam: result.data.fk_team,
                assignees: result.data.assignees,
                previousAssignees: result.data.assignees && result.data.assignees.length > 0,
                canSelectAssignees: result.data.active === 1 && result.data.locked === 0 && result.data.completed === 0,
                finishedAsigneeAsignment: result.data.total_assignees == result.data.assignees.length
            });

            this.getEmployees(result.data.fk_organisation);
            this.props.dispatch(initialize(
                'scheduler',
                this.state.assessmentCycle
            ));

        }).catch(error => {
            this.setState({showLoader: false});
            this.showModal('modal', error, 'error')
        });

    }

    getOrganisations() {
        organisationService.getOrganisations().then(result => {
            let orgs = []
            result.data.map(({name, guid}) => {
                return orgs.push({label: name, value: guid});
            })
            this.setState({organisations: orgs, fullOrgs: result.data});
        }).catch(error => {
            this.setState({showLoader: false});
            this.showModal('modal', error, 'error')
        });
    }


    getAssessments(orgGuid) {

        if (orgGuid) {

            assessmentService.getClimateAssessmentsByOrganisation(orgGuid).then(result1 => {
                assessmentService.getPerformanceAssessmentsByOrganisation(orgGuid).then(result2 => {
                    assessmentService.getKnowledgeSkillsAssessmentsByOrganisation(orgGuid).then(result3 => {
                        this.setState({assessments: Array.prototype.concat(result1.data, result2.data, result3.data)});
                    }).catch(error => {
                        this.setState({showLoader: false});
                        this.showModal('modal', error, 'error')
                    });
                }).catch(error => {
                    this.setState({showLoader: false});
                    this.showModal('modal', error, 'error')
                });
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });


        } else {

            assessmentService.getPerformanceAssessmentsAll().then(result1 => {
                assessmentService.getKnowledgeSkillsAssessmentsAll().then(result2 => {
                    assessmentService.getClimateAssessmentsAll().then(result3 => {
                        this.setState({assessments: Array.prototype.concat(result1.data, result2.data, result3.data)});
                    }).catch(error => {
                        this.setState({showLoader: false});
                        this.showModal('modal', error, 'error')
                    });
                }).catch(error => {
                    this.setState({showLoader: false});
                    this.showModal('modal', error, 'error')
                });
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });

        }

    }

    getDepartments(orgGuid) {

        if (orgGuid) {

            departmentService.getOrganisationDepartments(orgGuid).then(result => {
                this.setState({departments: result.data})
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });

        } else {

            departmentService.getDepartments().then(result => {
                this.setState({departments: result.data})
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });
        }

    }

    getBranches(orgGuid) {

        if (orgGuid) {
            branchService.getOrganisationBranches(orgGuid).then(result => {
                this.setState({branches: result.data})
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });

        } else {
            branchService.getBranches().then(result => {
                this.setState({branches: result.data})
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });
        }

    }

    getTeams(orgGuid) {

        if (orgGuid) {
            teamService.getOrganisationTeams(orgGuid).then(result => {
                this.setState({teams: result.data})
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });;

        } else {
            teamService.getTeams().then(result => {
                this.setState({teams: result.data})
            }).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });;
        }

    }

    getEmployees(orgGuid) {

        if (this.props.match.params.id !== 'new') {

            employeeService.getOrganisationEmployees(orgGuid, true).then(result => {
                    for(const emp of result.data){
                        for(let assignee of this.state.assignees){
                            if(assignee.fk_employee===emp.guid){
                                emp.selected = true;
                            }
                        }
                    }
                    this.setState({employees: result.data})
                }
            ).catch(error => {
                this.setState({showLoader: false});
                this.showModal('modal', error, 'error')
            });
        }

    }

    setupPageSettings(orgGuid) {

        if (orgGuid) {
            const org = this.state.fullOrgs.filter(org => {
                return org.guid === orgGuid
            })[0];
            this.setState({
                setupBranches: org ? org.setup_branches !== 0 : true,
                setupDepartments: org ? org.setup_departments !== 0 : true,
                setupTeams: org ? org.setup_teams !== 0 : true
            })
        } else {
            this.setState({
                setupBranches: true,
                setupDepartments: true,
                setupTeams: true
            })
        }
    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message: message, type: type});
    }

    hideModal(modal) {
        this.setState({[modal]: false, message: null, type: null});
    }

    handleSort(column) {

        const items = SORT_COLUMN(data, column);
        const alreadySorted = column === this.state.sortedColumn;

        if (alreadySorted) items.reverse();

        this.setState({items, sortedColumn: !alreadySorted ? column : ''});

    }

    getLookups() {

        lookupService.getPreferredComms().then(preferredComms => {
            let comms = []
            preferredComms.data.map(({name, guid}) => {
                return comms.push({label: name, value: name});
            })
            this.setState({preferredComm: comms})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

    }

    saveAssessmentSchedule(data, guid) {

        if (data.include_self || data.include_peers || data.include_manager || data.include_subordinates) {

            this.setState({showLoader: true});
            data.date_end = data.date_due;
            data.fk_organisation = this.state.selectedOrganisation;
            data.fk_branch = this.state.selectedBranch;
            data.fk_department = this.state.selectedDepartment;
            data.fk_team = this.state.selectedTeam;
            ObjectMapper.remapObject(data, false);
    
            if (guid === 'new') {
                //insert
                assessmentCycleService.addAssessmentCycle(data).then(result => {

                    this.setState({showLoader: false});
                    this.setState({handleModalAffirm: function() {
                        window.location = `/assessment-cycle/schedule/add-edit/${result.data.guid}`;
                    }});
                    this.showModal('modal', result.message, 'success');
                    
                });            
    
            } else {
    
                //update
                data.assignees = this.state.assignees;
                assessmentCycleService.updateAssessmentCycle(data).then(result => {

                    this.setState({handleModalAffirm: this.handleModalAffirm});
                    this.setState({showLoader: false});
                    this.showModal('modal', result.message, 'success');

                }).catch(error => {
                    this.setState({showLoader: false});
                    this.showModal('modal', error, 'error');
                });
    
            }

        } else {

            this.showModal('modal', 'Please select atleast one type of Reviewer!', 'error');

        }

    }

    render() {
        const headers = ['Full Name', 'Email', 'Include in Cycle'];
        const {items} = this.state;

        return (

            <Layout 
                title="Schedule Assessments"
                dateCreated={this.state.assessmentCycle.date_created}
                dateModified={this.state.assessmentCycle.date_modified}
                dirty={this.props.dirty || !this.state.previousAssignees}
            >
                
                <div>
                    <DashboardForm
                        onSubmit={this.props.handleSubmit((e) => this.promptConfirmation(e, this.props.match.params.id))}>
                    
                        <Flex justify="center">
                            <Heading2 as={'h4'} style={{marginTop:'30px'}}>Create Assessment Schedule and assign which employees should receive this assessment.</Heading2>
                        </Flex>
                    
                        <FieldSet>

                            <Legend>Details</Legend>

                            <MultiColumn>
                                <Field
                                    component={renderField}
                                    id="name"
                                    name="name"
                                    placeholder="Name"
                                />
                                <Field
                                    component={renderField}
                                    id="description"
                                    name="description"
                                    placeholder="Description"
                                />
                            </MultiColumn>

                            <MultiColumn>
                                <Field
                                    component={renderDatePicker}
                                    placeholder="Due date"
                                    id="due-date"
                                    name="date_due"
                                    validate={required} 
                                    required
                                /> 
                                <Field
                                    component={renderSelect}
                                    id="notification"
                                    name="preferred_comms"
                                    placeholder="Preferred notification delivery"
                                    options={this.state.preferredComm}
                                />
                            </MultiColumn>

                            <MultiColumn>

                                {this.state.currentUser.user_type === "SYSADMIN" ?
                                    <Field component={renderSelect} id="organisation" placeholder="Organisation"
                                           name="fk_organisation"
                                           options={this.state.organisations} onChange={({target: {value}}) => {
                                        this.setupPageSettings(value);
                                        this.setState({
                                            selectedBranch: null,
                                            selectedDepartment: null,
                                            selectedTeam: null,
                                            selectedOrganisation: value
                                        });
                                        this.getBranches(value);
                                        this.getDepartments(value);
                                        this.getTeams(value);
                                        this.getAssessments(value);
                                        this.getEmployees(value);
                                    }}/> :
                                    <Field component={renderField} id="organisation" placeholder="Organisation"
                                           name="UNUSED" disabled={true}
                                           input={{value: this.state.currentUser.organisation_obj.name}}/>}
                                <Field
                                    component={renderSelect}
                                    id="choose-assessment"
                                    name="fk_assessment"
                                    placeholder="Select Assessment"
                                    options={this.state.assessments.map(({name, guid}) => {
                                        return {label: name, value: guid}
                                    })}
                                />

                            </MultiColumn>

                            <Flex justify="center">
                                <Heading2 as={'h4'}>Reviewer Types</Heading2>
                            </Flex>

                            <MultiColumn>
                                <Field component={CheckBox} name="include_self" placeholder="Self"/>
                                <Field component={CheckBox} name="include_peers" placeholder="Peers" />
                                <Field component={CheckBox} name="include_manager" placeholder="Manager" />
                                <Field component={CheckBox} name="include_subordinates" placeholder="Subordinates" />
                            </MultiColumn>

                            <MultiColumn style={{paddingTop: '40px'}}>

                                {this.state.assessmentCycle.completed === 1 && <Flex justify="center">
                                    <Heading2 as={'h4'}>
                                        This cycle has been completed.
                                    </Heading2>
                                </Flex>}
                        
                                {this.state.assessmentCycle.locked === 1 && this.state.assessmentCycle.completed === 0 && <Flex justify="center">
                                    <Heading2 as={'h4'}>
                                        This cycle is in launched (locked) state, you can not select assignees any longer.
                                    </Heading2>
                                </Flex>}
                        
                                {this.state.assessmentCycle.active === 1 && this.state.assessmentCycle.locked === 0 && this.state.assessmentCycle.completed === 0 && <Flex justify="center">
                                    <Heading2 as={'h4'}>
                                        This cycle is has not been launched.
                                    </Heading2>
                                </Flex>}
                        
                            </MultiColumn>

                            {(!this.state.finishedAsigneeAsignment) &&
                                <div>
                                    <MultiColumn>
                                        <Flex justify="center">
                                            <Heading2 as={'h4'}>
                                                Employees Assigned to this cycle are still in the process of being setup!                                    
                                            </Heading2>
                                        </Flex>
                                    </MultiColumn>
                                    <MultiColumn>
                                        <ButtonsLessPadding>
                                            <PrimaryLink onClick={() => {window.location.reload();}}>Refresh</PrimaryLink>
                                        </ButtonsLessPadding>
                                   </MultiColumn>
                                </div>
                            }
                            {(this.state.finishedAsigneeAsignment && this.props.match.params.id !== 'new') &&
                                <div>
                                    <Legend>Employees Assigned Filters</Legend>

                                
                                    <MultiColumn style={{padding : "10px"}}>
                                        {this.state.setupBranches &&
                                        <Field
                                            component={renderSelect}
                                            id="branch"
                                            name="fk_branch"
                                            placeholder="Select Branch"
                                            options={this.state.branches.filter(item=>{
                                                return item.fk_organisation_guid === this.state.selectedOrganisation;
                                            }).map(({name, guid}) => {
                                                return {label: name, value: guid}
                                            })}
                                            onChange={({target: {value}}) => {
                                                this.setState({
                                                    selectedDepartment: null,
                                                    selectedTeam: null,
                                                    selectedBranch:value
                                                });

                                            }}
                                        />
                                        }
                                        {this.state.setupDepartments &&
                                        <Field
                                            component={renderSelect}
                                            id="department"
                                            name="fk_department"
                                            placeholder="Select Department"
                                            options={this.state.departments.filter(item=>{
                                                return item.fk_branch_guid === this.state.selectedBranch;
                                            }).map(({name, guid}) => {
                                                return {label: name, value: guid}
                                            })}
                                            onChange={({target: {value}}) => {
                                                this.setState({selectedTeam: null, selectedDepartment:value});
                                            }}
                                        />
                                        }
                                        {this.state.setupTeams &&
                                        <Field
                                            component={renderSelect}
                                            id="team"
                                            name="fk_team"
                                            placeholder="Select Team"
                                            options={this.state.teams.filter(item=>{
                                                return item.fk_department_guid === this.state.selectedDepartment;
                                            }).map(({name, guid}) => {
                                                return {label: name, value: guid}
                                            })}
                                            onChange={({target: {value}}) => {
                                                this.setState({selectedTeam:value})
                                            }}
                                        />}
                                    </MultiColumn>
                                </div>
                            }


                        </FieldSet>

                        {(this.state.finishedAsigneeAsignment) &&
                            <div>
                                {this.props.match.params.id !== 'new' && <CustomTable>
                                    <TableRow>
                                        {headers && headers.map((header, i) => (
                                            <TableHeader key={header} onClick={() => this.handleSort(`columns${i + 1}`)}>
                                                {header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>

                                    {this.state.employees.length > 0 && (

                                        this.state.employees.filter(emp=>{
                                            if(this.state.selectedTeam){
                                                return emp.team_guid === this.state.selectedTeam;
                                            }
                                            if(this.state.selectedDepartment){
                                                return emp.department_guid === this.state.selectedDepartment;
                                            }
                                            if(this.state.selectedBranch){
                                                return emp.branch_guid === this.state.selectedBranch;
                                            }
                                            return true;

                                        }).map((row, r) => (

                                            <TableRow key={r}>
                                                <TableItem key={`fullname_${r}`}>
                                                    {`${row.first_name} ${row.last_name}`}
                                                </TableItem>
                                                <TableItem key={`email_${r}`}>
                                                    {row.contact_obj?row.contact_obj.email:""}
                                                </TableItem>
                                                <TableItem key={`selected_${r}`}>
                                                    {this.state.canSelectAssignees && <Field component={SpecialCheckBox} name={'UNUSED123'} input={{value:row.selected}}
                                                        changeFunc={(val) => {

                                                            this.setState({previousAssignees: false});

                                                            if (val.value!=="true") {
                                                                //add
                                                                row.selected=true;
                                                                const currArr = this.state.assignees;
                                                                currArr.push({
                                                                    fk_employee:row.guid,
                                                                    date_due:this.state.assessmentCycle.date_due,
                                                                    person_email:row.contact_obj?row.contact_obj.email:"",
                                                                    date_end:this.state.assessmentCycle.date_due,
                                                                    fk_cycle:this.props.match.params.id,
                                                                    person_full_name:`${row.first_name} ${row.last_name}`,
                                                                    selected:true
                                                                });
                                                                this.setState({assignees: currArr})
                                                            } else {
                                                                //remove
                                                                row.selected=false;
                                                                const currArr = this.state.assignees;
                                                                const index = currArr.findIndex(item => item.fk_employee === row.guid);
                                                                currArr.splice(index, 1);
                                                                this.setState({assignees: currArr})
                                                            }
                                                        }}/>
                                                    }
                                                    {this.state.canSelectAssignees ? '' : row.selected ? <div>Included</div> : <div>Excluded</div>}
                                                </TableItem>

                                            </TableRow>

                                        ))

                                    )}

                                </CustomTable>}
                            </div>
                        }

                        <ButtonsLessPadding>
                            <SecondaryLink to="/assessment-cycle/schedule" onClick={this.props.dirty || !this.state.previousAssignees? (e) => 
                            {
                                e.preventDefault();
                                this.showModal('modal', 'You have made changes that you will be losing if you do not save!', 'info');
                            }
                            :undefined}>Cancel</SecondaryLink>
                            {(this.state.finishedAsigneeAsignment) &&
                                <PillButton type="submit">
                                    Save
                                </PillButton>
                            }
                        </ButtonsLessPadding>

                        {(this.state.finishedAsigneeAsignment) &&
                            <div>
                                {this.props.match.params.id !== 'new' &&
                                
                                <ButtonsLessPadding>
                                
                                    <PrimaryLink to={`/assessment-cycle/assign/${this.props.match.params.id}`} style={this.state.previousAssignees ? {} : {display: 'none'}}>
                                        Launch, View Assignees & Reviewers
                                    </PrimaryLink>

                                </ButtonsLessPadding>}
                            </div>
                        }

                    </DashboardForm>

                    
                <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
                    this.setState({showLoader: false});
                }}>
                    <p>Loading, please be patient...</p>
                </Loading>

                </div>

                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handlePromptAffirm} message="By clicking save, previous assignees will be removed and added again. And reviewers will receive a new batch of invites."/>}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                 type={this.state.type} affirmAction={this.state.handleModalAffirm}/>}

            </Layout>
        );
    }
};

export default reduxForm({
    form: 'scheduler'
})(Schedule);