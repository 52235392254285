import React, {Component} from 'react';
import {Layout} from '../../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
  Buttons,
  DashboardForm,
  EditDate,
  FieldSet,
  FormMeta,
  Legend,
  LegendInfoSection, 
  MultiColumn,
  PillButton,
  SecondaryLink
} from '../../components/style';
import {required} from '../../utils/validation';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {integratorService, lookupService} from "../../services";
import moment from "moment";
import TypedModal from "../../components/modals/TypedModal";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

const southAfricaGuid = ['049a3444-9d89-11e9-835a-068fc7503a26'];

class AddEdit extends Component{

  constructor(props, context) {
    
    super(props, context);
    
    this.state = { integrator: {}, handle:"", countries:[], provinces:[], selectedCountry:{}, showloader: false};
    
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

    this.getLookups();


    
  }

  componentDidMount() {
    if(this.props.match.params.id !== 'new'){
      this.loadIntegrator(this.props.match.params.id);
    }
  }

  loadIntegrator(guid){
    this.setState({showloader: true});
    integratorService.getIntegrator(guid).then(result => {
      this.setState({showloader: false});
      this.setState({integrator:result.data, selectedCountry:result.data.address.country})
        this.props.dispatch(initialize(
            'integrator-add-edit',
            this.state.integrator
        ));
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    if(this.state.type==='success'){
      this.setState({[modal]: false, message:null, type:null});
      this.props.history.push('/integrators')
    }else{
      this.setState({[modal]: false, message:null, type:null});
    }
  }

  getLookups(){
    lookupService.getCountries().then(countries =>{
        let countryLookup = []
        countries.data.map(({name, guid})=>{
            return countryLookup.push({label:name, value:guid});
        })
        this.setState({countries:countryLookup})
    }).catch(error => {
      this.showModal('modal', error, 'error')
    })
    lookupService.getProvinces().then(provinces =>{
        let provinceLookup = []
        provinces.data.map(({name, guid})=>{
            return provinceLookup.push({label:name, value:guid});
        })
        this.setState({provinces:provinceLookup})
    }).catch(error => {
      this.showModal('modal', error, 'error')
    })

}

  saveIntegrator(data, id) {
    this.setState({showloader: true});
    /*const saveData = {
        id: val.id,
        name: val.name,
        email: val.email,
        guid: val.guid,
        manager_first_name: val.manager_first_name,
        phone_number: val.phone_number,
        manager_last_name: val.manager_last_name,
        fk_organisation_guid: val.fk_organisation_guid
    };*/

    console.log('xxx: id=' + id);
    console.log('address guid: ' + data.address.guid);
    console.log('contact guid: ' + data.contact.guid);

    if (id === 'new') {

      const newIntegrator = {
        type: data.type,
        identifier: data.identifier,
        country: data.address.country,
        email: data.contact.email,
        first_name: data.first_name,
        last_name: data.last_name,
        guid: null,
        created_by: data.created_by,
        modified_by: null,
        date_created: null,
        active: 1,
        address: data.address.address,
        deleted: 0,
        address_guid: null,
        autoid: 0,
        date_modified: null,
        contact_guid: null,
        phone_number: data.contact.phone_number,
        complex: data.address.complex,
        unit: data.address.unit,
        fk_guid: null,
        suburb: data.address.suburb,
        street: null,
        city_town: data.address.city_town,
        floor: null,
        building: null,
        postal_code: data.address.postal_code,
        province: data.address.province,
        facebook: data.contact.facebook,
        mobile_number: null,
        prefer_email: 0,
        autodid: 0,
        web: data.contact.web,
        prefer_sms: 0,
        fax: data.contact.fax,
        twitter: data.contact.twitter,
        api_key: null,
        middle_name: data.middle_name,
        name: data.name
      };

        console.log('Preparing to do create new record...')
        console.log(JSON.stringify(newIntegrator));

        // do add
        integratorService.addIntegrator(newIntegrator).then(result => {
          this.setState({showloader: false});
          console.log(result);
          this.showModal('modal', result.message, 'success')
        }).catch(error => {
          this.setState({showloader: false});
          this.showModal('modal', error, 'error')
        })

    } else {

        //do update
        integratorService.updateIntegrator(data).then(result => {
          this.setState({showloader: false});
          this.showModal('modal', result.message, 'success')
        }).catch(error => {
          this.setState({showloader: false});
          this.showModal('modal', error, 'error')
        })

    }

  }

  render() {
    return (
      
      <Layout 
        title={`Integrators ${this.props.match.params.id==='new'?"Add":"Edit"}`}
        dateCreated={this.state.integrator.date_created}
        dateModified={this.state.integrator.date_modified}
        dirty={this.props.dirty}
        >
      
        <DashboardForm onSubmit={this.props.handleSubmit((e) => this.saveIntegrator(e, this.props.match.params.id))} onKeyPress={e=> {
          if(e.key==="Enter"){
            e.preventDefault();
          }
        }}>

          {/* Section */}
          <FieldSet>
            <Legend>Integrator information
            <LegendInfoSection>add informational text to explain expectation</LegendInfoSection>
            </Legend>
            
              <MultiColumn>
                <MultiColumn>
                  <Field component={renderField} placeholder="Identifier" name="identifier" validate={required} required/>
                  <Field component={renderField} placeholder="Integrator name" name="name" validate={[required]} required/>
                </MultiColumn>
                <Field component={renderField} placeholder="API Key" name="api_key" disabled={true} />            
              </MultiColumn>
          </FieldSet>

          <FieldSet>
            <Legend>Information</Legend>
              <MultiColumn>
                <MultiColumn>
                  <Field component={renderField} placeholder="Name" name="first_name" validate={[required]} required />
                  <Field component={renderField} placeholder="Middle Name" name="middle_name" />
                  <Field component={renderField} placeholder="Surname" name="last_name" validate={[required]} required />
                </MultiColumn>
                
              </MultiColumn>
              
          </FieldSet>

          <FieldSet>
            <Legend>Address</Legend>
              <MultiColumn>
                <MultiColumn>
                  <Field component={renderField} placeholder="Address" name="address.address" validate={[required]} required />
                  <Field component={renderField} placeholder="Unit" name="address.unit" />
                  <Field component={renderField} placeholder="Postal code" name="address.postal_code" validate={[required]} required />
                </MultiColumn>
                
              </MultiColumn>
              <MultiColumn>
                <MultiColumn>
                  <Field component={renderField} placeholder="Suburb" name="address.suburb" />
                  <Field component={renderField} placeholder="Town" name="address.city_town" />
                  <Field component={renderSelect} id="country" name="address.country" options={this.state.countries} placeholder="Select Country" onChange={(e, newVal)=>this.setState({selectedCountry:newVal})}/>
                  {southAfricaGuid.includes(this.state.selectedCountry) && <Field component={renderSelect} id="province" options={this.state.provinces} name="address.province" disabled={!southAfricaGuid.includes(this.state.selectedCountry)} placeholder="Select Province" />}
                </MultiColumn>
              </MultiColumn>
          </FieldSet>

          <FieldSet>
            <Legend>Contact Details</Legend>
              <MultiColumn>
                <MultiColumn>
                  <Field component={renderField} placeholder="Phone number" name="contact.phone_number" validate={[required]} required />
                  <Field component={renderField} placeholder="Fax" name="contact.fax" />
                  <Field component={renderField} placeholder="Email" name="contact.email" validate={[required]} required />
                </MultiColumn>
              </MultiColumn>
              <MultiColumn>
                <MultiColumn>
                  <Field component={renderField} placeholder="Web" name="contact.web" />
                  <Field component={renderField} placeholder="Facebook" name="contact.facebook" />
                  <Field component={renderField} placeholder="Twitter" name="contact.twitter" />
                </MultiColumn>
              </MultiColumn>
          </FieldSet>

          <Buttons>
            <SecondaryLink to="/integrators" onClick={this.props.dirty? (e) => 
                {
                    e.preventDefault();
                    this.showModal('modal', 'You have made changes that you will be losing if you do not save!', 'info');
                }
            :undefined}>Cancel</SecondaryLink>
            <PillButton disabled={!this.props.valid} type="submit">Save</PillButton>
          </Buttons>

        </DashboardForm>

        {/* Modal */}
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}


        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

        
      </Layout>

    );
  }
}

AddEdit = reduxForm({
  form: 'integrator-add-edit',
  destroyOnUnmount: true
})(AddEdit);

export default AddEdit