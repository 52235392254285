import React, { Fragment, Component } from "react";
// import { connect } from "react-redux";
import {reduxForm, Field, initialize, formValueSelector} from "redux-form";
import logo from "../images/logo.svg";
import styled from 'styled-components';
import GreenLeftArrow from "../images/GreenLeftArrow.svg";
import ObjectMapper from "../utils/objectMapper";

import  { 
  BackBtn, 
  Buttons, 
  BottomLogo, 
  BottomStrap, 
  Center, 
  ExtendedBanner, 
  ExtendedTitleBar, 
  Flex, 
  Logo, 
  Question, 
  Row, 
  TextArea, 
  DARK_GRAY_OPACITY, 
  GREEN

} from './external-assessment/styles';

import { LegendQuestionair, FlexRow, PillButton, ButtonsLessPadding
} from "../components/style";

import { required } from '../utils/validation';
import data from './external-assessment/data';
import RatingScale from './external-assessment/RatingScale';
import MultipleChoice from './external-assessment/MultipleChoice';
import TrueFalse from './external-assessment/TrueFalse';
import ProgressBar from './external-assessment/ProgressBar';
import {questionairService, assessmentService, mediaService} from '../services';
import Loading from "../components/loader/Loading";
import TypedModal from "../components/modals/TypedModal";
import PromptModal from "../components/modals/PromptModal";
import * as queryString from 'query-string'
import ScrollToTop from "react-scroll-to-top";
import "react-scroll-to-top/lib/index.css"


// import FormValues from '../utils/FormValues';
const ratingScaleOptions = [
  {title: 1, value: 1},
  {title: 2, value: 2},
  {title: 3, value: 3},
  {title: 4, value: 4},
  {title: 5, value: 5}
]

const LogoImg = styled.img`
  border-radius: 5px;
  max-width: 250px
`;

class ExternalAssessment extends Component {

  constructor(props) {
    
    super(props);

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.getCharacterLength = this.getCharacterLength.bind(this);

    // use this as test url for external assessment:
    // http://localhost:3000/external/assessment?ref=bbea0d32-c41a-11e9-ac5d-061d260a8c74
    // not sure if this is correct but i'm getting a value
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let reference = params.get('ref');
    let assessmentGuid = params.get('assessment_guid');

    console.log('Reference: ' + reference);

    this.state = { 
      assessment: {}, 
      questions: [], 
      organisationLogo:null, 
      viewing:!!assessmentGuid,
      successModal:false,
      reviewer_guid: reference,
      assessment_guid: assessmentGuid,
      reviewer : {},
      assignee : {},
      getCharacterLength : this.getCharacterLength

    };

  }

  componentDidMount() {

    const parsed = queryString.parse(this.props.location.search);

    if(parsed && parsed.message){
      this.showModal('modal', parsed.message, 'info');
    } else if(parsed && parsed.error){
      this.showModal('modal', parsed.error, 'error');
    } else if(parsed && parsed.info){
      this.showModal('modal', parsed.info, 'info');
    }

    this.setState({load: true})
    
    if (this.state.reviewer_guid||this.state.assessment_guid) {

      if(this.state.viewing){
        this.loadFullAssessment(this.state.assessment_guid);
      }else{
        this.loadReviewerAssigneeDetails(this.state.reviewer_guid);
        this.loadFullAssessment(this.state.reviewer_guid);
      }

    } else {
      console.log('no reference value');
    }

  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  handleConfirmation() {
    this.hideModal('promptModal');
    this.saveAssessment(this.state.assessment);
  }

  handleModalAffirm() {
    window.close();
  }

  getCharacterLength(fieldName) {
    console.log(fieldName);
  }

  loadReviewerAssigneeDetails(reviewer_guid) {

    assessmentService.getReviewerAssigneeDetails(reviewer_guid).then(result => {

      this.setState({reviewer:result.data.reviewer, assignee: result.data.assignee});
      
    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    });

  }

  loadFullAssessment(guid) {

    this.setState({showLoader: true});
    if (this.state.viewing) {
    
      assessmentService.getFullAssessment(guid).then(result => {

        this.setState({
          assessment:result.data, 
          questions:result.data.questions,
          showLoader: false
        });

        this.getOrganisationPicture(result.data.organisation_guid);

        this.props.dispatch(initialize(
          'external-assessment',
          this.state.assessment
        ));

      }).catch(error => {
        this.setState({showLoader: false});
        this.showModal('modal', error, 'error')
      });

    } else {

      assessmentService.getFullAssessmentExternal(guid).then(result => {

        this.setState({
          assessment:result.data,
          questions:result.data.questions,
          showLoader: false
        });

        this.getOrganisationPicture(result.data.organisation_guid);
        this.props.dispatch(initialize(
          'external-assessment',
          this.state.assessment
        ));

      }).catch(error => {
        this.setState({showLoader: false});
        this.showModal('modal', error, 'error')
      })

    }

  }

  promptSaveAssessment(assessment) {

    this.setState({assessment: assessment});
    this.showModal('promptModal', 'Are you sure you want to submit? Answers are final and cannot be edited afterwards.');
      
  }

  saveAssessment(assessment) {

    this.setState({handleModalAffirm: this.handleModalAffirm});
    this.setState({showLoader: true});

    ObjectMapper.remapObject(assessment, false);

    let questionairObj = {};
    questionairObj.status = 1;
    questionairObj.fk_assessment_cycle = assessment.guid;
    questionairObj.fk_assignee = "todo";
    questionairObj.fk_reviewer = this.state.reviewer_guid;

    let questionare_details = [];

    let questionTotal = assessment.questions.length;

    for (var i = 0; i < questionTotal; i++) {

      let assessmentQuestion = assessment.questions[i];

      let questionDetail = {};
      questionDetail.notes = assessmentQuestion.additional_comments;
      questionDetail.fk_asses_question = assessmentQuestion.guid;
      questionDetail.ques_description = assessmentQuestion.description;
      questionDetail.answer_description = assessmentQuestion.selected_answer;

      // guid of the correct answer for multi
      // 1 or 0 for true false
      // 1 or 5 for rating
      if (assessmentQuestion.question_type === 'Rating Scale') {

        questionDetail.fk_assess_answer = assessmentQuestion.selected_answer; 

      } else if (assessmentQuestion.question_type === 'Multiple Choice') {

        let fk = "";

        for (var j = 0; j < assessmentQuestion.answers.length; j++) {

          let answerOption = assessmentQuestion.answers[j];

          if (assessmentQuestion.selected_answer === answerOption.answer_multi) {
            fk = answerOption.guid;
            break;
          }

        }

        questionDetail.fk_assess_answer = fk; 

      } else if (assessmentQuestion.question_type === 'True / False') {

        questionDetail.fk_assess_answer = assessmentQuestion.selected_answer.toUpperCase() === "TRUE" ? 1 : 0; 

      }

      questionare_details.push(questionDetail);

    }

    questionairObj.questionare_details = questionare_details;

    questionairService.completeQuestionair(this.state.reviewer_guid, questionairObj).then(result => {

      this.setState({showLoader: false});
      this.showModal('modal', result.message, 'success')

    }).catch(error => {
      this.setState({showLoader: false});
      this.showModal('modal', error, 'error')
    })
      
  }

  getOrganisationPicture(guid){

    mediaService.getOrganisationPicture(guid).then(response=>{

      if(response.data && response.data.error){

      }else{
        this.setState({organisationPicture:URL.createObjectURL(response)});
      }

    });

  }

  render () {
    
    const { valid, handleSubmit, history } = this.props;

    return (
      <Fragment>

        <ExtendedTitleBar>
          Performance Assessment
        </ExtendedTitleBar>
        
        <Center>
          
          {this.state.questions && this.state.questions.length>0 && <ProgressBar data={this.state.questions} />}
  
          <Logo>
            <LogoImg src={this.state.organisationPicture}/>
          </Logo>
          
          {this.state.viewing && 
            <Question>
              Dear <i>Reviewer Name</i>, 
              <br/><br/>Please complete the assessment for <i>Assignee Name</i>.
            </Question>
          }
          {(!this.state.viewing && this.state.questions && this.state.questions.length > 0 && this.state.assignee.person_full_name == this.state.reviewer.person_full_name) && 
            <Question>
              Dear {this.state.assignee.person_full_name}, 
              <br/><br/>Please complete your personal assessment.
            </Question>
          }
          {(!this.state.viewing && this.state.questions && this.state.questions.length > 0 && this.state.assignee.person_full_name != this.state.reviewer.person_full_name) && 
            <Question>
              Dear {this.state.reviewer.person_full_name}, 
              <br/><br/>Please complete the assessment for {this.state.assignee.person_full_name}.
            </Question>
          }
  
          <form onSubmit={handleSubmit((e) => this.promptSaveAssessment(e))}>

            {this.state.questions && this.state.questions.map((question, i) =>

              <ExtendedBanner key={`${question.category}-${i+1}`}>

                <LegendQuestionair>{question.category}</LegendQuestionair>
                
                <FlexRow justify="center" align="center">

                  <Question style={{marginTop:'4px'}}>{question.description}</Question>
                  
                  <Flex style={{marginTop:'-16px'}}>
                    <Row>
                      {question.question_type === 'Rating Scale' && question.answers && (
                        <Field
                          component={RatingScale}
                          validate={[required]}
                          key={i}
                          options={ratingScaleOptions}
                          name={`questions[${i}].selected_answer`}
                          rdoGroup={`rating_scale_${i}`}
                        />
                      )}
                    </Row>
                    <Row>
                    <Flex>
                      {question.question_type === 'Multiple Choice' && question.answers && (
                        <Field
                          component={MultipleChoice}
                          validate={[required]}
                          key={i}
                          options={question.answers}
                          name={`questions[${i}].selected_answer`}
                        />
                      )}
                    </Flex>
                    </Row>
                    <Row>
                      {question.question_type === 'True / False' && (
                        <Field 
                          component={TrueFalse}
                          validate={[required]}
                          name={`questions[${i}].selected_answer`}
                          options={question.answers}
                          key={i}
                        />
                      )}
                    </Row>
                  </Flex>
            
                  <TextArea
                    name={`questions[${i}].additional_comments`}
                    component="textarea"
                    rows="5"
                    maxLength="1000"
                    placeholder="Please provide any additional feedback here."
                    onChange={(e)=> {

                      if (e.target.value.length + 10 > e.target.maxLength) {
                        document.getElementById(e.target.name.split('.')[0] + '.warning').innerHTML = 'Running out of characters. There are ' + (e.target.maxLength - e.target.value.length) + ' left.';
                      } else {
                        document.getElementById(e.target.name.split('.')[0] + '.warning').innerHTML = '';
                      }

                    }}
                  />

                  <label style={{color:'orange', marginTop:'-32px', marginBottom:'10px'}} id={`questions[${i}].warning`}/>
  
                </FlexRow>

              </ExtendedBanner> 

            )}
  
            <ButtonsLessPadding>
              <PillButton disabled={!valid || this.state.viewing} type="submit">Submit</PillButton>
            </ButtonsLessPadding>
            
          </form>
  
          {/* <FormValues /> */}
  
        </Center>
        <BottomStrap>Powered by</BottomStrap>

        <BottomLogo>
          <img src={logo} alt="AssessMe" />
        </BottomLogo>

        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleConfirmation} message={this.state.message} />}
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                 type={this.state.type} affirmAction={this.state.handleModalAffirm}/>}

        <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}> 
          <p>Loading, please be patient...</p>
        </Loading>

        <ScrollToTop smooth style={{marginBottom: "25px", marginRight: "-20px"}} />
                
      </Fragment>
    )

  }

};
export const selector = formValueSelector('external-assessment');
export default ExternalAssessment = reduxForm({
  form: 'external-assessment'
})(ExternalAssessment);