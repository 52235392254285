import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const lookupService = {
    getCountries,
    getProvinces,
    getEthnicity,
    getEmploymentStatuses,
    getEmploymentTypes,
    getUserTypes,
    getQuestionTypes,
    getAssessmentSubTypes,
    getPreferredComms
}


function getCountries() {
    return ajax.handleGet(`${API_ROOT}/lookup/countries`)
}
function getProvinces() {
    return ajax.handleGet(`${API_ROOT}/lookup/provinces`)
}
function getEthnicity() {
    return ajax.handleGet(`${API_ROOT}/lookup/ethnicity`)
}
function getEmploymentTypes() {
    return ajax.handleGet(`${API_ROOT}/lookup/employment-type`)
}
function getEmploymentStatuses() {
    return ajax.handleGet(`${API_ROOT}/lookup/employment-status`)
}
function getUserTypes() {
    return ajax.handleGet(`${API_ROOT}/lookup/user-types`)
}
function getQuestionTypes() {
    return ajax.handleGet(`${API_ROOT}/lookup/question-types`)
}
function getAssessmentSubTypes() {
    return ajax.handleGet(`${API_ROOT}/lookup/assessment-sub-types`)
}

function getPreferredComms() {
    return ajax.handleGet(`${API_ROOT}/lookup/preferred-comms`)
}