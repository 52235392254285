import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Routes, {AccordionRoutes} from "./Routes";
import Accordion from "./Accordion";
import AccordionItem from "./accordion/AccordionItem";
import {SideBar, StyledNavLink} from "./style";

const SideBarItems = Routes.filter(r => r.sidebar);
const extraChecks = ['Branches', 'Departments', 'Teams'];

class SideBarComponent extends Component {

  constructor(props) {

    super(props);

    this.state = {children : AccordionRoutes, selectedLabel : ""};
    this.restoreMenuToDefaults = this.restoreMenuToDefaults.bind(this);
    this.closeOtherOpenChildren = this.closeOtherOpenChildren.bind(this);

    this.restoreMenuToDefaults();

  }

  componentDidMount() {

    //console.log("componentDidMount");

  }

  componentDidUpdate(previousProps, previousState) {

    //if menu closes restore its state
    if (previousProps.isOpen && !this.props.isOpen) {
      this.restoreMenuToDefaults();
    }

  }

  restoreMenuToDefaults() {

    this.state.children.map(({label, children}, key) => {

      this.state.children[key].isOpen = false;
      children.map((({path, label, roles, sidebar}, innerKey) => {
        this.state.children[key].children[innerKey].isOpen = false;
        //lets check the browser url path, because then we know what menu item should be selected
        if (path.indexOf(this.props.location.pathname) > -1) {
          this.state.children[key].children[innerKey].isOpen = true;
          this.state.children[key].isOpen = true;
          this.state.selectedLabel = this.state.children[key].label;
        }
      }));

    });

  }

  closeOtherOpenChildren(selectedLabel) {

    let newChildren = Object.assign([], this.state.children);

    newChildren.map(({label, children}, key) => {

      if (selectedLabel != label) {
        if (children.isOpen != false) {
          newChildren[key].isOpen = false;
          newChildren[key].children.map(({label, children}, innerkey) => {
            if (newChildren[key].children[innerkey] != false) {
              newChildren[key].children[innerkey].isOpen = false;
            }
          });
        }
      } else {
        newChildren[key].isOpen = !newChildren[key].isOpen;
      }

    });

    this.setState({children: newChildren});

  }

  render() {

    let renderedChildren = this.state.children;

    return <SideBar isOpen={this.props.isOpen}>

        {SideBarItems.map(({path, label, roles}) => {
          
            let user = JSON.parse(localStorage.getItem('user'));

            if (roles.includes(user.user_type)) {

              if(extraChecks.includes(label) && user.user_type !== "SYSADMIN"){

                switch (label) {
                  case extraChecks[0]:
                    if(user.organisation_obj.setup_branches){
                      return (
                          <StyledNavLink to={path} activeClassName="active" key={label}>
                            {label}
                          </StyledNavLink>
                      )
                    }else{
                      return null
                    }
                  case extraChecks[1]:
                    if(user.organisation_obj.setup_departments){
                      return (
                          <StyledNavLink to={path} activeClassName="active" key={label}>
                            {label}
                          </StyledNavLink>
                      )
                    }else{
                      return null
                    }
                  case extraChecks[2]:
                    if(user.organisation_obj.setup_teams){
                      return (
                          <StyledNavLink to={path} activeClassName="active" key={label}>
                            {label}
                          </StyledNavLink>
                      )
                    }else{
                      return null
                    }
                  default:
                      return null;
                }

              }else{

                return (
                    <StyledNavLink to={path} activeClassName="active" key={label}>
                      {label}
                    </StyledNavLink>
                )

              }

            } else {
                return null
            }
        })}
        {renderedChildren &&
        renderedChildren.map(({label, children, roles}, key) => 
            <div key={`${label}${key}`}>
              <Accordion
                  roles={roles}
                  label={label}
                  key={`${label}${key}`}
                  isOpen={renderedChildren[key].isOpen}
                  toggleOpen={() => {
                    this.closeOtherOpenChildren(label)
                  }}
              >
                  {children &&
                  children.map(({path, label, roles, sidebar}) => {
                      let user = JSON.parse(localStorage.getItem('user'));
                      if (roles.includes(user.user_type) && sidebar) {
                          return (
                            <div key={path}>
                              <AccordionItem
                                  to={path}
                                  activeClassName="active"
                                  key={path}
                                  exact
                              >
                                  {label}
                              </AccordionItem>
                            </div>
                          )
                      } else {
                          return null
                      }

                  })}
              </Accordion>
            </div>
        )}
    </SideBar>
  }

}

export default withRouter(SideBarComponent);
