import React, { Component } from 'react';
import styled from 'styled-components';
import { Layout } from '../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
  DashboardForm,
  Heading2,
  Buttons,
  PillButton,
  SecondaryLink,
  PrimaryLink,
  Flex,
  Banner,
  FlexRow,
  Legend,
  CustomTable,
  TableRow,
  TableHeader,
  TableItem,
  CHECKMARK,
  DARK_GRAY_OPACITY,
  GREEN,
  FLEX_CENTER, FieldSet, FLEX_BETWEEN, SecondaryButton, ButtonsLessPadding
} from '../components/style';
import renderField from '../components/RenderField';
import SORT_COLUMN from '../utils/sortCollection';
import * as strings from "../utils/appstring";
import {assessmentCycleService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import Loading from "../components/loader/Loading";
import renderDatePicker from "../components/RenderDatePicker";
import renderSelect from '../components/RenderSelect';
import moment from "moment";

const Checkmark = styled.div`
  ${FLEX_CENTER};
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 10px;

  &:after {
    ${CHECKMARK};
  }
`;

const Wrapper = styled.div`
  ${FLEX_BETWEEN};
  margin: 0 auto;
  max-width: 350px;
  
  select {
    margin-left: 10px;
  }
  
  label {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 0px;
    white-space: nowrap;
  }

 
`;

const TableLegend = styled(Legend)`
  width: 100%;
  border-width: 1px 1px 0;
`;

class SubmitFeedback extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = { 
      showloader: false,
      successModal: false,
      send_notification: true,
      selectedSchedule:"",
      items: [],
      cycle: {},
      cycles:[],
      assignees:[],
      promptIndex: '',
      sortedColumn: '',
      currentUser: user
    };

    this.handleSort = this.handleSort.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);

  }

  componentDidMount() {
    this.getAssessmentCycles();
  }

  getAssessmentCycles() {

    this.setState({showloader: true});

    assessmentCycleService.getAssessmentCycles("locked").then(result => {
        this.setState({cycles: result.data, showloader: false});
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getAssessmentCycleFull(guid) {

    this.setState({showloader: true});
    assessmentCycleService.getAssessmentCycle(guid).then(result => {
        this.setState({cycle: result.data, showloader: false, send_notification: false});
    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  getOutstanding(reviewers) {

    let outstanding = 0;

    for (var i = 0; i < reviewers.length; i++) {
      let reviewer = reviewers[i];
      if (reviewer.status !== "COMPLETED") {
        outstanding++;
      }
    }

    return outstanding;

  }

  completeAssessmentCycle(e, assessment_cycle_guid) {

    this.setState({showloader: true});

    let assessment_cycle = {};
    assessment_cycle.guid = assessment_cycle_guid;

    assessmentCycleService.completeAssessmentCycle(assessment_cycle).then(result => {
      
      this.setState({showloader: false, send_notification: false});
      this.showModal('modal', result.message, 'success')

    }).catch(error => {
      this.setState({showloader: false});
      this.showModal('modal', error, 'error')
    });

  }

  navigateToReview(guid) {
    window.location = `/performance-management/review-results/${guid}`;
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message: message, type: type});
  }

  hideModal(modal) {
    if (this.state.type === 'success') {
      this.setState({[modal]: false, message: null, type: null});
      this.props.history.push('/assessment-cycle/schedule')
    } else {
      this.setState({[modal]: false, message: null, type: null});
    }
  }

  handleSort(column) {
    const items = SORT_COLUMN(this.state.items, column);
    const alreadySorted = column === this.state.sortedColumn;

    if (alreadySorted) items.reverse();

    this.setState({ items, sortedColumn: !alreadySorted ? column : '' });
  }

  render() {
    return (
      <Layout title="Submit Feedback">
        <div>

          <Flex justify="center">
            <Heading2 as={'h4'}>
              See current and previous scheduled assessment cycles' statuses
            </Heading2>
          </Flex>

          <DashboardForm>
            <FieldSet>
              <Legend>Schedule</Legend>
              <Wrapper>
                <label>Schedule</label>
                <Field
                    component={renderSelect}
                    id="due-date"
                    name="date_end"
                    options={this.state.cycles.map(({name, guid, date_due, organisation_name}) => {
                      let label = `${name} (${moment(date_due).format("DD/MM/YYYY")})`;
                      if (this.state.currentUser.user_type === "SYSADMIN") {
                          label= organisation_name + " > " + label;
                      }
                      return {label: label, value: guid}
                    })}

                    onChange={({target: {value}}) => {
                      this.setState({selectedSchedule:value});
                      this.getAssessmentCycleFull(value);
                    }}

                />
              </Wrapper>
            </FieldSet>
          </DashboardForm>

          <Legend>Employees</Legend>

          <CustomTable>
            <TableRow>
              <TableHeader>
                Employee Name
              </TableHeader>
              <TableHeader>
                Total
              </TableHeader>
              <TableHeader>
                Outstanding
              </TableHeader>
            </TableRow>
            {this.state.cycle.assignees && this.state.cycle.assignees.map((assignee, i) =>
              <TableRow key={i}>
                <TableItem>
                  {assignee.person_full_name}
                </TableItem>
                <TableItem>
                  {assignee.reviewers.length}
                </TableItem>
                <TableItem>
                  {this.getOutstanding(assignee.reviewers)}
                </TableItem>
              </TableRow>
                
            )}

          </CustomTable>
        
          <ButtonsLessPadding>
            <PillButton disabled={this.state.send_notification} onClick={this.props.handleSubmit((e) => this.completeAssessmentCycle(e, this.state.cycle.guid))}>
                Submit and Finalise Assessment Results
            </PillButton>
          </ButtonsLessPadding>
          
          <ButtonsLessPadding>
            <PillButton disabled={this.state.selectedSchedule ===""} onClick={this.props.handleSubmit((e) => this.navigateToReview(this.state.selectedSchedule))}>
                Review Results
            </PillButton>
          </ButtonsLessPadding>
          
          <ButtonsLessPadding>
            <SecondaryButton type="button" onClick={()=>this.props.history.goBack()}>Cancel</SecondaryButton>
          </ButtonsLessPadding>

        </div>

        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>

        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                         type={this.state.type}/>}

      </Layout>
    );
  }
};

export default reduxForm({
  form: 'submit-feedback'
})(SubmitFeedback);