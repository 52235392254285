import React, {Component} from 'react';
import {EditField, Layout, NoResults, Search} from '../components';
import {
    CustomTable,
    DARK_GRAY_OPACITY, GREEN,
    PaginationButtons,
    PrimaryButton,
    TableHeader,
    TableItem,
    TableRow,
} from '../components/style';
import SORT_COLUMN from '../utils/sortCollection';
import SEARCH_COLLECTION from '../utils/searchCollection';
import PromptModal from '../components/modals/PromptModal';
import {branchService} from "../services";
import TypedModal from "../components/modals/TypedModal";
import Loading from '../components/loader/Loading';

//import data from './branches/data';

class Branches extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(localStorage.getItem("user"));

        if (user.user_type==="SYSADMIN") {
            this.state = {
                modal: false, 
                items: [], 
                promptIndex: '', 
                searchTerm: '',
                pageNumber:1,
                sysadmin: true,
                showLoader:false
            };
        } else {
            this.state = {
                modal: false, 
                items: [], 
                promptIndex: '', 
                searchTerm: '',
                pageNumber:1,
                sysadmin: false,
                showLoader:false
            };
        }

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.promptDelete = this.promptDelete.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.paginate = this.paginate.bind(this);
        this.handleApiSearch = this.handleApiSearch.bind(this);

    }

    componentDidMount() {
        this.getBranches();
    }

    getBranches(search) {
        this.setState({showLoader:true});
        branchService.getBranches(search).then(result => {
            this.setState({items: result.data, showLoader:false})
        }).catch(error => {
            this.setState({showLoader:false});
            this.showModal('modal', error, 'error')
        });
    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {
        if(this.state.type==='success'){
            this.setState({[modal]: false, message:null, type:null});
            this.props.history.push('/branches')
        }else{
            this.setState({[modal]: false, message:null, type:null});
        }
    }

    handleSearch({target: {value}}) {
        //const items = SEARCH_COLLECTION(this.state.items, value);
        this.setState({searchTerm: value});
        if(value===""){
            this.getBranches();
        }
    }

    handleApiSearch(){
        this.setState({showLoader:true});
        this.getBranches(this.state.searchTerm);
    }
    
    handleDelete() {
        const self = this;
        this.hideModal('promptModal');
        this.setState({showLoader:true});
        branchService.deleteBranch(this.state.promptIndex).then(result => {
            this.setState({showLoader:false});
            self.getBranches();
        }).catch(error => {
            this.setState({showLoader:false});
            this.showModal('modal', error, 'error')
        });
    }

    handleSort(column) {
        const items = SORT_COLUMN(this.state.items, column);
        this.setState({items});
    }

    promptDelete(index) {
        this.showModal('promptModal');
        this.setState({promptIndex: index});
    }

    handleNext() {
        this.setState({ pageNumber: this.state.pageNumber+1 });
    }
    handleBack() {
        let currentPage = this.state.pageNumber;
        let setVal=0;
        if(currentPage-1===0){
            setVal=1
        }else{
            setVal=currentPage-1
        }
        this.setState({ pageNumber: setVal });
    }
    paginate (array, page_size, page_number) {
        --page_number; // because pages logically start with 1, but technically with 0
        return array.slice(page_number * page_size, (page_number + 1) * page_size);
    }

    handleRowClick(guid) {
        console.log(guid)
        //please review this
       this.props.history.push(this.props.match.path + "/add-edit/" + guid);
    }

    render() {
        return (
            <Layout title="Branch Search">
                <div>
                    <Search match={this.props.match} handleSearch={this.handleSearch} apiSearch={this.handleApiSearch}/>
                    <CustomTable>
                        <TableRow>
                            <TableHeader onClick={() => this.handleSort('name')}>
                                Branch Name
                            </TableHeader>
                            {this.state.sysadmin
                                ?
                                <TableHeader onClick={() => this.handleSort('organisation_name')}>
                                Organisation Name
                                </TableHeader>
                                : null
                            }
                            <TableHeader onClick={() => this.handleSort('manager_last_name')}>
                                Branch Manager
                            </TableHeader>
                        </TableRow>
                        {(this.state.items.length > 0) ? this.paginate(this.state.items, 10, this.state.pageNumber).map((branch, i) =>
                            <TableRow key={i}>
                                <TableItem>
                                    {branch.name}
                                </TableItem>
                                {this.state.sysadmin
                                ?
                                <TableItem>
                                    {branch.organisation_name}
                                </TableItem>
                                : null
                                }
                               <TableItem>
                                    {branch.manager_first_name + ' ' + branch.manager_last_name}
                                    <EditField
                                        match={this.props.match}
                                        promptDelete={this.promptDelete}
                                        index={branch.guid}
                                    />
                                </TableItem>
                            </TableRow>
                        ) : <NoResults searchTerm={this.state.searchTerm} entity="Branches"/>}
                    </CustomTable>

                    <PaginationButtons>
                        {this.state.pageNumber>1?<PrimaryButton onClick={this.handleBack}>Back</PrimaryButton>:<div></div>}
                        {10*this.state.pageNumber<this.state.items.length?<PrimaryButton  onClick={this.handleNext}>Next</PrimaryButton>:<div></div>}
                    </PaginationButtons>
                </div>
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}
                {this.state.promptModal &&
                <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleDelete}/>}
                <Loading loading={this.state.showLoader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>
            </Layout>
        );
    }
}

export default Branches;