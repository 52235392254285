import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const branchService = {
    getBranches,
    getBranch,
    addBranch,
    deleteBranch,
    updateBranch,
    getOrganisationBranches
};


function getBranches(search) {
    return ajax.handlePost(`${API_ROOT}/branch/search`, {search: search ? search : ""})
}

function getBranch(guid) {
    return ajax.handleGet(`${API_ROOT}/branch/retrieve/${guid}`)
}

function addBranch(data) {
    return ajax.handlePost(`${API_ROOT}/branch/add`, data)
}

function updateBranch(data) {
    return ajax.handlePost(`${API_ROOT}/branch/update`, data)
}

function deleteBranch(guid) {
    return ajax.handlePost(`${API_ROOT}/branch/delete`, {guid:guid})
}

function getOrganisationBranches(guid){
    return ajax.handlePost(`${API_ROOT}/branch/organisation/${guid}/search`, {search: ""})
}