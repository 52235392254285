import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const authService = {
    login,
    logout,
    forgotPassword,
    loginStatus
}

function login(email, password) {
    return ajax.auth({email, password})
}

function logout() {
    // remove user from local storage to log user out
    return ajax.logout()
}

function forgotPassword(data) {
    return ajax.handlePost(`${API_ROOT}/auth/forgot-password`, data)
}

function loginStatus(token) {
    return ajax.handleGet(`${API_ROOT}/auth/login-status/${token}`)
}


