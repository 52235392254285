import React, {Component} from 'react';
import styled from 'styled-components';
import {Layout} from '../../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
    Buttons,
    DashboardForm,
    EditDate,
    FieldSet,
    FLEX_CENTER,
    FormMeta,
    IconContainer,
    Legend,
    LogoImg,
    LogoRow,
    MultiColumn,
    PictureLogo,
    PillButton,
    SecondaryButton,
    SecondaryLink
} from '../../components/style';
import {required, email} from '../../utils/validation';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {authService, lookupService, mediaService, userAdministrationService} from "../../services";
import moment from "moment";
import Camera from "../../images/Camera.svg";
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from "../../components/modals/PromptModal";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

const FormRow = styled.div`
${FLEX_CENTER}
`;

const emailValidate = val => {
    return (!email(val)) ? undefined : "Not a valid email.";
}

class AddEdit extends Component {

    constructor(props, context) {

        super(props, context);

        this.state = {user: {}, handle: "", userPicture: null, userTypes: [], promptModal:false, showloader: false};
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.uploadPhoto = this.uploadPhoto.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        const user = JSON.parse(localStorage.getItem('user'));
        this.getLookups(user.user_type);



    }

    handleConfirmation() {
        window.location = "/admin-users";
        this.setState({confirmationMethod: function(){}});
    }

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.loadUser(this.props.match.params.id);
            this.getUserPicturePicture(this.props.match.params.id);
        }
    }

    loadUser(guid) {

        this.setState({showloader: true});
        userAdministrationService.getUser(guid).then(result => {
            this.setState({showloader: false});
            this.setState({user: result.data})

            this.props.dispatch(initialize(
                'admin-users-add-edit',
                this.state.user
            ));

        }).catch(error => {
            this.showModal('modal', error, 'error')
        });

    }

    forgotPassword(){
        this.setState({showloader: true});
        let data = {
            email: this.state.user.user_name
        };
        authService.forgotPassword(data).then(response=>{
            this.setState({showloader: false});
            this.showModal('modal', response.message, 'success')
        }).catch(error => {
            this.setState({showloader: false});
            this.showModal('modal', error, 'error')
        });
        this.setState({confirmationMethod: function(){}});
    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {
        if(this.state.type==='success'){
            this.setState({[modal]: false, message:null, type:null});
            this.props.history.push('/admin-users')
        }else{
            this.setState({[modal]: false, message:null, type:null});
        }

    }

    saveUser(data, id) {

        debugger;
        this.setState({showloader: true});
        if (id === 'new') {

            // do add
            userAdministrationService.addUser(data).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            })


        } else {

            //do update
            userAdministrationService.updateUser(data).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            })


        }

    }

    getUserPicturePicture(guid) {
        mediaService.getUserPicture(guid).then(response => {
            if (response.data && response.data.error) {

            } else {
                this.setState({userPicture: URL.createObjectURL(response)});
            }

        }).catch(error => {
            this.showModal('modal', error, 'error')
        })

    }

    uploadPhoto(event) {
        this.setState({showloader: true});
        console.log(event.target.files[0])
        let data = {
            file: event.target.files[0],
        };

        mediaService.uploadUserPicture(this.props.match.params.id, data).then(response => {
            this.setState({showloader: false});
            if (!response.error) {
                this.getUserPicturePicture(this.props.match.params.id);
            }
        }).catch(error => {
            this.setState({showloader: false});
            this.showModal('modal', error, 'error')
        })

    }

    getLookups(user_type) {
        lookupService.getUserTypes().then(result => {
            let userTypes = [];

            result.data.map(({user_type_name, user_type_code}) => {

                if (user_type === "SYSADMIN") {
                    return userTypes.push({label: user_type_name, value: user_type_code});
                } else if (user_type === "ORGADMIN") {

                    if (user_type_code === "ORGADMIN" || user_type_code === "MANAGER" || user_type_code === "USER") {
                        return userTypes.push({label: user_type_name, value: user_type_code});
                    }
                }
            });
            this.setState({userTypes: userTypes})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        })

    }

    render() {
        return (
            <Layout 
                title={`User ${this.props.match.params.id==='new'?"Add":"Edit"}`}
                dateCreated={this.state.user.date_created}
                dateModified={this.state.user.date_modified}
                dirty={this.props.dirty}
            >

                <DashboardForm onSubmit={this.props.handleSubmit((e) => this.saveUser(e, this.props.match.params.id))} onKeyPress={e=> {
                    if(e.key==="Enter"){
                        e.preventDefault();
                    }
                }}>
                    
                    <LogoRow>
                        <PictureLogo>
                            {this.state.userPicture ? "" : "User Profile Picture"}
                            <IconContainer>
                                <img src={Camera} alt="Camera"/>
                            </IconContainer>
                            {this.state.userPicture ? <LogoImg src={this.state.userPicture}/> : ""}
                            <input type="file" name="Logo" placeholder="logo" onChange={this.uploadPhoto}/>
                        </PictureLogo>
                    </LogoRow>
                    <FieldSet>
                        <Legend>User information</Legend>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Name" name="first_name"
                                       validate={required} required/>
                                <Field component={renderField} validate={required} placeholder="Surname" name="last_name" required/>
                                <Field component={renderField} placeholder="Telephone" name="contact_number"/>
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Email" name="user_name" validate={required} 
                                    validate={required, emailValidate} required/>
                                <Field component={renderSelect} placeholder="Select User Status" id="user-status"
                                    name="active"/>
                                <Field component={renderSelect} placeholder="Select User Type" id="user-type"
                                    name="user_type" options={this.state.userTypes}/>
                            </MultiColumn>
                        </MultiColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Change Password</Legend>
                        <FormRow>
                            <SecondaryButton onClick={()=> {

                                    this.setState({confirmationMethod: this.forgotPassword});
                                    this.showModal('promptModal', 'Are you sure you want to reset the password for this user?');

                                }
                             } type="button">Reset Password</SecondaryButton>
                        </FormRow>
                    </FieldSet>

                    <Buttons>
                        <SecondaryLink to="/admin-users" onClick={this.props.dirty? (e) => 
                            {
                                e.preventDefault();
                                this.setState({confirmationMethod: this.handleConfirmation});
                                this.showModal('promptModal', 'You have unsaved changes that will be lost. Are you sure you want to cancel?');
                            }
                        :undefined}>Cancel</SecondaryLink>
                        <PillButton type="submit" disabled={!this.props.valid}>Save</PillButton>
                    </Buttons>
                </DashboardForm>

                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.state.confirmationMethod} message={this.state.message} />}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}

                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </Layout>
        );

    }

}

export default reduxForm({
    form: 'admin-users-add-edit'
})(AddEdit);

