import React from "react";
import styled from "styled-components";
import { Input } from "./style";

const LabelContainer = styled.label`
  position: relative;
  width: 100%;
`;

const Label = styled.span`
  position: absolute;
  top: -10px;
  left: 10px;
  color: gray;
  font-size: 12px;
  opacity: 0;
`;

const InputComponent = props => {
  const type = props.type || "text";
  return (
    <LabelContainer>
      <Label>{props.name}</Label>
      <Input {...props} type={type} />
    </LabelContainer>
  );
};

export default InputComponent;
