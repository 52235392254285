import React from "react";
import styled from "styled-components";
import Modal from "../Modal";
import {
  Button,
  Heading1,
  Heading2,
  CheckIcon,
  ModalContainer,
} from "../style";
import * as strings from "../../utils/appstring";

const DismissBtn = styled(Button)`
  width: 50%;
  border-radius: 20px;
`;

const SuccessModal = ({ hideModal , message}) => (
  <Modal hideModal={hideModal}>
    <ModalContainer>
      <CheckIcon />
      <Heading1>Success</Heading1>
      <Heading2 style={
                                {
                                    maxWidth: "400px",
                                    textAlign: "center"
                                }
                            }>{message}</Heading2>
      <DismissBtn onClick={hideModal}>{strings.ok_Title}</DismissBtn>
    </ModalContainer>
  </Modal>
);

export default SuccessModal;
