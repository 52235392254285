import styled from "styled-components";
import { Field } from "redux-form";
import {
  TitleBar,
  Banner,
  ROBOTO_THIN,
  WHITE,
  LIGHT_GRAY,
  GREEN,
  SHADOW,
  PillButton,
  COLORS_1_INVERT,
  DARK_GRAY_OPACITY,
  Buttons as ImportedButtons, GRAY
} from "../../components/style";

const ExtendedTitleBar = styled(TitleBar)`
  margin-top: -72px;
`;

const ExtendedBanner = styled(Banner)`
  width: 100%;
`;

const Logo = styled.div`
  background: #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  border-radius: 5px;
`;

const Question = styled.h2`
  ${ROBOTO_THIN};
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 900px;
  margin: 0 auto;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  margin-bottom: 20px;
`;

const BottomStrap = styled.div`
  ${ROBOTO_THIN};
  background: ${LIGHT_GRAY};
  border-top: solid 1px ${SHADOW};
  border-bottom: solid 1px ${SHADOW};
  padding: 20px 0;
  text-align: center;
  font-size: 12px;
`;

const BottomLogo = styled.div`
  background: ${WHITE};
  padding: 20px;
  display: flex;
  justify-content: center;
  height: 120px;

  img {
    width: 168px;
  }
`;

const BarsContainer = styled.div`
  top: 35px;
  z-index: 100;
  position: fixed;
  width: 100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  `;

const Bars = styled.div`
  display: flex;
  width: 100%;
  max-width: 80%;
`;

const Bar = styled.div`
  height: 4px;
  background: ${props => props.complete ? GREEN : LIGHT_GRAY };
  flex-grow: 1;
  margin: 0 1px;
`;

const Buttons = styled(ImportedButtons)`
  background: transparent;
`;

const TextArea = styled(Field)`
  width: 100%; 
  margin-bottom: 40px;
`;

const BackBtn = styled(PillButton)`
  ${COLORS_1_INVERT};
  position: fixed;
  width: 120px;
  top: 110px;
  margin-left: -150px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.7;

  :hover {
    opacity: 1;
  }

  img {
    max-height: 13px;
  }
`;

export {
  BackBtn,
  Bar,
  Bars,
  BarsContainer,
  Buttons,
  BottomLogo,
  BottomStrap,
  Center,
  ExtendedBanner,
  ExtendedTitleBar,
  Flex,
  Logo,
  Question,
  Row,
  TextArea,
  GREEN,
  DARK_GRAY_OPACITY
};