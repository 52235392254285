import React from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Bar, Bars, BarsContainer } from "./styles";

class ProgressBar extends React.Component{

  constructor(props){
    super(props);
    this.state={data:props.data, questionsCompleted:2};
  }

  render(){

    return (

        <BarsContainer>

          <Bars>

            {this.state.data.map((question, key) => {
              return(
                <Bar key={`bar-${key}`} complete={this.props.values?this.props.values.questions[key].selected_answer!==undefined:false} />
            )})}

          </Bars>
          
        </BarsContainer>

    );

  }
  
}


export default connect(state => ({
  // Hook this up to the form you would like to preview
  values: getFormValues("external-assessment")(state)
}))(ProgressBar);
