import React from "react";
import styled from "styled-components";
import { GRAY, FLEX_CENTER } from "./style";

const NoSearchResults = styled.div`
  ${FLEX_CENTER};
  color: ${GRAY};
  font-size: 24px;
  height: 300px;
`;

class NoResults extends React.Component{
  constructor(props) {
    super(props);
  }

  render() {

    return (
      // <NoSearchResults>No results found {(this.props.searchTerm && this.props.searchTerm.length > 0) ? " for '" + this.props.searchTerm + "'":""} please click 'Add' to add records, or try searching again.</NoSearchResults>
      <NoSearchResults>No results found.</NoSearchResults>
    )

  }
}

export default NoResults;
