import React, { Component } from 'react';
import { Layout } from '../../components';
import { FieldArray, reduxForm, formValueSelector, Field, initialize  } from 'redux-form';
import {
  DashboardForm,
  FieldSet,
  Buttons,
  PillButton,
  SecondaryButton,
  SecondaryLink,
  Legend,
  MultiColumn, EditDate, FormMeta
} from '../../components/style';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import renderQuestions from './addEdit/RenderQuestions';
import {assessmentService, lookupService, organisationService} from '../../services'
import deduplicateArray from "../../utils/deduplicateArray";
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from "../../components/modals/PromptModal";
import moment from "moment";
import {required} from '../../utils/validation';
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";
import "react-scroll-to-top/lib/index.css"

class AddEdit extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      modal: false,
      assessment: {},
      questionCategories:[],
      questionTypes:[],
      assessmentTypes:[],
      showRole:false,
      showOrganisation:false,
      organisations:[],
      currentUser: JSON.parse(localStorage.getItem('user')),
      assessmentType:this.props.match.path.split('/')[2].split("-")[0],
      questionCount:0, 
      showloader: false, 
      unChanged: true
    };

    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.updateQuestionCount = this.updateQuestionCount.bind(this);
    this.unChanged = this.unChanged.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);

    this.getLookups();
    this.getQuestionTypes();
    this.getOrganisations();

  }

  handleConfirmation() {
    let url = `/assessments/${this.state.assessmentType.includes("knowledge")?this.state.assessmentType+'-skills':this.state.assessmentType}`;
    window.location = url;
  }

  componentDidMount() {
    if (!this.props.match.path.includes('create-new')){
      this.loadFullAssessment(this.props.match.params.id);
    }
  }

  showModal(modal, message, type) {
    this.setState({[modal]: true, message:message, type:type});
  }

  hideModal(modal) {
    this.setState({[modal]: false, message:null, type:null});
  }

  loadFullAssessment(guid) {
  
    this.setState({showloader: true});
    
    assessmentService.getFullAssessment(guid).then(result => {
      
      this.setState({showloader: false});

      let categories = result.data.questions.map(question=>{
        return {label:question.category, value:question.category}
      });

      for(let question of result.data.questions){
        question.category = {label:question.category, value:question.category};
        switch (question.question_type) {
          case "Rating Scale":
            //rating
            if(question.answers) {
              question.answers[0].answer_scale = String(question.answers[0].answer_scale);
            }
            break;
          case "True / False":
            //true false
            if(question.answers) {
              question.answers[0].answer_truefalse = question.answers[0].answer_truefalse === 1 ? "true" : "false";
            }
            break;
          case "Multiple Choice":
            //multiple choice
            for (let i = 0; i < question.answers.length; i++) {
              if (question.answers[i].answer_multi_correct === 1) {
                question.correct_answer = question.answers[i].answer_multi;
              }
            }
            break;
          default:
            break;
        }

      }

      const uniqueCategories = deduplicateArray(categories, 'label');

      this.setState({
        assessment: result.data, 
        questionCategories: uniqueCategories, 
        questionCount: result.data.questions.length
      });

      this.props.dispatch(initialize(
        'assessment',
        this.state.assessment
      ));

    });

  }

  createCategory(value){
    console.log(value);
    let current = this.state.questionCategories;
    current.push({label:value, value:value});
    this.setState({questionCategories:current})
  }

  getLookups(){
    lookupService.getAssessmentSubTypes().then(result=>{
      let assessmentType = []
      result.data.map(({name, guid})=>{
        if(JSON.parse(localStorage.getItem("user")).user_type==="SYSADMIN"){
          return assessmentType.push({label: name, value: name.toUpperCase()});
        }else{
          if(!name.includes("Organisation")){
            return assessmentType.push({label: name, value: name.toUpperCase()});
          }
        }
      });
      this.setState({assessmentTypes:assessmentType})
    })
  }

  getQuestionTypes(){
    lookupService.getQuestionTypes().then(types => {
      let qtypes = [];
      types.data.map(({name, guid}) => {
        return qtypes.push({label: name, value: guid});
      });
      this.setState({questionTypes: qtypes})
    }).catch(error => {
      this.showModal('modal', error, 'error')
    });
  }

  getOrganisations(search) {
    organisationService.getOrganisations(search).then(result => {
      let orgs = []
      result.data.map(({name, guid})=>{
        return orgs.push({label: name, value: guid});
      })
      this.setState({organisations:orgs})
    })
  }

  addQuestion() {
    const { questions } = this.state;
    this.setState({
      questions: [...questions, {}],
    });
  }

  updateQuestionCount(val){
    this.setState({questionCount:this.state.questionCount+val, unChanged: false})
  }

  unChanged() {

    return this.state.unChanged;

  }

  removeQuestion(idx) {
    const questions = this.state.questions.filter((q, i) => i !== idx);
    this.setState({ questions });
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  save(data){

    this.setState({showloader: true});

    let saveData={
      name:data.name,
      description:data.description,
      organisation_guid: this.state.currentUser.user_type === 'SYSADMIN' ? data.organisation_guid : this.state.currentUser.fk_organisation,
      assessment_type:this.state.assessmentType.toUpperCase(),
      assessment_sub_type:data.assessment_sub_type,
      role:data.role,
      version:"1.0",
      questions: this.props.match.path.includes('create-new')?[]:this.buildQuestionAnswerObject(data.questions)
    };

    if(!this.state.showRole){
      delete data.role;
    }

    if(this.props.match.path.includes('create-new')) {
      
      assessmentService.assessmentOrganisationAdd(saveData).then(response => {
        this.setState({showloader: false});
        window.location = "/assessments/performance/add-edit/" + response.data.guid;
      }).catch(error => {
        console.error(error);
        this.setState({showloader: false});
      });

    } else {

      saveData.guid = this.props.match.params.id;

      assessmentService.assessmentOrganisationUpdate(saveData).then(response =>{
        this.setState({showloader: false, unChanged: true});
        this.showModal('promptModal', response.message + " Do you want to go back to search?", 'success');
        this.props.dispatch(initialize(
          'assessment',
          this.state.assessment
        ));
  
      }).catch(error => {
        this.setState({showloader: false});
        this.showModal('modal', error, 'error')
      });

    }

  }

  getQuestionType(questionTypeGuid){
    const qtype =this.state.questionTypes.find(item=>{
      return item.value === questionTypeGuid;
    })
    return qtype.label;
  }

  buildQuestionAnswerObject(questions){

    let questionsClone = JSON.parse(JSON.stringify(questions));
    for(let j = 0;  j<questionsClone.length; j++) {
      questionsClone[j].fk_assesment = this.props.match.params.id;
      if(questionsClone[j].category){
        questionsClone[j].category = questionsClone[j].category.value;
      }
      switch (this.getQuestionType(questionsClone[j].fk_question_type)) {
        case "Rating Scale":
          //rating
          questionsClone[j].answers[0].answer_scale = Number(questionsClone[j].answers[0].answer_scale);
          break;
        case "True / False":
          //true false
          questionsClone[j].answers[0].answer_truefalse = questionsClone[j].answers[0].answer_truefalse === "true" ? 1 : 0;
          break;
        case "Multiple Choice":
          //multiple choice
          for (let i = 0; i < questionsClone[j].answers.length; i++) {
            if (questionsClone[j].answers[i].answer_multi === questionsClone[j].correct_answer) {
              questionsClone[j].answers[i].answer_multi_correct = 1;
            } else {
              questionsClone[j].answers[i].answer_multi_correct = 0;
            }
          }
          break;
        default:
          break;
      }
    }
    return questionsClone;
  }

  setPageOptions(value){
    console.log(value)
    switch (value) {
      case 'ROLE SPECIFIC':
        this.setState({showRole:true, showOrganisation:true});
        break;
      case 'ORGANISATION SPECIFIC':
        this.setState({showRole:false, showOrganisation:true});
        break;
      default:
        this.setState({showRole:false, showOrganisation:false});
    }
  }
  
  render() {
    return (
      <Layout 
        title={`${this.state.assessmentType.includes("KNOWLEDGE")?this.state.assessmentType+" & Skills":this.state.assessmentType} ${this.props.match.path.includes('create-new')?"Add":"Edit"}`}
        dateCreated={this.state.assessment.date_created}
        dateModified={this.state.assessment.date_modified}
        dirty={this.props.dirty}
      >
        
        <DashboardForm onSubmit={this.props.handleSubmit((e) => this.save(e, this.props.match.params.id))} onKeyPress={e=> {
          if(e.key==="Enter"){
            e.preventDefault();
          }
        }}>
          
          <FieldSet>
            <Legend>
              Assessment Details
            </Legend>
            <MultiColumn>
              <Field
                component={renderField}
                id="assessment-title"
                name="name"
                placeholder="Assessment Title"
                validate={[required]}
                required
              />
            </MultiColumn>
            <MultiColumn>
              <Field
                  component={renderField}
                  id="assessment-description"
                  name="description"
                  placeholder="Assessment Description"
              />
              <Field
                component={renderSelect}
                onChange={({target: {value}})=>this.setPageOptions(value)}
                options={this.state.assessmentTypes}
                id="assessment-type"
                name="assessment_sub_type"
                placeholder="Select Assessment Type"
                validate={[required]}
                required
              />
            </MultiColumn>
            <MultiColumn>
              {this.state.showRole && <Field
                  component={renderField}
                  id="role"
                  name="role"
                  placeholder="Role type"
              />}
              {JSON.parse(localStorage.getItem("user")).user_type==='SYSADMIN' && <Field
                  component={renderSelect}
                  id="organisation"
                  name="organisation_guid"
                  placeholder="Select Organisation"
                  options={this.state.organisations}
              />}
            </MultiColumn>
          </FieldSet>
          {!this.props.match.path.includes('create-new') && <FieldSet>
              {(this.state.questionCount > 0) && <Legend>Questions</Legend>}
            <FieldArray name="questions" component={renderQuestions} props={
                {
                  questionCategories: this.state.questionCategories, 
                  onCreateCategory:this.createCategory, 
                  questions:this.state.assessment.questions, 
                  updateQuestionCount:this.updateQuestionCount, 
                  unChanged:this.unChanged,
                  canAdd:this.props.valid, 
                  assessment_guid:this.state.assessment.guid
                }
              } />
          </FieldSet>}
  
          <Buttons>
            <SecondaryButton type="button" onClick={() => 
              {
                let url = `/assessments/${this.state.assessmentType.includes("knowledge")?this.state.assessmentType+'-skills':this.state.assessmentType}`;

                if (this.unChanged() && !this.props.dirty) {
                  window.location = url;
                } else {

                  this.showModal('promptModal', "You have unsaved changes in your Assessment! Are you sure you want to cancel?");

                }

              }
            }>Cancel</SecondaryButton>
            <PillButton type="submit" disabled={!this.props.valid}>Save</PillButton>
          </Buttons>
        </DashboardForm>

        {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleConfirmation} message={this.state.message} type={this.state.type}/>}
        {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}

        <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
          this.setState({showLoader: false});
        }}>
          <p>Loading, please be patient...</p>
        </Loading>
                
      </Layout>
    );
  };
}

export const selector = formValueSelector('assessment');

export default reduxForm({
  form: 'assessment',
  destroyOnUnmount:true
})(AddEdit);