import React, { Fragment } from 'react';
import { ROBOTO_REGULAR } from "../../components/style";
import styled from "styled-components";

const Label = styled.label`
  ${ROBOTO_REGULAR};
  margin-left: 25px;

  :hover {
    cursor: pointer;
  }

  > input {
    margin-right: 5px;
  }
`;

const TrueFalse = ({ input, name, key, meta }) => {
  const isRequired = meta.touched && meta.error;
  return (
    <Fragment>
      <Label>
        <input 
          {...input}
          name={name}
          type="radio"
          value={'true'}
          checked={'true' === input.value}
        />
        True
      </Label>
      <Label>
        <input 
          {...input}
          name={name}
          type="radio"
          value={'false'}
          checked={'false' === input.value}
        />
        False
      </Label>
      {isRequired && <span>{meta.error}</span>}
    </Fragment>
  )
};

export default TrueFalse;