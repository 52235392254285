export default [
  {
    name: "John McClane",
    id: "04",
    role: "Employee",
    weight: "",
    status: "",
    reviewers: [
      {
        name: "Hans Gruber",
        id: "001",
        role: "Manager",
        weight: 100,
        status: "Scheduled"
      },
      {
        name: "Harry Ellis",
        id: "007",
        role: "Supervisor",
        weight: 50,
        status: "Complete"
      }
    ]
  },
  {
    name: "John McClane",
    id: "04",
    role: "Employee",
    weight: "",
    status: "",
    reviewers: [
      {
        name: "Hans Gruber",
        id: "001",
        role: "Manager",
        weight: 100,
        status: "Scheduled"
      },
      {
        name: "Harry Ellis",
        id: "007",
        role: "Supervisor",
        weight: 50,
        status: "Complete"
      }
    ]
  },
  {
    name: "John McClane",
    id: "04",
    role: "Employee",
    weight: "",
    status: "",
    reviewers: [
      {
        name: "Hans Gruber",
        id: "001",
        role: "Manager",
        weight: 100,
        status: "Scheduled"
      },
      {
        name: "Harry Ellis",
        id: "007",
        role: "Supervisor",
        weight: 50,
        status: "Complete"
      }
    ]
  },
  {
    name: "John McClane",
    id: "04",
    role: "Employee",
    weight: "",
    status: "",
    reviewers: [
      {
        name: "Hans Gruber",
        id: "001",
        role: "Manager",
        weight: 100,
        status: "Scheduled"
      },
      {
        name: "Harry Ellis",
        id: "007",
        role: "Supervisor",
        weight: 50,
        status: "Complete"
      }
    ]
  },
  {
    name: "John McClane",
    id: "04",
    role: "Employee",
    weight: "",
    status: "",
    reviewers: [
      {
        name: "Hans Gruber",
        id: "001",
        role: "Manager",
        weight: 100,
        status: "Scheduled"
      },
      {
        name: "Harry Ellis",
        id: "007",
        role: "Supervisor",
        weight: 50,
        status: "Complete"
      }
    ]
  }
];
