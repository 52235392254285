import React, {Component} from 'react';
import {Field, initialize, reduxForm} from 'redux-form';
import {Layout} from '../../components';
import ObjectMapper from '../../utils/objectMapper'
import styled, {css} from "styled-components";
import {
    Buttons,
    DashboardForm,
    EditDate,
    FieldSet,
    FormMeta,
    Legend,
    MultiColumn,
    PillButton,
    SecondaryLink
} from '../../components/style';
import {required, email} from '../../utils/validation';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {
    organisationService,
    branchService,
    departmentService,
    teamService,
    employeeService,
    lookupService
} from "../../services";
import renderDatePicker from "../../components/RenderDatePicker";
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from '../../components/modals/PromptModal';
import moment from "moment";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";
import "react-scroll-to-top/lib/index.css"

const NotSelectedLabel = styled.span`
  color: grey;
  font-size: 12px;
  padding-left: 10px
  text-align: center;
  display: inline-flex;
  align-items: center;
`;

const southAfricaGuid = ['049a3444-9d89-11e9-835a-068fc7503a26'];

const emailValidate = val => {
    return (!email(val)) ? undefined : "Not a valid email.";
}

class AddEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: {},
            departments: [],
            branches: [],
            organisations: [],
            teams: [],
            countries: [],
            provinces: [],
            ethnicity: [],
            empTypes: [],
            fullOrgs: [],
            empStatus: [],
            selectedCountry: {},
            currentUser: JSON.parse(localStorage.getItem('user')), 
            showloader: false,
            handleConfirmationError: null
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.handleConfirmationError = this.handleConfirmationError.bind(this);

        this.getLookups();

        if (this.state.currentUser.user_type !== "SYSADMIN") {
            this.state.selectedOrganisation = this.state.currentUser.fk_organisation;
            this.getOrganisations();
            this.getBranches(this.state.currentUser.fk_organisation);
            this.getDepartments(this.state.currentUser.fk_organisation);
            this.getTeams(this.state.currentUser.fk_organisation);
        }else{
            this.getOrganisations();
            this.getBranches();
            this.getDepartments();
            this.getTeams();
        }

    }

    handleConfirmation() {
        window.location = "/employees";
    }

    handleConfirmationError() {
        window.location = "/employees";
    }

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.loadEmployee(this.props.match.params.id);
        }
    }

    setupPageSettings(orgGuid) {

        if (orgGuid) {

            const org = this.state.fullOrgs.filter(org => {
                return org.guid === orgGuid
            })[0];
            this.setState({
                setupBranches: org ? org.setup_branches !== 0 : 0,
                setupDepartments: org ? org.setup_departments !== 0 : 0,
                setupTeams: org ? org.setup_teams !== 0 : 0
            });

        } else {

            this.setState({
                setupBranches: true,
                setupDepartments: true,
                setupTeams: true
            });

        }

    }

    loadEmployee(guid) {

        this.setState({showloader: true});

        employeeService.getEmployee(guid).then(result => {

            this.setState({showloader: false});

            let manager = '';

            if (result.data.manager) {
                manager = `${result.data.manager.first_name} ${result.data.manager.last_name}`;
            } else {

                if (result.data.team_obj) {
                    manager = `${result.data.team_obj.leader_first_name} ${result.data.team_obj.leader_last_name}`;
                } else if (result.data.department_obj) {
                    manager = `${result.data.department_obj.manager_first_name} ${result.data.department_obj.manager_last_name}`;
                } else if (result.data.branch_obj) {
                    manager = `${result.data.branch_obj.manager_first_name} ${result.data.branch_obj.manager_last_name}`;
                } else if (result.data.organisation_obj) {
                    manager = `${result.data.organisation_obj.contact_person_obj ? result.data.organisation_obj.contact_person_obj.first_name : ''} ${result.data.organisation_obj.contact_person_obj ? result.data.organisation_obj.contact_person_obj.last_name : ''}`;
                }

            }

            this.setupPageSettings(result.data.organisation_guid);

            this.setState({
                employee: result.data,
                manager: manager,
                selectedOrganisation: result.data.organisation_guid,
                selectedBranch: result.data.branch_guid,
                selectedDepartment: result.data.department_guid,
                selectedTeam: result.data.team_guid,
                selectedCountry: result.data.address_obj ? result.data.address_obj.country : ''
            });
            this.props.dispatch(initialize(
                'employees-add-edit',
                this.state.employee
            ));

            if (!this.state.setupBranches && !this.state.setupDepartments && !this.state.setupTeams) {

                let manager;
                if (this.state.employee.organisation_obj && this.state.employee.organisation_obj.organisational_head) {
                    manager = this.state.employee.organisation_obj.organisational_head.first_name + ' ' + this.state.employee.organisation_obj.organisational_head.last_name;
                }

                this.setState({
                    manager: manager
                });

            }

        }).catch(error => {
            
            this.setState({showloader: false});

            if (error.toLowerCase().includes("invalid user right")) {
                this.state.handleConfirmationError = this.handleConfirmationError;
                this.showModal('modal', error, 'error')
            } else {
                this.showModal('modal', error, 'error')
            }

        });

    }

    saveEmployee(data, id) {

        this.setState({showloader: true});

        ObjectMapper.remapObject(data, false);
        if (id === 'new') {
            let createData = {
                address: "",
                type: "USER",
                country: data.address_obj.country,
                department_guid: this.state.selectedDepartment,
                ethnicity_guid: data.ethnicity_guid,
                organisation_guid: this.state.selectedOrganisation,
                nationality_guid: data.nationality_guid,
                employee_number: data.employee_number,
                job_title: data.job_title,
                unit: data.address_obj.unit,
                email: data.contact_obj.email,
                active: 0,
                last_name: data.last_name,
                first_name: data.first_name,
                created_by: "",
                deleted: 0,
                phone_number: data.contact_obj.phone_number,
                start_date: data.start_date,
                team_guid: this.state.selectedTeam,
                id_number: data.id_number,
                salary: data.salary,
                type_guid: data.type_guid,
                status_guid: data.status_guid,
                branch_guid: this.state.selectedBranch,
                suburb: data.address_obj.suburb,
                street: data.address_obj.street,
                middle_name: data.middle_name,
                complex: data.address_obj.complex,
                city_town: data.address_obj.city_town,
                floor: data.address_obj.floor,
                building: data.address_obj.building,
                facebook: data.contact_obj.facebook,
                web: data.contact_obj.web,
                prefer_sms: 0,
                twitter: data.contact_obj.twitter,
                fax: data.contact_obj.fax,
                prefer_email: 0,
                mobile_number: "",
                province: data.address_obj.province,
                postal_code: data.address_obj.postal_code
            }

            employeeService.createEmployee(createData).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
                window.scrollTo(0, 0);
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            });

        } else {

            //do update
            data.branch_guid = this.state.selectedBranch;
            data.team_guid = this.state.selectedTeam;
            data.department_guid = this.state.selectedDepartment;
            data.organisation_guid = this.state.selectedOrganisation;

            employeeService.updateEmployee(data).then(result => {

                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
                window.scrollTo(0, 0);

            }).catch(error => {
                this.state.handleConfirmationError = null
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            });

        }

    }

    getOrganisations() {
        organisationService.getOrganisations().then(result => {
            let orgs = []
            result.data.map(({name, guid}) => {
                return orgs.push({label: name, value: guid});
            })
            this.setState({organisationDropdown: orgs, fullOrgs: result.data})
            this.setupPageSettings(this.state.currentUser.fk_organisation);
            this.getBranches(this.state.currentUser.fk_organisation);
        });
    }

    getBranches(orgGuid) {
        if (orgGuid) {
            branchService.getOrganisationBranches(orgGuid).then(result => {
                this.setState({branches: result.data});
            })
        } else {
            branchService.getBranches().then(result => {
                this.setState({branches: result.data});

            })
        }

    }

    getDepartments(orgGuid) {
        if (orgGuid) {
            departmentService.getOrganisationDepartments(orgGuid).then(result => {
                this.setState({departments: result.data})
            }).catch(error => {
                this.showModal('modal', error, 'error')
            })
        } else {
            departmentService.getDepartments().then(result => {
                this.setState({departments: result.data})
            }).catch(error => {
                this.showModal('modal', error, 'error')
            })
        }

    }

    getTeams(orgGuid) {
        if (orgGuid) {
            teamService.getOrganisationTeams(orgGuid).then(result => {
                this.setState({teams: result.data})
            }).catch(error => {
                this.showModal('modal', error, 'error')
            });
        } else {
            teamService.getTeams().then(result => {
                this.setState({teams: result.data})
            }).catch(error => {
                this.showModal('modal', error, 'error')
            });
        }

    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message: message, type: type});
    }

    hideModal(modal) {
        if (this.state.type === 'success') {
            this.setState({[modal]: false, message: null, type: null});
            this.props.history.push('/employees')
        } else {
            this.setState({[modal]: false, message: null, type: null});
        }
    }

    getLookups() {
        lookupService.getCountries().then(countries => {
            let countryLookup = [];
            countries.data.map(({name, guid}) => {
                return countryLookup.push({label: name, value: guid});
            });
            this.setState({countries: countryLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });
        lookupService.getProvinces().then(provinces => {
            let provinceLookup = [];
            provinces.data.map(({name, guid}) => {
                return provinceLookup.push({label: name, value: guid});
            });
            this.setState({provinces: provinceLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });
        lookupService.getEthnicity().then(eth => {
            let ethLookup = [];
            eth.data.map(({name, guid}) => {
                return ethLookup.push({label: name, value: guid});
            });
            this.setState({ethnicity: ethLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });
        lookupService.getEmploymentTypes().then(empTypes => {
            let empTypeLookup = [];
            empTypes.data.map(({name, guid}) => {
                return empTypeLookup.push({label: name, value: guid});
            });
            this.setState({empTypes: empTypeLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        });
        lookupService.getEmploymentStatuses().then(empStatus => {
            let empStatusLookup = [];
            empStatus.data.map(({name, guid}) => {
                return empStatusLookup.push({label: name, value: guid});
            });
            this.setState({empStatus: empStatusLookup})
        }).catch(error => {
            this.showModal('modal', error, 'error')
        })
    }

    render() {
        return (
            <Layout 
                title={`Employee ${this.props.match.params.id === 'new' ? "Add" : "Edit"}`}
                dateCreated={this.state.employee.date_created}
                dateModified={this.state.employee.date_modified}
                dirty={this.props.dirty}
            >
                
                <DashboardForm
                    onSubmit={this.props.handleSubmit((e) => this.saveEmployee(e, this.props.match.params.id))}
                    onKeyPress={e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    }}>
                    
                    <FieldSet>
                        <Legend>Employee information</Legend>
                        <MultiColumn>
                            <Field component={renderField} placeholder="Name" name="first_name" validate={required}
                                   required/>
                            <Field component={renderField} placeholder="Middle Name" name="middle_name"/>
                        </MultiColumn>
                        <MultiColumn>
                            <Field component={renderField} placeholder="Surname" name="last_name" validate={required}
                                   required/>
                            <Field component={renderField} placeholder="ID Number" name="id_number" validate={required}
                                   required/>
                        </MultiColumn>
                        <MultiColumn>
                            <Field component={renderSelect} id="nationality" placeholder="Select Nationality"
                                   options={this.state.countries} name="nationality_guid"/>
                            <Field component={renderSelect} id="ethnicity" options={this.state.ethnicity}
                                   placeholder="Select Ethnicity"
                                   name="ethnicity_guid"/>
                        </MultiColumn>
                        <MultiColumn>
                            <Field component={renderSelect} id="employee_status" options={this.state.empStatus}
                                   placeholder="Select Employee Status" validate={required} required
                                   name="status_guid"/>
                            <Field component={renderSelect} id="employee_type" options={this.state.empTypes}
                                   placeholder="Select Employee Type"
                                   name="type_guid"/>
                        </MultiColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Address Information</Legend>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Complex" name="address_obj.complex"/>
                                <Field component={renderField} placeholder="Building" name="address_obj.building"/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Floor" name="address_obj.floor"/>
                                <Field component={renderField} placeholder="Number" name="address_obj.unit"/>
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Street" name="address_obj.street"/>
                                <Field component={renderField} placeholder="Suburb" name="address_obj.suburb"/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Town" name="address_obj.city_town"/>
                                <Field component={renderField} placeholder="Postal Code"
                                       name="address_obj.postal_code"/>
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderSelect} id="country" placeholder="Select Country"
                                       options={this.state.countries} name="address_obj.country" validate={required}
                                       required
                                       onChange={(e, newVal) => this.setState({selectedCountry: newVal})}/>
                                {southAfricaGuid.includes(this.state.selectedCountry) &&
                                <Field component={renderSelect} id="province" options={this.state.provinces}
                                       disabled={!southAfricaGuid.includes(this.state.selectedCountry)}
                                       placeholder="Select Province" name="address_obj.province"/>}
                            </MultiColumn>
                            <MultiColumn/>
                        </MultiColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Contact Information</Legend>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Telephone" name="contact_obj.phone_number"
                                       validate={required} required/>
                                <Field component={renderField} placeholder="Fax" name="contact_obj.fax"/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Web" name="contact_obj.web"/>
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Email" name="contact_obj.email" 
                                    validate={required, emailValidate} required/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Twitter" name="contact_obj.twitter"/>
                                <Field component={renderField} placeholder="Facebook" name="contact_obj.facebook"/>
                            </MultiColumn>
                        </MultiColumn>
                    </FieldSet>

                    <FieldSet>
                        <Legend>Employment Information</Legend>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} id="job-title" placeholder="Job title" name="job_title"
                                       validate={required} required/>
                                <Field component={renderField} placeholder="Employee Number" name="employee_number"/>
                                <Field 
                                    component={renderDatePicker} 
                                    maxDate={new Date()} 
                                    placeholder="Start date"
                                    name="start_date" 
                                    validate={required} 
                                    required
                                />
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                {this.state.currentUser.user_type === "SYSADMIN" ?
                                    <Field component={renderSelect} id="organisation"
                                           placeholder="Select Organisation"
                                           name="organisation_guid" validate={required} required
                                           options={this.state.organisationDropdown} onChange={({target: {value}}) => {
                                            this.setState({
                                            selectedBranch: null,
                                            selectedDepartment: null,
                                            selectedTeam: null,
                                            selectedOrganisation: value
                                        });
                                        this.setupPageSettings(value);
                                        this.getBranches(value)
                                    }}/> :
                                    <Field component={renderField} id="organisation" placeholder="Select Organisation"
                                           name="UNUSED" disabled={true}
                                           input={{value: this.state.currentUser.organisation_obj.name}}/>}
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                            {this.state.setupBranches ?
                                <Field component={renderSelect} id="branch" placeholder="Select Branch"
                                       name="branch_guid"
                                       options={this.state.branches.filter(item => {
                                           return item.fk_organisation_guid === this.state.selectedOrganisation;
                                       }).map(({name, guid}) => {
                                           return {label: name, value: guid}
                                       })}
                                       onChange={({target: {value}}) => {
                                           this.state.branches.filter(item => {
                                               return item.guid === value;
                                           }).map(val=>{
                                               this.setState({manager:`${val.manager_first_name} ${val.manager_last_name}`})
                                           });
                                           this.setState({
                                               selectedDepartment: null,
                                               selectedTeam: null,
                                               selectedBranch: value
                                           });

                                       }}
                                /> : <NotSelectedLabel>Branch Setup not Selected</NotSelectedLabel>}
                            </MultiColumn>
                            <MultiColumn>
                            {this.state.setupDepartments ?
                                <Field
                                    component={renderSelect}
                                    id="department"
                                    placeholder="Select Department"
                                    name="department_guid"
                                    options={this.state.departments.filter(item => {
                                        return item.fk_branch_guid === this.state.selectedBranch;
                                    }).map(({name, guid}) => {
                                        return {label: name, value: guid}
                                    })}
                                    onChange={({target: {value}}) => {
                                        this.state.departments.filter(item => {
                                            return item.guid === value;
                                        }).map(val=>{
                                            this.setState({manager:`${val.manager_first_name} ${val.manager_last_name}`})
                                        });
                                        this.setState({selectedTeam: null, selectedDepartment: value});
                                    }}
                                    disabled={this.state.selectedBranch===null||this.state.selectedBranch===""}
                                /> : <NotSelectedLabel>Department Setup not Selected</NotSelectedLabel>}
                            </MultiColumn>
                            <MultiColumn>
                            {this.state.setupTeams ?
                                <Field component={renderSelect}
                                       id="team"
                                       placeholder="Select Team"
                                       name="team_guid"
                                       options={this.state.teams.filter(item=>{
                                           return item.fk_department_guid === this.state.selectedDepartment;
                                       }).map(({name, guid}) => {
                                           return {label: name, value: guid}
                                       })}
                                       onChange={({target: {value}}) => {
                                           this.state.teams.filter(item => {
                                               return item.guid === value;
                                           }).map(val=>{
                                               this.setState({manager:`${val.leader_first_name} ${val.leader_last_name}`})
                                           });
                                           this.setState({selectedTeam:value})
                                       }}
                                       disabled={this.state.selectedDepartment===null||this.state.selectedDepartment===""}
                                /> : <NotSelectedLabel>Team Setup not Selected</NotSelectedLabel>}
                           </MultiColumn>
                        </MultiColumn>
                        <br/>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} id="manager" placeholder="Manager" disabled={true}
                                       name="MANAGER_UNUSED" input={{value: this.state.manager}}/>
                                <Field component={renderField} id="salary" placeholder="Salary" name="salary"
                                       validate={required} required/>
                            </MultiColumn>
                        </MultiColumn>
                    </FieldSet>

                    <Buttons>
                        <SecondaryLink to="/employees" onClick={this.props.dirty? (e) => 
                            {
                                e.preventDefault();
                                this.showModal('promptModal', 'You have unsaved changes that will be lost. Are you sure you want to cancel?');
                            }
                        :undefined}>Cancel</SecondaryLink>
                        <PillButton type="submit" disabled={!this.props.valid}>Save</PillButton>
                    </Buttons>
                </DashboardForm>

                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.handleConfirmation} message={this.state.message}/>}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message}
                                                 type={this.state.type} affirmAction={this.state.handleConfirmationError}/>}

                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader=
                {
                    () => 
                        {
                            this.setState({showLoader: false});
                        }
                }>
                    <p>Loading, please be patient...</p>
                </Loading>
                
            </Layout>
        );
    }
}


export default reduxForm({
    form: 'employees-add-edit'
})(AddEdit);
