const authHeader = ()=> {

    const token = localStorage.getItem("authToken");
    if (token) {
        return   'Bearer ' + token;
    } else {
        return '';
    }
    
}
export default authHeader;