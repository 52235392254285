import React, {Component} from 'react';
import PropTypes from 'prop-types'

import './styles.css'


class Loading extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      showCloseButton : false,
      loading : this.props.loading
    };
    this.hideLoadingDiv = this.showCloseButtonAfter5Seconds.bind(this);
    this.closeLoader = this.closeLoader.bind(this);
  }

  componentDidMount() {
    //console.log("componentDidMount");
  }

  componentWillUnmount() {
    this.state = {
      showCloseButton : false
    };
  }
  
  componentDidUpdate(prevProps) {
    //console.log("componentDidUpdate");
    if (this.props.loading !== prevProps.loading) {
      //console.log("componentDidUpdate", this.props.loading, prevProps.loading);
      if (this.props.loading) {
        this.showCloseButtonAfter5Seconds();
      }
    }
  }
  
  showCloseButtonAfter5Seconds() {

    //console.log("showCloseButtonAfter5Seconds");
    this.setState({showCloseButton: false});
    setTimeout(() =>{
      //console.log("showCloseButton=true");
      this.setState({
        showCloseButton : true
      });
    }, 10000);

  }

  closeLoader() {

    this.setState({loading:false, showCloseButton: false});
    this.props.closeLoader();

  }
  
  render() {

    const backgroundColor = {
      background: this.props.background
    }
    const loaderColor = {
      background: this.props.loaderColor
    }

    return this.props.loading ? (
      
      <div className="loading-background" style={backgroundColor}>
      
        <div className="loading-bar" >
          <div className="loading-circle-1" style={loaderColor} />
          <div className="loading-circle-2" style={loaderColor} />
        </div>
      
        {!this.state.showCloseButton && <div className="loading-text">Loading, please be patient...</div>}
        {this.state.showCloseButton && <div className="loading-text">Sorry, it's taking a bit longer than expected, please be patient...</div>}

        {this.state.showCloseButton && <div className="loading-close" onClick={this.closeLoader}>X</div>}
        
      </div>
    ) : null

  }

}

Loading.defaultProps = {
  loading: false,
  background: 'rgba(236, 240, 241, 0.7)',
  loaderColor: '#e74c3c'
}

Loading.propTypes = {
  loading: PropTypes.bool,
  background: PropTypes.string,
  loaderColor: PropTypes.string
}

export default Loading