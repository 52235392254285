const checkNullOrEmpty = (value) => {

    if (value == undefined || value == null || value.toString().trim().length == 0) {
        return '-';
    } else {
        return value;
    }
    
}

export default checkNullOrEmpty;