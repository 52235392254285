import ajax from '../utils/ajax'
import { API_ROOT } from '../api-config';

export const mediaService = {
    getUserPicture,
    getOrganisationPicture,
    uploadOrganisationPhoto,
    uploadUserPicture
};


function getUserPicture(guid) {
    return ajax.handleGet(`${API_ROOT}/media/users/${guid}`)
}
function getOrganisationPicture(guid) {
    return ajax.handleGet(`${API_ROOT}/media/organisations/${guid}`)
}
function uploadOrganisationPhoto(guid,data) {
    return ajax.handlePostMultiPart(`${API_ROOT}/media/organisation/logo/${guid}`, data)
}
function uploadUserPicture(guid,data) {
    return ajax.handlePostMultiPart(`${API_ROOT}/media/user/profile/${guid}`, data)
}