import React from "react";
import styled from "styled-components";
import { DARK_GRAY } from "../../../../components/style";
import { FormRow, Label } from "./styles";

const Select = styled.select`
  color: ${DARK_GRAY};
  &:focus {
    outline: 0;
    box-shadow: 0 0 2px #009B77;
  }
`;

const renderSelect = ({ input, label, meta: { touched, error }, children, placeHolder }) => (
  <FormRow style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
    <Label>{label}</Label>
    <div>
      <Select {...input}>
        <option value="">{placeHolder}</option>
        {children.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </Select>
      {touched && error && <span style={{color: 'red'}}>{error}</span>}
    </div>
  </FormRow>
);

export default renderSelect;
