import React, { Component } from "react";
import styled, { css } from "styled-components";
import {
  CHEVRON,
  GREEN,
  LIGHT_GRAY
} from "../../components/style";
import ListNested from "../../components/ListNested";

const AccordionWrapperNested = styled.div`

  ${props =>
    props.chevron &&
    css`
    padding-left: 40px;
    `
  }

  position: relative;
  border-bottom: solid 1px ${LIGHT_GRAY};

  ${props =>
    props.chevron &&
    css`
    > div::before {
      ${CHEVRON};
      border-color: ${GREEN};
      border-width: 0 2px 2px 0;
      position: absolute;
      top: 15px;
      left: 15px;

      ${props =>
        props.open &&
        css`
          transform: rotate(-135deg);
          top: 15px;
        `};

    }`
  };

  :hover {
    cursor: pointer;
  }
`;

class AssignAccordionNested extends Component {
  constructor(props) {
    super(props);
    this.state = { open: this.props.open || false, chevron : this.props.chevron || false};
    this.toggleOpenNested = this.toggleOpenNested.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({
      open: this.props.open
    });
  }

  toggleOpenNested(event) {
    event.stopPropagation();
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <AccordionWrapperNested chevron={this.state.chevron} open={this.state.open} onClick={(e)=>{this.toggleOpenNested(e)}}>
        <div>{this.props.label}</div>
        <ListNested open={this.state.open}>{this.props.children}</ListNested>
      </AccordionWrapperNested>
    );
  }
}

export default AssignAccordionNested;
