import React, {Component} from 'react';
import styled from 'styled-components';
import {Layout} from '../../components';
import {Field, initialize, reduxForm} from 'redux-form';
import {
    Buttons,
    DashboardForm,
    FieldSet,
    FLEX_CENTER,
    Legend,
    MultiColumn,
    PillButton,
    SecondaryButton,
    SecondaryLink,
    TextArea
} from '../../components/style';
import {required, email} from '../../utils/validation';
import renderField from '../../components/RenderField';
import renderSelect from '../../components/RenderSelect';
import {messagesService} from "../../services";
import TypedModal from "../../components/modals/TypedModal";
import PromptModal from "../../components/modals/PromptModal";
import Loading from '../../components/loader/Loading';
import {DARK_GRAY_OPACITY, GREEN} from "../../components/style";

const FormRow = styled.div`
${FLEX_CENTER}
`;

const emailValidate = val => {
    return (!email(val)) ? undefined : "Not a valid email.";
}

class AddEdit extends Component {

    constructor(props, context) {

        super(props, context);

        this.state = {user: {}, handle: "", userPicture: null, userTypes: [], promptModal:false, showloader: false};
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleConfirmation = this.handleConfirmation.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        const user = JSON.parse(localStorage.getItem('user'));

    }

    handleConfirmation() {
        window.location = "/messages";
        this.setState({confirmationMethod: function(){}});
    }

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.loadUser(this.props.match.params.id);
            this.getUserPicturePicture(this.props.match.params.id);
        }
    }

    showModal(modal, message, type) {
        this.setState({[modal]: true, message:message, type:type});
    }

    hideModal(modal) {
        if(this.state.type==='success'){
            this.setState({[modal]: false, message:null, type:null});
            this.props.history.push('/messages')
        }else{
            this.setState({[modal]: false, message:null, type:null});
        }

    }

    sendMessage(data, id) {

        this.setState({showloader: true});
        if (id === 'new') {

            // do add
            messagesService.sendMessage(data).then(result => {
                this.setState({showloader: false});
                this.showModal('modal', result.message, 'success')
            }).catch(error => {
                this.setState({showloader: false});
                this.showModal('modal', error, 'error')
            })


        }

    }

    render() {
        return (
            <Layout 
                title={`Message ${this.props.match.params.id==='new'?"Add":"Edit"}`}
                dateCreated={this.state.user.date_created}
                dateModified={this.state.user.date_modified}
                dirty={this.props.dirty}
            >

                <DashboardForm onSubmit={this.props.handleSubmit((e) => this.sendMessage(e, this.props.match.params.id))} onKeyPress={e=> {
                    if(e.key==="Enter"){
                        e.preventDefault();
                    }
                }}>
                    
                    <FieldSet>
                        <Legend>Send Internal Email</Legend>
                        <MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Recipient" name="recipient"
                                       validate={required} required/>
                            </MultiColumn>
                            <MultiColumn>
                                <Field component={renderField} placeholder="Full Name" name="full_name"
                                       validate={required} required/>
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                        <MultiColumn>
                                <Field component={renderField} placeholder="Subject" name="subject"
                                       validate={required} required/>
                            </MultiColumn>
                        </MultiColumn>
                        <MultiColumn>
                            <MultiColumn>
                                <TextArea
                                    name='body'
                                    component="textarea"
                                    rows="10"
                                    maxLength="10000"
                                />
                            </MultiColumn>
                        </MultiColumn>
                    </FieldSet>

                    <Buttons>
                        <SecondaryLink to="/messages" onClick={this.props.dirty? (e) => 
                            {
                                e.preventDefault();
                                this.setState({confirmationMethod: this.handleConfirmation});
                                this.showModal('promptModal', 'You have unsaved changes that will be lost. Are you sure you want to cancel?');
                            }
                        :undefined}>Cancel</SecondaryLink>
                        <PillButton type="submit" disabled={!this.props.valid}>Send</PillButton>
                    </Buttons>

                </DashboardForm>

                {this.state.promptModal && <PromptModal hideModal={() => this.hideModal('promptModal')} affirmAction={this.state.confirmationMethod} message={this.state.message} />}
                {this.state.modal && <TypedModal hideModal={() => this.hideModal('modal')} message={this.state.message} type={this.state.type}/>}

                <Loading loading={this.state.showloader} background={DARK_GRAY_OPACITY} loaderColor={GREEN} closeLoader={() => {
                    this.setState({showLoader: false});
                    }}>
                    <p>Loading, please be patient...</p>
                </Loading>

            </Layout>
        );

    }

}

export default reduxForm({
    form: 'admin-users-add-edit'
})(AddEdit);

